import { AfterViewInit, Directive, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';

@Directive({
  selector: '[dateMask]'
})
export class DateMaskDirective implements AfterViewInit {
  @Input('dateMask') mask: string = '99/99/9999';
  constructor(private primeCalendar: Calendar) { }

  ngAfterViewInit() {
    new Inputmask(this.mask).mask( this.getHTMLInput() );
  }

  getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }

  getDateMask(): string {
    return '99-99-9999';
  }
}