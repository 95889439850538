export const questionDescription = {
  'GG0130A1': 'Self-Care: Eating',
  'GG0130B1': 'Self-Care: Oral Hygiene',
  'GG0130C1': 'Self-Care: Toileting Hygiene',
  'GG0130E1': 'Self-Care: Shower/Bathe',
  'GG0130F1': 'Self-Care: Upper Body Dressing',
  'GG0130G1': 'Self-Care: Lower Body Dressing',
  'GG0130H1': 'Self-Care: Putting on/taking off footwear',
  'GG0170A1': 'Mobility: Roll left and right',
  'GG0170B1': 'Mobility: Sit to lying',
  'GG0170C1': 'Mobility: Lying to sitting onside of bed',
  'GG0170D1': 'Mobility: Sit to stand',
  'GG0170E1': 'Mobility: Chair/bed-to-chair transfer',
  'GG0170F1': 'Mobility: Toilet transfer',
  'GG0170G1': 'Mobility: Car transfer',
  'GG0170I1': 'Mobility: Walk 10 ft.',
  'GG0170J1': 'Mobility: Walk 50 ft. with 2 turns',
  'GG0170K1': 'Mobility: Walk 150 ft.',
  'GG0170L1': 'Mobility: 10 ft on uneven surfaces',
  'GG0170M1': 'Mobility: 1 step (curb)',
  'GG0170N1': 'Mobility: 4 steps',
  'GG0170O1': 'Mobility: 12 steps',
  'GG0170P1': 'Mobility: Picking up object',
  'GG0170Q1': 'Mobility: Use wheelchair/scooter?',
  'GG0170R1': 'Mobility: Wheel 50 ft with 2 turns',
  'GG0170RR1': 'Mobility: Type of wheelchair/scooter used',
  'GG0170S1': 'Mobility: Wheel 150 ft',
  'GG0170SS1': 'Mobility: Type of wheelchair/scooter used',
  'GG0130A2': 'Self-Care: Eating ',
  'GG0130B2': 'Self-Care: Oral Hygiene ',
  'GG0130C2': 'Self-Care: Toileting Hygiene ',
  'GG0130E2': 'Self-Care: Shower/Bathe ',
  'GG0130F2': 'Self-Care: Upper Body Dressing ',
  'GG0130G2': 'Self-Care: Lower Body Dressing ',
  'GG0130H2': 'Self-Care: Putting on/taking off footwear ',
  'GG0170A2': 'Mobility: Roll left and right ',
  'GG0170B2': 'Mobility: Sit to lying ',
  'GG0170C2': 'Mobility: Lying to sitting onside of bed ',
  'GG0170D2': 'Mobility: Sit to stand ',
  'GG0170E2': 'Mobility: Chair/bed-to-chair transfer ',
  'GG0170F2': 'Mobility: Toilet transfer ',
  'GG0170G2': 'Mobility: Car transfer ',
  'GG0170I2': 'Mobility: Walk 10 ft. ',
  'GG0170J2': 'Mobility: Walk 50 ft. with 2 turns ',
  'GG0170K2': 'Mobility: Walk 150 ft. ',
  'GG0170L2': 'Mobility: 10 ft on uneven surfaces ',
  'GG0170M2': 'Mobility: 1 step (curb) ',
  'GG0170N2': 'Mobility: 4 steps ',
  'GG0170O2': 'Mobility: 12 steps ',
  'GG0170P2': 'Mobility: Picking up object ',
  'GG0170R2': 'Mobility: Wheel 50 ft with 2 turns ',
  'GG0170S2': 'Mobility: Wheel 150 ft ',
  'GG0100A': 'Prior Functioning: Self Care ',
  'GG0100B': 'Prior Functioning: Indoor Mobility ',
  'GG0100C': 'Prior Functioning: Stairs ',
  'GG0100D': 'Prior Functioning: Cognition ',
  'GG0110A': 'Prior Device Use: Motorized wheelchair/scooter ',
  'GG0110B': 'Prior Device Use: Motorized wheelchair/scooter ',
  'GG0110C': 'Mechanical Lift ',
  'GG0110D': 'Walker ',
  'GG0110E': 'Orthotics/Prosthetics ',
  'GG0110Z': 'None of the above ',
  'GG0130A3': 'Self-Care: Eating',
  'GG0130B3': 'Self-Care: Oral Hygiene',
  'GG0130C3': 'Self-Care: Toileting Hygiene',
  'GG0130E3': 'Self-Care: Shower/Bathe',
  'GG0130F3': 'Self-Care: Upper Body Dressing',
  'GG0130G3': 'Self-Care: Lower Body Dressing',
  'GG0130H3': 'Self-Care: Putting on/taking off footwear',
  'GG0170A3': 'Mobility: Roll left and right',
  'GG0170B3': 'Mobility: Sit to lying',
  'GG0170C3': 'Mobility: Lying to sitting on side of bed',
  'GG0170D3': 'Mobility: Sit to stand',
  'GG0170E3': 'Mobility: Chair/bed-to-chair transfer',
  'GG0170F3': 'Mobility: Toilet transfer',
  'GG0170G3': 'Mobility: Car transfer',
  'GG0170I3': 'Mobility: Walk 10 ft.',
  'GG0170J3': 'Mobility: Walk 50 ft. with 2 turns',
  'GG0170K3': 'Mobility: Walk 150 ft.',
  'GG0170L3': 'Mobility: 10 ft on uneven surfaces',
  'GG0170M3': 'Mobility: 1 step (curb)',
  'GG0170N3': 'Mobility: 4 steps',
  'GG0170O3': 'Mobility: 12 steps',
  'GG0170P3': 'Mobility: Picking up object',
  'GG0170R3': 'Mobility: Wheel 50 ft with 2 turns',
  'GG0170S3': 'Mobility: Wheel 150 ft',
  'GG0130A4': 'Self-Care: Eating',
  'GG0130B4': 'Self-Care: Oral Hygiene',
  'GG0130C4': 'Self-Care: Toileting Hygiene',
  'GG0130E4': 'Self-Care: Shower/Bathe',
  'GG0130F4': 'Self-Care: Upper Body Dressing',
  'GG0130G4': 'Self-Care: Lower Body Dressing',
  'GG0130H4': 'Self-Care: Putting on/taking off footwear',
  'GG0170A4': 'Mobility: Roll left and right',
  'GG0170B4': 'Mobility: Sit to lying',
  'GG0170C4': 'Mobility: Lying to sitting on side of bed',
  'GG0170D4': 'Mobility: Sit to stand',
  'GG0170E4': 'Mobility: Chair/bed-to-chair transfer',
  'GG0170F4': 'Mobility: Toilet transfer',
  'GG0170G4': 'Mobility: Car transfer',
  'GG0170I4': 'Mobility: Walk 10 ft.',
  'GG0170J4': 'Mobility: Walk 50 ft. with 2 turns',
  'GG0170K4': 'Mobility: Walk 150 ft.',
  'GG0170L4': 'Mobility: 10 ft on uneven surfaces',
  'GG0170M4': 'Mobility: 1 step (curb)',
  'GG0170N4': 'Mobility: 4 steps',
  'GG0170O4': 'Mobility: 12 steps',
  'GG0170P4': 'Mobility: Picking up object',
  'GG0170R4': 'Mobility: Wheel 50 ft with 2 turns',
  'GG0170S4': 'Mobility: Wheel 150 ft',
  'M0150': 'Current Payment Source: None -No Charge ',
  'M0150(1)': 'Current Payment Source: Medicare (Traditional) ',
  'M0150(10)': 'Current Payment Source: Self-Pay ',
  'M0150(11)': 'Current Payment Source: Other ',
  'M0150(12)': 'Current Payment Source: Unknown ',
  'M0150(13)': 'Current Payment Source: Unknown ',
  'M0150(2)': 'Current Payment Source: HMO/Advantage Medicare ',
  'M0150(3)': 'Current Payment Source: Medicaid (Traditional) ',
  'M0150(4)': 'Current Payment Source: Medicaid (HMO/Managed Care) ',
  'M0150(5)': 'Current Payment Source: Worker\'s Comp ',
  'M0150(6)': 'Current Payment Source: Title Programs (III, V, XX) ',
  'M0150(7)': 'Current Payment Source: Other Government Programs (TriCare, VA) ',
  'M0150(8)': 'Current Payment Source: Private Insurance ',
  'M0150(9)': 'Current Payment Source: Private HMO/Managed Care ',
  'M1033': 'Risk of Hospitalization ',
  'M1033(1)': 'Risk of Hospitalization: History of falls ',
  'M1033(2)': 'Risk of Hospitalization: Unintentional weight loss ',
  'M1033(3)': 'Risk of Hospitalization: Multiple Hospitalizations ',
  'M1033(4)': 'Risk of Hospitalization: Multiple ED visits ',
  'M1033(5)': 'Risk of Hospitalization: Decline in mental, emotional, behavioral status ',
  'M1033(6)': 'Risk of Hospitalization: Difficulty complying with medical instructions ',
  'M1033(7)': 'Risk of Hospitalization: Taking 5 or more medications ',
  'M1033(8)': 'Risk of Hospitalization: Reports exhaustion',
  'M1033(9)': 'Risk of Hospitalization: Other risks not listed',
  'M1033(10)': 'Risk of Hospitalization: None of the above',
  'M1060A': 'Height ',
  'M1060B': 'Weight ',
  'M1340': 'Surgical Wound ',
  'M1342': 'Status of Most Problematic Surgical Wound ',
  'M1400': 'When Short of Breath ',
  'M1600': 'Treated for UTI ',
  'M1610': 'Incontinence or Catheter ',
  'M1700': 'Cognitive Functioning ',
  'M1710': 'When Confused ',
  'M1745': 'Frequency of Disruptive Behavior ',
  'M1800': 'Grooming ',
  'M1810': 'Dress Upper Body ',
  'M1820': 'Dress Lower Body ',
  'M1830': 'Bathing ',
  'M1840': 'Toilet Transferring ',
  'M1845': 'Toileting Hygiene ',
  'M1850': 'Transferring ',
  'M1860': 'Ambulation ',
  'M1870': 'Feeding/Eating ',
  'M2001': 'Drug Regimen Review ',
  'M2003': 'Medication Follow Up ',
  'M2010': 'Patient/Caregiver High Risk Drug Education ',
  'M2020': 'Oral Medications ',
  'M2102(4)': 'Types and Sources of Assistance ',
  'M1021(1)': 'Primary Diagnosis ',
  'M1023(1)': 'Other Diagnoses ',
  'M1023(2)': 'Other Diagnoses ',
  'M1023(3)': 'Other Diagnoses ',
  'M1023(4)': 'Other Diagnoses ',
  'M1023(5)': 'Other Diagnoses ',

}
