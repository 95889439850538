import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private message$ = new BehaviorSubject<boolean>(false);

  setProgressBarVisibility(visibility: boolean) {
    this.message$.next(visibility);
  }

  getProgressBarVisibility$(): Observable<boolean> {
    return this.message$.asObservable();
  }
}
