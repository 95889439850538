import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CREATE_NEW_PASSWORD, EMAIL_SENT_IF_USER_EXISTS, LOGIN_PATH } from 'src/app/common/Constants';
import { LOGO_TEXT } from 'src/app/common/Constants';
import { MessageHandlerService } from 'src/app/services/message-handler.service';
import { UserService } from 'src/app/services/user.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordEmail: string;
  logoText = LOGO_TEXT;
  subscriptions: Subscription[] = [];
  constructor(private router: Router,private messageHandlerService : MessageHandlerService,private userService : UserService) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    if(this.subscriptions.length){
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  submitForgotPasswordForm() {
  this.subscriptions.push(this.userService.checkExistingEmails(this.forgotPasswordEmail).subscribe(res =>{
  if(res.success && res.content.data.length){
    Auth.forgotPassword(this.forgotPasswordEmail)
      .then(() => {
        console.log('Forgot password request submitted successfully.');
        this.router.navigate([CREATE_NEW_PASSWORD]);
      })
      .catch(() => {
        this.messageHandlerService.generateToastMessage('error','','Forgot Password Service Failed')
      });
  }
  this.messageHandlerService.generateToastMessage('success','',EMAIL_SENT_IF_USER_EXISTS)
}));

  }
  navigateToPreviousPage() {
    this.router.navigate([LOGIN_PATH]);
  }
}
