import { Injectable } from '@angular/core';
import { ServiceClientService } from 'src/app/services/service-client.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EsocPatient } from 'src/app/models/esocPatient';

@Injectable({
  providedIn: 'root',
})
export class EsocService {
  constructor(private serviceClientService: ServiceClientService) {}

  getEsocPatients(): Observable<EsocPatient[]> {
    return this.serviceClientService.get(environment.endPoints.getAgencies);
  }

  getEsocPatientsWithSearch(search: any, isEsoc: any): Observable<any> {
    return this.serviceClientService.get(environment.endPoints.getEsocPatientsWithSearch + `?isEsoc=${isEsoc}`);
  }

  getEsocPatientSubmissions(id: any): Observable<any> {
    return this.serviceClientService.get(environment.endPoints.getEsocPatientsSubmissions + `?patientId=${id}`);
  }

  processEsocRequest(req: any, agencyId): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.processEsocRequest, req, 'agencyid', agencyId);
  }

  processEsocResendRequest(req: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.processEsocResendRequest, req);
  }

  geteSocPatientSubmissions(req: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getPatientEsocListing, req);
  }

  getPatientEdocById(req: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getPatientEsocById, req);
  }

  getPatientESOCExportData(req: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.patientESOCExportData, req);
  }

  getUsersForEsoc(req: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getUserEsocPatientSubmissions, req);
  }

  changeConsent(req): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.changeConsent, req);
  }

  getAgencyInformation(req): Observable<any> {
    return this.serviceClientService.get(environment.endPoints.getAgencyInformation + `/${req}`);
  }

  processEphResendRequest(req: any): Observable<any> {
    return this.serviceClientService.get(environment.endPoints.processEphResendRequest + `/${req}`);
  }

  getEPHfileObjectUrl(req): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getEPHfileObjectUrl, req);
  }

  updateEsocFileStatus(req): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.updateEsocFileStatus, req);
  }

  checkFileValidity(req): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.checkFileValidity, req);
  }

  checkEDocLinkValidity(req): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.checkEDocLinkValidity, req);
  }
}
