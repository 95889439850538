import {answerDescription} from "./answer.description";

export function reformatDate(date: any, seperator: string) {
  const dateParts = date.split(seperator);
  const year = dateParts[2];
  const month = dateParts[1];
  const day = dateParts[0];
  return `${year}-${day}-${month}`;
}

export function reformatVisitsAnswersData(patientSubmission: any) {
    let ansDescription = '';
    return patientSubmission.forEach((issue) => {
      issue?.answers?.forEach((answer) => {
        ansDescription += (answerDescription[issue?.question+'-'+answer])? answerDescription[issue?.question+'-'+answer] + ', ' : '';
         return issue['answerDescription'] = ansDescription?.replace(/,\s*$/, "").split(',')
                .map((tag) => tag.trim())
                .filter((tag) => tag.length !== 0);
      });
      ansDescription = '';
    });
}

export function reformatNormalizedElementAnswersData(normalizedElement: any) {
    let ansDescription = '';
    return normalizedElement?.answers?.forEach((answer) => {
        ansDescription += (answerDescription[normalizedElement?.question+'-'+answer])? answerDescription[normalizedElement?.question+'-'+answer] + ', ' : '';
         return normalizedElement['answerDescription'] = ansDescription?.replace(/,\s*$/, "").split(',')
                .map((tag) => tag.trim())
                .filter((tag) => tag.length !== 0);
      });
}

export function reformatWithSpecialCharacters(data) {
  if(!data) return data;
  const lines = data.split('\n');
  //if(lines.length === 1) return data;
  let finalOutput = '';
  lines.forEach((line) => {
    if(line.trim().startsWith('-')) {
      finalOutput += `<li class="line-height-3 py-2">${line.trim().replace("-", "")}</li>`;
    } else if(line.trim().startsWith('$B$')) {
      finalOutput += `<li class="line-height-3 py-2">${line.trim().replace("$B$", "")}</li>`;
    } else if(line.trim().length > 0) {
      finalOutput += `${line.trim()}`;
    } /*else {
      finalOutput += `<br>`;
    }*/
  });
  return finalOutput;
}

export function reformatDashToList(data) {
  if(!data) return data;

  const lines = data.split('\n');
  const transformedLines = lines.map(line => line.startsWith('-') ? `<li>${line.substring(1)}</li>` : line);

  return `<ul>${transformedLines.join('')}</ul>`;
}

