import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { finalize, Subscription, tap, throwError } from "rxjs";
import {
  bulkUploadTemplateValues,
  defaultSelectedNonIOHUserRows,
  NonIOHUserRows,
  User,
  UserFilterDropdown,
  UserFilterDropdownPermissions, userSettingsValues,
  UserStatus,
  userStatusValues,
} from "../../models/user";
import { Agency, NonIOHRoleFilterOptions, Providers, ProvidersFilter, RoleEnum, Roles, status, statusValues } from "../../models/agency";
import { ConfirmationService, SelectItem } from "primeng/api";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  BULK_USER_UPLOAD,
  BULK_USER_UPLOAD_ALLOWED_FORMAT,
  BULK_USER_UPLOAD_FAILED_MESSAGE,
  BULK_USER_UPLOAD_FILE_FORMAT_ERROR,
  dateAndTimeFormat,
  dateFormat,
  dateFormatStartWithYear,
  DEFAULT_SEARCH_MIN_CHARACTER,
  EMAIL_ALREADY_REGISTERED_USER,
  FILE_DOWNLOAD_SUCCESS,
  MfaAllowedRoles,
  NEW_PASSWORD_REQUEST_SEND,
  RoleID,
  rowsPerPageOptions,
  superAdminRoles,
  TEMP_PASSWORD_EXPIRED,
  USER_ACCOUNT_ALREADY_EXIST,
  USER_ACTIVITY_HOURS_IN_PAST,
  NO_OF_RECORDS_PER_PAGE, REGISTRATION_WAITING_STATUS, ACTIVATION_EMAIL_SUCCESS_MESSAGE,
} from "../../common/Constants";
import { countries } from "../../models/country-data";
import { Paginator } from "primeng/paginator";
import { FileUpload } from "primeng/fileupload";
import { UserService } from "../../services/user.service";
import { MessageHandlerService } from "../../services/message-handler.service";
import { UserDataManager } from "../../user/UserDataManager";
import { AgencyService } from "../../services/agency/agency.service";
import { ProgressBarService } from "../../services/progress-bar.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpParams } from "@angular/common/http";
import { UtilService } from "../../services/util/util.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { DatePipe } from "@angular/common";
import { isMultiAgenciesUserEnabled } from "../../services/util/helper.function";
import { Dropdown } from "primeng/dropdown";
import { Company } from "src/app/models/company";
import { CompanyService } from "src/app/services/company/company.service";
import { catchError } from "rxjs/operators";
import { toLower } from "lodash-es";

@Component({
  selector: "app-non-ioh-users",
  templateUrl: "./non-ioh-users.component.html",
  styleUrls: ["./non-ioh-users.component.scss"],
})
export class NonIohUsersComponent implements OnInit, OnDestroy {
  subscribers: Subscription[] = [];
  users: User[] = [];
  currentUser: User;
  currentAgencyRole: { agency: string; role: string };
  dateFormat = dateFormat;
  totalUsers: number = 0;
  display = false;
  globalRoles: string;
  roles = Roles;
  statusValues = statusValues;
  agencies: SelectItem[] = [];
  providers: SelectItem[] = Providers;
  providersFilter: SelectItem[] = ProvidersFilter;
  canActivateProviders = false;
  agenciesFilter: SelectItem[] = [];
  originalAgenciesFilter: SelectItem[] = [];
  selectedAgencies: Agency[] = [];
  numberOfAgencies: number = 0;
  searchUser: string = "";
  currentImage: any;
  statusEnum = status;
  newUserForm: FormGroup;
  countries = countries;
  userDropDownOptions: SelectItem[];
  selectedItem: any;
  selectedIndex: number;
  showEditForm: boolean = false;
  filteredAgencies: any[];
  agencyDetails: Agency[];
  pageNo: number = 0;
  noOfUsersPerRow: number = NO_OF_RECORDS_PER_PAGE;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  SUPER_ADMIN = RoleID.Admin;
  IOH_ADMIN = RoleID.iohAdmin;
  AGENCY_ADMINISTRATOR = RoleID.AgencyAdmin;
  COMPANY_ADMIN = RoleID.companyAdmin;
  selectedAgency: string = "";
  selectedFilterAgency: string[] = [];
  userStatusFilterOptions: SelectItem[] = userStatusValues;
  bulkUploadTemplateOptions = bulkUploadTemplateValues;
  selectedUserStatus;
  userStatusFilter: string = "";
  bulkUploadTemplate;
  roleEnum = RoleEnum;
  emailType = "";
  dateAndTimeFormat = dateAndTimeFormat;
  selectedRows: any[] = defaultSelectedNonIOHUserRows;
  visibleRows: any[] = NonIOHUserRows;
  userFilter: any[] = UserFilterDropdown;
  userFilterSelectedRows: any[] = [];
  newUserAgencies: SelectItem[] = [];
  rolesFilterOptions: SelectItem[] = NonIOHRoleFilterOptions;
  selectedRoles: string[] = [];
  selectedProvider: string[] = [];
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  disableMultiAgencyFilter: boolean = true;
  showUserCreationSuccessPopup: boolean = false;
  loading: boolean = false;
  componentLocalStorageKey = "nonIohUser";
  companies: SelectItem[] = [];
  companiesPayload: Company[] = [];
  filteredCompanies: any[];
  userRegistrationStatus: boolean = false;

  @ViewChild("paginator") paginator: Paginator;
  @ViewChild("fileUpload", { static: false }) fileUpload: FileUpload;
  @ViewChild("bulkUploadUsers", { static: false }) bulkUploadUsers: FileUpload;
  @ViewChild("downloadTemplateDropdown") dropdown: Dropdown;
  mfaEnabled: boolean = false;

  constructor(
    private userService: UserService,
    private messageHandlerService: MessageHandlerService,
    public userDataManager: UserDataManager,
    private agencyService: AgencyService,
    private companyService: CompanyService,
    private readonly progressBarService: ProgressBarService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private matIconRegistry: MatIconRegistry,
    private utilService: UtilService,
    private localStorageService: LocalStorageService,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe,
  ) {
    this.userDropDownOptions = [
      { label: "Edit", value: "Edit" },
      { label: "Delete", value: "Delete" },
      { label: "Inactive", value: "Inactive" },
    ];
    this.matIconRegistry.addSvgIcon("status", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/active-inactive.svg"));
    this.matIconRegistry.addSvgIcon("delete", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/delete-item.svg"));
  }

  get role(): FormArray {
    return this.newUserForm.get("agency_roles") as FormArray;
  }

  async customSort(event) {
    this.users = [];
    this.totalUsers = 0;
    const nonIOHUsersSorting = {
      order: event?.sortOrder,
      orderBy: event?.sortField,
    };

    this.localStorageService.set("nonIOHUsersSorting", JSON.stringify(nonIOHUsersSorting));
    await this.getUsers(event?.sortOrder, event?.sortField);
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getDataFromLocalStorage();
    this.getCurrentAgencyRole();
    this.createUsersForm();
    this.getSelectedRows();
    if (this.currentUser?.is_IOH_admin || this.currentUser?.is_super_admin) {
      this.getCompanyListing();
    } else if (this.currentUser?.is_company_admin) {
      this.getCompanyListing(this.currentUser.companyId);
    }
  }

  getDataFromLocalStorage() {
    const nonIohUser = JSON.parse(this.localStorageService.get(this.componentLocalStorageKey));
    this.selectedFilterAgency = nonIohUser?.selectedFilterAgency ? nonIohUser?.selectedFilterAgency : [];
    this.userFilterSelectedRows = nonIohUser?.userFilterSelectedRows ? nonIohUser?.userFilterSelectedRows : [];
    this.selectedRoles = nonIohUser?.selectedRoles ? nonIohUser?.selectedRoles : [];
    this.selectedProvider = nonIohUser?.selectedProvider ? nonIohUser?.selectedProvider : [];
    this.selectedUserStatus = nonIohUser?.selectedUserStatus ? nonIohUser?.selectedUserStatus : "";
    this.userStatusFilter = this.selectedUserStatus;

    this.prePopulatePerPage();
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get("globalPageLimit"));
    if (global) {
      this.noOfUsersPerRow = global.perPage;
    }
  }

  resetMultiSelectDataInLocalStorage() {
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "pageNo", this.pageNo);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedFilterAgency", this.selectedFilterAgency);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "userFilterSelectedRows", this.userFilterSelectedRows);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedRoles", this.selectedRoles);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedProvider", this.selectedProvider);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedUserStatus", this.selectedUserStatus);
  }

  canEnablePermissionsEmail(user: User) {
    let canEnable = false;
    let allowedRoles: string[] = [RoleID.Admin, RoleID.iohAdmin, RoleID.companyAdmin, RoleID.AgencyAdmin];
    let isAllowedRole: boolean = allowedRoles.includes(this.currentAgencyRole?.role);
    let permissionsExists = false;
    if (user?.permissions && user?.permissions.length > 0) {
      permissionsExists = this.checkValuesExist(user?.permissions);
    }

    if (!permissionsExists && isAllowedRole) {
      if (user.agency_roles && user.agency_roles.length)
        user.agency_roles.forEach((agency_role) => {
          if (agency_role.roles.includes(RoleID.Clinician)) canEnable = true;
        });
    }

    return canEnable;
  }

  checkValuesExist(permissions) {
    for (let i = 0; i < UserFilterDropdownPermissions.length; i++) {
      if (!(permissions.includes(UserFilterDropdownPermissions[i]) || permissions.includes(UserFilterDropdownPermissions[i].toLowerCase()))) {
        return false;
      }
    }
    return true;
  }

  onAgencySelect() {
    const selectedAgencies = this.newUserForm.value?.agency_roles;
    const selectedAgenciesSet = new Set(selectedAgencies.map((item) => item.agency));
    this.agencies = this.agencies.map((item) => {
      if (selectedAgenciesSet.has(item.value)) {
        return { ...item, disabled: true };
      } else {
        return { ...item, disabled: false };
      }
    });
  }

  getSelectedRows() {
    if (!this.localStorageService.get("userSettings" + this.currentUser?.aws_cognito_id)) {
      this.selectedRows = defaultSelectedNonIOHUserRows;
      this.localStorageService.set("userSettings" + this.currentUser?.aws_cognito_id, JSON.stringify({ selectedNonIOHUserRows: this.selectedRows }));
    } else {
      this.selectedRows = JSON.parse(this.localStorageService.get("userSettings" + this.currentUser?.aws_cognito_id))?.selectedNonIOHUserRows
        ? JSON.parse(this.localStorageService.get("userSettings" + this.currentUser?.aws_cognito_id))?.selectedNonIOHUserRows
        : defaultSelectedNonIOHUserRows;
    }
  }

  ngOnDestroy(): void {
    if (this.subscribers.length > 0) {
      this.subscribers.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  getCurrentAgencyRole() {
    this.subscribers.push(
      this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
        this.currentAgencyRole = currentRole;
        this.selectedAgency = currentRole?.agency;

        if (this.currentUser?.userSettings?.isMultiAgenciesAllowed) {
          this.setAgencyAutoCompleteOptions();
        } else {
          this.getAgencyListing();
        }
        this.addCompanyAdminFilter();
        this.getUsers();
      }),
    );
  }

  addCompanyAdminFilter() {
    const isCompanyAdmin = this.rolesFilterOptions.findIndex((rolesFilterOption) => rolesFilterOption.value == this.COMPANY_ADMIN);

    if (this.currentAgencyRole.role == this.IOH_ADMIN || this.currentAgencyRole.role == this.SUPER_ADMIN) {
      if (isCompanyAdmin == -1) {
        const companyOption = {
          value: this.COMPANY_ADMIN,
          label: "Company Admin",
        };

        this.rolesFilterOptions.splice(1, 0, companyOption);
      }
    } else if (isCompanyAdmin != -1) {
      this.rolesFilterOptions.splice(1, 1);
    }
  }

  setAgencyAutoCompleteOptions() {
    if (this.currentUser?.agency_roles) {
      this.agencies = this.currentUser.agency_roles
        .filter((agency_role) => agency_role.roles.includes(this.AGENCY_ADMINISTRATOR))
        .map((agency_role) => ({
          label: agency_role.agency?.agencyName,
          value: agency_role.agency?.agencyID,
        }));
    } else {
      this.agencies = [];
    }
  }

  filterAgencies(event) {
    let filtered: any[] = [];
    let query = event.query;
    this.newUserAgencies = this.agencies.filter((agencie) => agencie.label !== "All" && agencie.value !== "");

    for (let i = 0; i < this.newUserAgencies.length; i++) {
      let checkIfAlreadySelected = false;
      let agency = this.newUserAgencies[i];

      if (agency?.label?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        const formData = this.newUserForm.value;

        formData.agency_roles.forEach((agencyRole) => {
          if (agency?.value && agencyRole?.agency?.value == agency?.value) {
            checkIfAlreadySelected = true;
          }
        });

        if (!checkIfAlreadySelected) {
          filtered.push(agency);
        }
      }
    }

    this.filteredAgencies = filtered;
  }

  async getCurrentUser() {
    this.subscribers.push(
      await this.userDataManager.user$.subscribe((user) => {
        if (user) {
          this.currentUser = user;
        }
      }),
    );
  }

  removeAgency(index) {
    const agencyArray = this.newUserForm.get("agency_roles") as FormArray;
    agencyArray.removeAt(index);

    this.onSelectedRoleChange();
  }

  createUsersForm() {
    this.newUserForm = this.formBuilder.group({
      is_super_admin: false,
      is_IOH_admin: false,
      is_company_admin: false,
      is_ioh_user: false,
      picture: [""],
      firstName: ["", Validators.required],
      lastName: ["", [Validators.required, Validators.minLength(2)]],
      //gender: ['Male'],
      phone: this.formBuilder.group({
        number: ["", Validators.required],
        countryCode: [countries[0].dial_code],
      }),
      // fax: this.formBuilder.group({
      //   number: [''],
      //   countryCode: [countries[0].dial_code],
      // }),
      status: ["Active"],
      agency_roles: this.formBuilder.array([], [Validators.required]),
      defaultRole: ["", this.getIohUserValidator()],
      email: ["", [Validators.required, Validators.email]],
      // address: this.formBuilder.group({
      //   state: [''],
      //   country: [countries[0].name],
      //   city: ['', Validators.required],
      //   line1: ['', Validators.required],
      //   line2: [''],
      //   postalCode: [''],
      // }),
      userSettings: this.formBuilder.group(userSettingsValues),
      isIOHHelp: true,
      companyId: [""],
      provider: [""],
    });

    (this.newUserForm.get("agency_roles") as FormArray).push(this.createAgency());
    this.iohUserChangeSubscriber();

    this.prePopulateCompanyField();
  }

  getIohUserValidator(): any {
    let iohUser = this.newUserForm?.get("is_ioh_user")?.value;
    let validators = iohUser ? [Validators.required] : [];
    return validators;
  }

  iohUserChangeSubscriber() {
    this.newUserForm.get("is_ioh_user").valueChanges.subscribe((obj) => {
      if (!obj) {
        this.newUserForm.controls["defaultRole"].reset();
      }
      let defaultRole = this.newUserForm.get("defaultRole");
      defaultRole.setValidators(this.getIohUserValidator());
      defaultRole.updateValueAndValidity();
    });
  }

  onSelectedRoleChange() {
    const formData = this.newUserForm.value;
    const index = formData.agency_roles.findIndex((agency) => agency.roles.includes(formData?.defaultRole) && agency.defaultAgency);
    if (index == -1) {
      this.newUserForm.patchValue({
        defaultRole: "",
      });
    }
    this.enableMFA(this.newUserForm.value);
    this.checkSelectedAgencyRoles();
    this.canEnableProvider();
  }

  canEnableProvider() {
    const formData = this.newUserForm.value;

    this.canActivateProviders = formData?.agency_roles?.some((agency_role) => agency_role?.roles?.includes(RoleID.Clinician)) || false;

    if (!this.canActivateProviders) {
      this.newUserForm.get("provider").patchValue("");
      this.newUserForm.get("provider").removeValidators(Validators.required);
      this.newUserForm.get("provider").updateValueAndValidity();
    }

    if (this.canActivateProviders) this.newUserForm.get("provider").addValidators(Validators.required);
  }

  enableMFA(formData) {
    let defaultAgencyIndex = -1;
    if (formData.agency_roles && formData.agency_roles.length)
      defaultAgencyIndex = formData.agency_roles.findIndex((agency_role) => agency_role?.defaultAgency);

    if (formData?.is_company_admin) {
      this.mfaEnabled = true;
      this.newUserForm.get("userSettings").get("mfa").setValue(false);
    } else if (
      defaultAgencyIndex !== -1 &&
      formData?.agency_roles[defaultAgencyIndex]?.roles &&
      formData?.agency_roles[defaultAgencyIndex]?.roles.length
    ) {
      this.mfaEnabled = MfaAllowedRoles.includes(formData.agency_roles[defaultAgencyIndex]?.roles[0]);
      this.newUserForm.get("userSettings").get("mfa").setValue(true);

      if (!this.mfaEnabled) this.newUserForm.get("userSettings").get("mfa").setValue(false);
    } else {
      this.newUserForm.get("userSettings").get("mfa").setValue(false);
      this.mfaEnabled = false;
    }
  }

  disabledRoles(roleItem) {
    const formData = this.newUserForm.value;
    const index = formData.agency_roles.findIndex((agency) => agency.roles.includes(roleItem) && agency.defaultAgency);
    if (index > -1) {
      return false;
    }
    return true;
  }

  disabledAgency(agencyItem) {
    const formData = this.newUserForm.value;
    const index = formData.agency_roles.findIndex((agency) => agency.agency == agencyItem);
    if (index > -1) {
      return true;
    }
    return false;
  }

  createEditUsersForm() {
    let companyId;
    if (!this.users[this.selectedIndex].is_company_admin) {
      companyId = this.companies.filter((e) => e.value === this.users[this.selectedIndex]?.agencyObject[0]?.companyId)[0];
    } else {
      companyId = this.companies.filter((e) => e.value === this.users[this.selectedIndex]?.companyId)[0];
    }
    this.enableMFA(this.users[this.selectedIndex]);
    this.userRegistrationStatus = this.users[this.selectedIndex]?.registrationStatus === REGISTRATION_WAITING_STATUS;

    this.newUserForm.patchValue({
      is_super_admin: this.users[this.selectedIndex].is_super_admin,
      is_company_admin: this.users[this.selectedIndex].is_company_admin,
      is_ioh_user: this.users[this.selectedIndex].is_ioh_user,
      picture: this.users[this.selectedIndex].picture,
      firstName: this.users[this.selectedIndex].firstName,
      lastName: this.users[this.selectedIndex].lastName,
      // gender: this.users[this.selectedIndex].gender,
      phone: {
        countryCode: this.users[this.selectedIndex]?.phone?.countryCode,
        number: this.users[this.selectedIndex]?.phone?.number,
      },
      // fax: {
      //   countryCode: this.users[this.selectedIndex]?.fax?.countryCode,
      //   number: this.users[this.selectedIndex]?.fax?.number,
      // },
      status: this.users[this.selectedIndex].status,
      defaultRole: this.users[this.selectedIndex].defaultRole,
      email: this.users[this.selectedIndex].email,
      // address: {
      //   state: this.users[this.selectedIndex]?.address?.state,
      //   country: this.users[this.selectedIndex]?.address?.country,
      //   city: this.users[this.selectedIndex]?.address?.city,
      //   line1: this.users[this.selectedIndex]?.address?.line1,
      //   line2: this.users[this.selectedIndex]?.address?.line2,
      //   postalCode: this.users[this.selectedIndex]?.address?.postalCode,
      // },
      userSettings: {
        logging: this.users[this.selectedIndex]?.userSettings?.logging,
        loggingView: this.users[this.selectedIndex]?.userSettings?.loggingView,
        isMultiAgenciesAllowed: this.users[this.selectedIndex]?.userSettings?.isMultiAgenciesAllowed,
        mfa: this.users[this.selectedIndex]?.userSettings?.mfa,
      },
      isIOHHelp: this.users[this.selectedIndex]?.isIOHHelp,
      companyId: companyId,
      provider: this.users[this.selectedIndex]?.provider,
    });

    const agency_roles = this.newUserForm.get("agency_roles") as FormArray;
    this.users[this.selectedIndex]?.agency_roles?.forEach((item, index) => {
      agency_roles.push(this.createAgencyWithValues(item.agency.agencyName, item.agency.agencyID, item.roles, item.defaultAgency));
    });
    //this.checkSelectedAgencyRoles(this.selectedIndex);
    this.iohUserChangeSubscriber();
  }

  prepareEditUserForm() {
    this.checkSelectedAgencyRoles();
    this.newUserForm = this.formBuilder.group({
      is_super_admin: false,
      is_IOH_admin: false,
      is_company_admin: false,
      is_ioh_user: false,
      picture: [""],
      firstName: ["", Validators.required],
      lastName: ["", [Validators.required, Validators.minLength(2)]],
      //gender: [''],
      phone: this.formBuilder.group({
        number: ["", Validators.required],
        countryCode: [""],
      }),
      // fax: this.formBuilder.group({
      //   number: [''],
      //   countryCode: [''],
      // }),
      status: [""],
      agency_roles: this.formBuilder.array([]),
      defaultRole: [""],
      email: ["", [Validators.required, Validators.email]],
      // address: this.formBuilder.group({
      //   state: [''],
      //   country: [''],
      //   city: ['', Validators.required],
      //   line1: ['', Validators.required],
      //   line2: [''],
      //   postalCode: [''],
      // }),
      userSettings: this.formBuilder.group(userSettingsValues),
      isIOHHelp: true,
      companyId: [""],
      provider: [""],
    });
  }

  createAgency(): FormGroup {
    const is_company_admin = this.newUserForm.value.is_company_admin;
    const agencyValidator = is_company_admin ? [""] : ["", Validators.required];
    const rolesValidator = is_company_admin ? [[]] : [[], Validators.required];
    return this.formBuilder.group({
      agency: agencyValidator,
      roles: rolesValidator,
      defaultAgency: [false],
    });
  }

  createAgencyWithValues(agencyName, agency, roles, defaultAgency): FormGroup {
    return this.formBuilder.group({
      agency: [agency, Validators.required],
      roles: [roles, Validators.required],
      defaultAgency: [defaultAgency],
    });
  }

  onDefaultAgencyChange(index) {
    const agencyArray = this.newUserForm.get("agency_roles") as FormArray;
    const agency = agencyArray.controls[index];
    if (agency.value.defaultAgency) {
      agencyArray.controls.forEach((item, i) => {
        if (i != index) {
          item.patchValue({
            defaultAgency: false,
          });
        }
      });
    }

    this.onSelectedRoleChange();
  }

  removeChips(index) {
    const agencyArray = this.newUserForm.get("agency_roles") as FormArray;
    agencyArray.controls[index].patchValue({ roles: [] });

    this.onSelectedRoleChange();
  }

  addNewAgency() {
    (this.newUserForm.get("agency_roles") as FormArray).push(this.createAgency());
  }

  getAgencyListing(companyId?: string) {
    let params = new HttpParams();
    if ((this.currentAgencyRole.role == RoleID.Admin || this.currentAgencyRole.role == RoleID.iohAdmin) && !companyId) {
      params = undefined;
    } else if (this.currentAgencyRole.role == RoleID.companyAdmin) {
      params = params.append("companyId", `${this.currentUser.companyId}`);
    } else if (!companyId) {
      params = params.append("agency", this.currentAgencyRole.agency);
      params = params.append("role", this.currentAgencyRole.role);
    }

    if (companyId) {
      params = params.append("companyId", `${companyId}`);
    }

    this.subscribers.push(
      this.agencyService.getAgencies(params).subscribe((res) => {
        if (res.success) {
          this.agencyDetails = res.content.data;
          this.agencies = res.content.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          this.sortAgenciesByName();
          this.agenciesFilter = JSON.parse(JSON.stringify(this.agencies));

          this.originalAgenciesFilter = this.agenciesFilter.map((obj) => Object.assign({}, obj));
        }
      }),
    );
  }

  sortAgenciesByName() {
    this.agencies.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  checkIfDefaultAgencySelected() {
    const agencyArray = this.newUserForm.value;

    const index = agencyArray.agency_roles.findIndex((item) => item.defaultAgency);
    if (agencyArray.agency_roles.length && index > -1) {
      return true;
    }
    if (agencyArray.is_company_admin) {
      return true;
    }
    return false;
  }

  getSelectedIcon(index) {
    const agencyArray = this.newUserForm.value;
    const selectedIndex = this.agencyDetails?.findIndex((item) => item.id == agencyArray.agency_roles[index]?.agency?.value);
    return selectedIndex && selectedIndex != -1 ? this.agencyDetails[selectedIndex]?.picture : "";
  }

  onIOHAndSuperAdminChange() {
    if (!this.newUserForm.value.is_super_admin && !this.newUserForm.value.is_ioh_user) {
      this.newUserForm.get("agency_roles").setValidators([Validators.required]);
      this.newUserForm.get("agency_roles").updateValueAndValidity();
      this.newUserForm.get("defaultRole").setValidators([]);
      this.newUserForm.get("defaultRole").updateValueAndValidity();
      const data = (this.newUserForm.get("agency_roles") as FormArray).at(0);
      (this.newUserForm.get("agency_roles") as FormArray).clear();
      if (data) {
        (this.newUserForm.get("agency_roles") as FormArray).push(data);
      }
    }

    if (this.newUserForm.value.is_super_admin) {
      this.newUserForm.get("agency_roles").clearValidators();
      this.newUserForm.get("agency_roles").updateValueAndValidity();

      this.newUserForm.get("defaultRole").clearValidators();
      this.newUserForm.get("defaultRole").updateValueAndValidity();

      this.newUserForm.get("defaultRole").patchValue("");
      (this.newUserForm.get("agency_roles") as FormArray).clear();
    }
  }

  getUserListing() {
    try {
      let pagination = { pageNumber: 0, pageSize: 5 };
      this.subscribers.push(
        this.userService.getAllUsers(pagination).subscribe((res) => {
          if (res?.success) {
            this.users = res?.content?.data;
            this.totalUsers = res?.content?.totalElements;
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
        }),
      );
    } catch (e) {
      console.log(e);
      this.messageHandlerService.generateToastMessage("error", "Communication error: ", e?.errorMessage);
    }
  }

  getAllRoles(user): any {
    if (user.is_company_admin) {
      return ["COMPANY_ADMIN"];
    }
    if (user.agency_roles && user.agency_roles.length) {
      let res = user.agency_roles.flatMap((role) => role.roles);

      if (res.length) {
        res.sort();

        return Array.from(new Set(res));
      }
    }
  }

  canCreateUser() {
    return (
      this.currentUser?.is_super_admin ||
      this.currentAgencyRole.role == RoleID.AgencyAdmin ||
      this.currentUser?.is_IOH_admin ||
      this.currentUser?.is_company_admin
    );
  }

  searchUsers() {
    if (this.searchUser === undefined || this.searchUser === null || this.searchUser === "") {
      this.pageNo = 0;
      this.getUsers();
      this.paginator.changePage(this.pageNo);
    } else if (this.searchUser && this.searchUser.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.getUsers();
      this.paginator.changePage(this.pageNo);
    }
  }

  getCompanyId() {
    if (this.currentUser.is_company_admin) {
      return this.currentUser.companyId;
    }
    return null;
  }

  hasUserActivityInPast(user: User) {
    const roles = this.getAllRoles(user);
    const oneHour = 60 * 60 * 1000;
    const lastActivity = new Date(user.lastActivity);
    const lastAction = new Date(user.lastAction);

    const diffHours = (lastActivity.getTime() - lastAction.getTime()) / oneHour;

    if (!Number.isNaN(diffHours) && diffHours > USER_ACTIVITY_HOURS_IN_PAST && roles?.includes(RoleID.Clinician)) {
      return true;
    } else return !!(Number.isNaN(diffHours) && roles?.includes(RoleID.Clinician));
  }

  getUsers(order: number = 0, orderBy: string = "") {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;
      const nonIOHUsersSorting = JSON.parse(this.localStorageService.get("nonIOHUsersSorting"));
      let request = {
        search: this.searchUser ? this.searchUser : "",
        status: this.selectedUserStatus,
        partOfAgency: this.selectedFilterAgency ? this.selectedFilterAgency : [],
        globalAgency: this.selectedAgency ? this.selectedAgency : "",
        role: this.selectedRoles,
        //pagination: { pageNumber: this.selectedUserStatus === "" ? 0 : this.pageNo, pageSize: this.noOfUsersPerRow },
        pagination: { pageNumber: this.pageNo, pageSize: this.noOfUsersPerRow },
        IOH: false,
        order: order ? order.toString() : nonIOHUsersSorting?.order.toString(),
        orderBy: orderBy ? orderBy : nonIOHUsersSorting?.orderBy,
        companyId: this.getCompanyId(),
        usersFilter: this.userFilterSelectedRows,
        provider: this.selectedProvider,
      };

      //remove GPS from usersFilter
      if (request.usersFilter && request.usersFilter.length) {
        request.usersFilter = request.usersFilter.filter((item) => item !== "GPS");
      }

      this.subscribers.push(
        this.userService.getUsersWithSearch(request).subscribe((res) => {
          if (res?.success) {
            this.users = res?.content?.data.map((user) => {
              return { ...user, noActivity: this.hasUserActivityInPast(user) };
            });
            this.totalUsers = res?.content?.totalUsersCount;
            if (!this.isChangingPage) {
              this.changePage();
            }
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
          this.loading = false;
        }),
      );
    } catch (e) {
      console.log(e);
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage("error", "Communication error: ", e?.errorMessage);
      this.loading = false;
    }
  }

  openEditUserForm(index: number) {
    this.selectedIndex = index;
    this.showEditForm = true;
    this.display = true;
    this.prepareEditUserForm();
    this.createEditUsersForm();
  }

  createTooltip(users: User) {
    let res = `<b>Created On:</b> ${this.datePipe.transform(users.createdAt, dateAndTimeFormat)} ` + "<br>";
    if (users?.creator) res = res + `<b>Created By:</b> ${users?.creator}` + "<br>";

    if (users?.platform) res = res + `<b>Platform:</b> ${users?.platform} ` + "<br>";

    if (users?.desktopVersion) res = res + `<b>Desktop Version:</b> ${users?.desktopVersion} ` + "<br>";

    if (users?.androidVersion) res = res + `<b>Android Version:</b> ${users?.androidVersion}` + "<br>";

    if (this.checkIfClinician(users))
      res = res + `<b>Permissions:</b> ${users?.permissions && users?.permissions.length ? users?.permissions.join(", ") : ""}` + "<br>";

    if (users?.lastLogin) res = res + `<b>Last Login:</b> ${this.datePipe.transform(users?.lastLogin, dateAndTimeFormat)}` + "<br>";

    // if(users?.lastActivity)
    //   res = res + `<b>Last Activity:</b> ${this.datePipe.transform(users?.lastActivity, dateAndTimeFormat)}` + '<br>'
    //
    // if(users?.lastAction)
    //   res = res + `<b>Last Action:</b> ${this.datePipe.transform(users?.lastAction, dateAndTimeFormat)}` + '<br>'

    return res;
  }

  checkIfClinician(user: User) {
    return !!user.agency_roles?.find((agency_role) => agency_role.roles.includes(RoleID.Clinician));
  }
  changeStatus(index: number) {
    let user: User = this.users[index];
    user.status = user.status === "Active" ? "Inactive" : "Active";
    this.updateUserStatus(user);
  }

  updateUserStatus(user: User) {
    this.progressBarService.setProgressBarVisibility(true);
    this.subscribers.push(
      this.userService.updateUser(user).subscribe((res) => {
        if (res.success) {
          this.pageNo = 0;
          this.getUsers();
          this.paginator.changePage(this.pageNo);
          this.onSlideBarClose();
          this.messageHandlerService.generateToastMessage("success", "", "User Updated Successfully");
          this.progressBarService.setProgressBarVisibility(false);
        } else {
          this.messageHandlerService.generateToastMessage("error", "", "User Not Updated");
          this.progressBarService.setProgressBarVisibility(false);
        }
      }),
    );
  }

  onAddUserClick() {
    this.showEditForm = false;
    //this.mfaEnabled = false;
    this.display = true;
    this.selectedIndex = -1;
    this.createUsersForm();
    this.getAgencyListing();

    const selectedAgencyIndex = this.filteredAgencies
      ? this.filteredAgencies?.findIndex((filteredAgency) => filteredAgency.value === this.currentAgencyRole.agency)
      : -1;
    if (!this.currentUser.is_super_admin && !this.currentUser.is_ioh_user && selectedAgencyIndex != -1) {
      (this.newUserForm.get("agency_roles") as FormArray).at(0).get("agency")?.patchValue(this.filteredAgencies[selectedAgencyIndex]);
    }

    if (this.currentAgencyRole.role === this.SUPER_ADMIN || this.currentAgencyRole.role === this.IOH_ADMIN) {
      this.markCompanyIdAsRequired();
    }
  }

  bulkUpload(event) {
    this.progressBarService.setProgressBarVisibility(true);
    const file = event.files[0];
    if (file.type !== BULK_USER_UPLOAD_ALLOWED_FORMAT) {
      this.bulkUploadUsers.clear();
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage("error", "", BULK_USER_UPLOAD_FILE_FORMAT_ERROR);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    this.userService.bulkUpload(formData).subscribe((res) => {
      if (res && res.success) {
        this.progressBarService.setProgressBarVisibility(false);
        this.getUsers();
        this.messageHandlerService.generateToastMessage("success", "", BULK_USER_UPLOAD);
        this.bulkUploadUsers.clear();
      } else {
        this.bulkUploadUsers.clear();
        let errorMessage;
        if (res && res?.message && res?.message?.length > 0) {
          errorMessage = res?.message || res.errorMessage;
        } else {
          errorMessage = res;
        }

        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage("error", "", BULK_USER_UPLOAD_FAILED_MESSAGE);
      }
    });
  }

  uploadTemplate(event) {
    this.progressBarService.setProgressBarVisibility(true);
    const file = event.files[0];

    const formData = new FormData();
    formData.append("file", file);
    this.userService.uploadTemplate(formData).subscribe((res) => {
      this.progressBarService.setProgressBarVisibility(false);
      this.bulkUploadUsers.clear();
    });
  }

  bulkUploadTemplateClick() {
    this.agencyService.getBulkUploadUsersTemplate().subscribe((res: any) => {
      if (res.success) {
        const link = document.createElement("a");
        link.href = res.content.url;
        link.download = "bulkUploadTemplate";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.messageHandlerService.generateToastMessage("success", "", FILE_DOWNLOAD_SUCCESS);
      } else {
        this.messageHandlerService.generateToastMessage("error", "", "Error Downloading File: " + res.message);
      }
    });
    this.dropdown.writeValue(this.dropdown.value === "Download Template" ? null : "Download Template");
  }

  onDropDownChange(index: number, selectedOption) {
    if (selectedOption === "Edit") {
      this.selectedIndex = index;
      this.showEditForm = true;
      this.display = true;
      this.prepareEditUserForm();
      this.createEditUsersForm();
      this.markCompanyIdAsRequired();
      this.getAgencyListing(this.preFetchCompanyId());
    } else if (selectedOption === "Delete") {
      console.log(index);
      console.log(selectedOption);
      this.selectedIndex = index;
      this.onDeleteClick();
    }
  }

  onSidebarShow() {
    if (
      (this.currentAgencyRole.role == this.COMPANY_ADMIN || this.currentAgencyRole.role == this.AGENCY_ADMINISTRATOR) &&
      this.users[this.selectedIndex]?.id == this.currentUser?.id
    ) {
      this.messageHandlerService.generateToastMessage("error", "", "Sorry - You are not able to edit your own information or roles");
    } else if (this.users[this.selectedIndex]?.is_company_admin && this.currentUser?.is_company_admin) {
      this.messageHandlerService.generateToastMessage("error", "", "Sorry - You are not allowed to edit information or roles for following user");
    }

    this.canEnableProvider();
  }

  disableEditMode() {
    return (
      (this.currentAgencyRole.role == this.COMPANY_ADMIN && this.users[this.selectedIndex]?.is_company_admin) ||
      (this.newUserForm.value?.email == this.currentUser?.email &&
        (this.currentAgencyRole.role == this.AGENCY_ADMINISTRATOR || this.currentAgencyRole.role == this.COMPANY_ADMIN))
    );
  }

  onDeleteClick() {
    if (this.users[this.selectedIndex].aws_cognito_id == this.currentUser.aws_cognito_id) {
      this.messageHandlerService.generateToastMessage("error", "", "Cannot delete logged in user");
      return;
    }

    this.onSlideBarClose();
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to delete user.",
      acceptButtonStyleClass: "btn-delete",
      accept: () => {
        this.deleteUser();
      },
    });
  }

  deleteUser(): void {
    this.progressBarService.setProgressBarVisibility(true);
    this.subscribers.push(
      this.userService.deleteUser({ aws_cognito_id: this.users[this.selectedIndex].aws_cognito_id }).subscribe((res) => {
        if (res.success) {
          this.getUsers();
          this.messageHandlerService.generateToastMessage("success", "", "User deleted Successfully");
        } else if (res) {
          this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
        }
        this.progressBarService.setProgressBarVisibility(false);
      }),
    );
  }

  setAgency(event: any, index: number) {
    (this.newUserForm.get("agency_roles") as FormArray)
      .at(index)
      .get("agency")
      ?.patchValue({ agencyID: event?.value?.id, agencyName: event?.value?.name });
  }

  createUser() {
    let newUser = this.newUserForm.value;
    newUser.createdBy = this.currentUser.aws_cognito_id;
    newUser.agency_roles = newUser.agency_roles.map((item) => {
      return {
        ...item,
        agency: {
          agencyID: item?.agency,
          agencyName: this.agencies.find((agency) => agency.value == item?.agency)?.label,
        },
      };
    });

    const index = newUser?.agency_roles.findIndex((agency_role) => agency_role.defaultAgency);

    if (index != -1) newUser.defaultRole = newUser?.agency_roles[index]?.roles[0];

    if (this.currentUser.is_company_admin) {
      newUser.companyId = this.currentUser.companyId;
    }

    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      if (newUser.companyId && "label" in newUser.companyId && "value" in newUser.companyId) {
        newUser.companyId = newUser.companyId.value;
      } else {
        delete newUser.companyId;
      }
    }

    this.subscribers.push(
      this.userService.createUser(newUser).subscribe((res) => {
        if (res.success) {
          this.getUsers();

          (this.newUserForm.get("agency_roles") as FormArray).controls.forEach((control) => {
            const agency = this.agencies?.find((item) => item.value === control.value.agency?.value);
            control.get("agency").patchValue({ agencyName: agency?.label, agencyID: agency?.value });
          });

          if (this.adminRoles()) this.getAgencies();

          this.onSlideBarClose();
          this.showUserCreationSuccessPopup = true;
        } else {
          this.showUserCreationSuccessPopup = false;
          this.messageHandlerService.generateToastMessage("error", "", res.errorMessage);
        }
      }),
    );
  }

  checkIfEmailExists() {
    this.subscribers.push(
      this.userService.findUserByEmail({ email: this.newUserForm.value.email }).subscribe((res) => {
        if (res.success && res.content.data.length && res.content.data[0]?.deleted) {
          this.messageHandlerService.generateToastMessage("error", "", USER_ACCOUNT_ALREADY_EXIST);
        } else if (res.success && res.content.data.length) {
          this.messageHandlerService.generateToastMessage("error", "", EMAIL_ALREADY_REGISTERED_USER);
        } else if (res.success && !res.content.data.length) {
          this.createUser();
        } else {
          this.messageHandlerService.generateToastMessage("error", "", res.errorMessage);
        }
      }),
    );
  }

  async onSelect(event) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append("file", file);

    if (event.currentFiles && event.currentFiles.length > 0) {
      this.progressBarService.setProgressBarVisibility(true);
      await this.userService.uploadFile(formData).subscribe((res) => {
        if (res?.message?.Location) {
          this.currentImage = res;
          this.newUserForm.get("picture").setValue(res.message.Location);
          this.fileUpload.clear();
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    }
  }

  async deleteFile() {
    await this.userService.deleteFile({ objectKey: this.newUserForm.value.picture }).subscribe((res) => {
      if (res) {
        this.currentImage = null;
        this.fileUpload.clear();
        this.newUserForm.patchValue({ picture: null });
      }
    });
  }

  onSlideBarClose() {
    this.display = false;
    this.emailType = "";
    this.showEditForm = false;
    this.disableMultiAgencyFilter = true;
    (this.newUserForm.get("agency_roles") as FormArray).clear();
    this.newUserForm.reset();

    this.getAgencyListing();
    this.canEnableProvider();
  }

  updateUser() {
    if (
      this.users[this.selectedIndex].aws_cognito_id == this.currentUser.aws_cognito_id &&
      this.currentAgencyRole?.role !== this.AGENCY_ADMINISTRATOR
    ) {
      this.messageHandlerService.generateToastMessage("error", "", "Cannot update logged in user");
      return;
    }

    const user = { ...this.users[this.selectedIndex], ...this.newUserForm.value };

    user.agency_roles = user.agency_roles.map((item) => {
      return {
        ...item,
        agency: {
          agencyID: item?.agency,
          agencyName: this.agencies.find((agency) => agency.value == item?.agency)?.label,
        },
      };
    });

    if (user.agencyObject) {
      delete user.agencyObject;
    }
    const index = user?.agency_roles.findIndex((agency_role) => agency_role.defaultAgency);

    if (index != -1) user.defaultRole = user?.agency_roles[index]?.roles[0];

    if (this.currentUser.is_company_admin) {
      user.companyId = this.currentUser.companyId;
    }

    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      if (user.companyId && "label" in user.companyId && "value" in user.companyId) {
        user.companyId = user.companyId.value;
      } else {
        delete user.companyId;
      }
    }

    this.subscribers.push(
      this.userService.updateUser(user).subscribe((res) => {
        if (res.success) {
          this.getUsers();
          this.onSlideBarClose();
          this.messageHandlerService.generateToastMessage("success", "", "User Updated Successfully");
        } else {
          this.messageHandlerService.generateToastMessage("error", "", res?.errorMessage);
        }
      }),
    );
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage("globalPageLimit", "perPage", event.rows);
      this.pageNo = event.page;
      this.noOfUsersPerRow = event.rows;
      this.getUsers();
    }
  }

  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }

  onSelectAgencyOrRole() {
    this.pageNo = 0;
    this.getUsers();
    this.paginator.changePage(this.pageNo);
    this.resetMultiSelectDataInLocalStorage();
  }

  getAgencies() {
    let params = new HttpParams();
    if (this.currentAgencyRole.role == RoleID.companyAdmin) {
      params = params.append("companyId", `${this.currentUser.companyId}`);
    }
    this.agencyService.getAgencies(params).subscribe((res) => {
      if (res.success) {
        const agencies = res.content.data;
        const allOption = { label: "All", value: "" };
        const mappedAgencies = agencies.map(({ name, id }) => ({ label: name, value: id }));
        this.filteredAgencies = [allOption, ...mappedAgencies];
      }
    });
  }

  onChangeUserStatusFilter(event) {
    this.userStatusFilter = event.value;
    this.pageNo = 0;
    this.getUsers();
    this.paginator.changePage(this.pageNo);
    this.resetMultiSelectDataInLocalStorage();
  }
  adminRoles() {
    return superAdminRoles.includes(this.currentAgencyRole?.role);
  }

  sendResetPasswordEmail() {
    const email = this.newUserForm.value.email;
    this.userService.resetPassword(email).subscribe(
      (response) => {
        if (response.success) {
          const code = response.content.data.code;
          if (code === NEW_PASSWORD_REQUEST_SEND) {
            this.messageHandlerService.generateToastMessage("success", "", TEMP_PASSWORD_EXPIRED);
          } else {
            this.utilService.sendResetPasswordEmail(email);
          }
        } else {
          this.messageHandlerService.generateToastMessage("error", "", "Error in the API call." + response.errorMessage);
        }
      },
      (error) => {
        this.messageHandlerService.generateToastMessage(error, "", "Error while fetching data. " + error.message);
      },
    );
  }

  /**
   * Send activation email to the user
   */
  sendActivationEmail() {
    try {
      this.subscribers.push(
      this.userService.sendActivationEmail({ email: this.newUserForm.value.email }).subscribe((res) => {
        if (res.success) {
          this.messageHandlerService.generateToastMessage("success", "", res?.message || ACTIVATION_EMAIL_SUCCESS_MESSAGE);
        }else {
          this.messageHandlerService.generateToastMessage("error", "", "Error in the API call." + res?.message);
        }
      }),
    )

    }catch (error) {
      this.messageHandlerService.generateToastMessage("error", "", error.message);
    }
  }

  isRowVisible(rowName: string) {
    if (this.selectedRows && this.selectedRows.length) return this.selectedRows.find((selectedRow) => selectedRow === rowName);
  }

  onSelectedRowsChange() {
    let selectedNonIOH = { selectedNonIOHUserRows: [] };

    if (this.localStorageService.get("userSettings" + this.currentUser?.aws_cognito_id))
      selectedNonIOH = JSON.parse(this.localStorageService.get("userSettings" + this.currentUser?.aws_cognito_id));

    selectedNonIOH.selectedNonIOHUserRows = this.selectedRows;
    this.localStorageService.set("userSettings" + this.currentUser?.aws_cognito_id, JSON.stringify(selectedNonIOH));
  }

  isPhoneAndFaxNumberValid() {
    return this.utilService.isPhoneAndFaxNumberValid(this.newUserForm.value);
  }

  async checkSelectedAgencyRoles(selectedIndex?) {
    const formData = (await selectedIndex) ? this.users[selectedIndex] : this.newUserForm.value;
    if (!formData) {
      return;
    }

    const isMultiAgencyEnabled = isMultiAgenciesUserEnabled(formData?.agency_roles);
    if (isMultiAgencyEnabled) {
      this.disableMultiAgencyFilter = false;
      this.newUserForm.get("userSettings").get("isMultiAgenciesAllowed").patchValue(formData?.userSettings?.isMultiAgenciesAllowed);
    } else {
      this.disableMultiAgencyFilter = true;
      this.newUserForm.get("userSettings").get("isMultiAgenciesAllowed").patchValue(false);
    }
  }

  //   if(formData){
  //   formData?.agency_roles?.forEach(obj => {
  //     if(obj.roles.some(role => role == RoleID.AgencyAdmin || role == RoleID.OfficeWorker )){
  //       console.log("reached in if");
  //       this.disableMultiAgencyFilter = false;
  //     }
  //     else{
  //       this.disableMultiAgencyFilter = true;
  //       this.newUserForm.get('userSettings').get('isMultiAgenciesAllowed').patchValue(false)
  //     }
  //   })
  // }

  shouldDisableCompanyField() {
    if (this.currentUser?.is_super_admin || this.currentUser?.is_IOH_admin) {
      return false;
    }

    return true;
  }

  preFetchCompanyId() {
    if (this.currentUser?.is_company_admin || this.currentAgencyRole.role == this.AGENCY_ADMINISTRATOR) {
      return this.currentUser.companyId;
    }

    if (
      (this.currentUser?.is_IOH_admin || this.currentUser?.is_super_admin) &&
      this.newUserForm.value.companyId &&
      this.newUserForm.value.companyId &&
      "value" in this.newUserForm.value.companyId
    ) {
      return this.newUserForm.value.companyId.value;
    }
  }

  getCompanyListing(companyId?: string) {
    this.subscribers.push(
      this.companyService.getAllCompanies({ ...(companyId ? { companyId } : null) }).subscribe((res) => {
        if (res.success) {
          const activeCompanies = res.content.data.filter((company) => company.status == UserStatus.active);
          this.companies = activeCompanies.map((item) => ({ label: item.name, value: item.id }));
          this.companiesPayload = res.content.data;

          if (this.currentUser?.is_company_admin) {
            this.prePopulateCompanyField();
          }
        }
      }),
    );
  }

  prePopulateCompanyField() {
    const filteredCompany = this.companies.filter((e) => e.value === this.currentUser.companyId)[0];
    this.newUserForm.get("companyId").patchValue(filteredCompany);
  }

  onSelectCompany(event) {
    if (event.value) {
      this.confirmationService.confirm({
        message: "Please confirm if you wanted to select this Company",
        header: "Confirm Company?",
        icon: "pi pi-upload",
        acceptLabel: "Confirm",
        rejectLabel: "Cancel",
        accept: () => {
          this.getAgencyListing(this.preFetchCompanyId());
          this.changeAgencyRole();
        },
        reject: () => {
          this.newUserForm.get("companyId").patchValue("");
        },
      });
    }
  }

  filterCompanies(event) {
    let filtered: any[] = [];
    let query = event.query;
    const newUserCompanies = this.companies.filter((company) => company.label !== "All" && company.value !== "");

    for (let i = 0; i < newUserCompanies.length; i++) {
      let checkIfAlreadySelected = false;
      let company = newUserCompanies[i];
      if (company?.label?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        const formData = this.newUserForm.value;

        if (company?.value && formData?.companyId?.value == company?.value) {
          checkIfAlreadySelected = true;
        }

        if (!checkIfAlreadySelected) {
          filtered.push(company);
        }
      }
    }

    this.filteredCompanies = filtered;
  }

  getCompanySelectedIcon(index) {
    const selectedIndex = this.companiesPayload.find((e) => e.id === index?.value);

    return selectedIndex?.logo ?? "";
  }

  removeCompany() {
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to delete this Company",
      header: "Delete Company?",
      icon: "pi pi-trash",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "btn-delete",
      accept: () => {
        this.newUserForm.get("companyId").patchValue("");
        this.changeAgencyRole();
        this.getAgencyListing();
        this.canEnableProvider();
      },
      reject: () => {},
    });
  }

  showRemoveCompany() {
    const company = this.newUserForm?.value?.companyId?.value;

    if ((this.currentUser.is_IOH_admin || this.currentUser.is_super_admin) && company && company !== "") {
      return true;
    } else {
      return false;
    }
  }

  companyAdminChange() {
    if (this.newUserForm.get("is_company_admin").value) {
      this.markCompanyIdAsRequired();
      this.newUserForm.get("userSettings").get("mfa").setValue(true);
      this.mfaEnabled = true;
    } else {
      this.newUserForm.get("userSettings").get("mfa").setValue(false);
      this.mfaEnabled = false;
    }
    this.changeAgencyRole();
  }

  changeAgencyRole() {
    this.newUserForm.get("agency_roles").clearValidators();
    this.newUserForm.get("agency_roles").updateValueAndValidity();
    (this.newUserForm.get("agency_roles") as FormArray).clear();

    this.newUserForm.get("companyId").setValidators([Validators.required]);
  }

  markCompanyIdAsRequired() {
    const companyIdControl = this.newUserForm.get("companyId");

    if (companyIdControl && this.currentAgencyRole?.role !== this.AGENCY_ADMINISTRATOR) {
      companyIdControl.setValidators(Validators.required);
      companyIdControl.updateValueAndValidity();
    }
  }

  isFormControlRequired(controlName: string): boolean {
    const control = this.newUserForm.get(controlName);
    if (control) {
      const validators = control.validator({} as any);
      return validators && validators.required;
    }
    return false;
  }

  getSelectedAgencyID(user: User) {
    const selectedAgencyRole = user.agency_roles?.find((agency_role) => agency_role.roles.includes(RoleID.Clinician));
    return selectedAgencyRole?.agency?.agencyID;
  }

  onSendEmailClick(user: User) {
    this.progressBarService.setProgressBarVisibility(true);

    this.userService
      .sendPermissionsEmail({ to: user?.email, agencyID: this.getSelectedAgencyID(user) })
      .pipe(
        tap((res) => {
          const message = res && res.success ? "Email sent successfully" : "Email not sent";
          this.messageHandlerService.generateToastMessage(res && res.success ? "success" : "error", "", message);
        }),
        catchError((error) => {
          this.messageHandlerService.generateToastMessage("error", "", "Email not sent");
          return throwError(() => new Error(error));
        }),
        finalize(() => {
          this.progressBarService.setProgressBarVisibility(false);
        }),
      )
      .subscribe();
  }

  clearSelectedValues(selectedValues: any[]) {
    if (selectedValues && selectedValues.length) {
      selectedValues.splice(0, selectedValues.length);
      this.getUsers();
      this.resetMultiSelectDataInLocalStorage();
    }
  }
  sortSelectedValues() {
    this.agenciesFilter = this.utilService.sortSelectedValuesOfMultiSelect(this.originalAgenciesFilter, this.selectedFilterAgency);
  }
}
