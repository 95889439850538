import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VisitComponent} from "../visit.component";
import {TableModule} from "primeng/table";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/shared.module";
import {DockModule} from "primeng/dock";
import {PaginatorModule} from "primeng/paginator";
import {GMapModule} from 'primeng/gmap';
import {DialogModule} from 'primeng/dialog';
import {SidebarModule} from 'primeng/sidebar';
import {CalendarModule} from "primeng/calendar";
import {SupportModule} from "../../support/support.module";
import {MultiSelectModule} from "primeng/multiselect";


@NgModule({
  declarations: [
    VisitComponent,
  ],
  exports: [
    VisitComponent,
  ],
    imports: [
        CommonModule,
        TableModule,
        DropdownModule,
        FormsModule,
        SharedModule,
        DockModule,
        PaginatorModule,
        DialogModule,
        GMapModule,
        SidebarModule,
        CalendarModule,
        SupportModule,
        MultiSelectModule,
    ]
})
export class VisitModule { }
