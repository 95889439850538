import { NgModule } from '@angular/core';
import { EsocComponent } from '../e-soc/e-soc/esoc.component';
import { EsocVerificationComponent } from './esoc-verification.component';
import { EsocModule } from '../e-soc/esoc.modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import {CalendarModule} from "primeng/calendar";
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    EsocVerificationComponent,
  ],
  providers: [
    EsocComponent,
  ],
  imports: [
    EsocModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    ReactiveFormsModule,
    InputMaskModule,
    CalendarModule,
    SharedModule
  ],exports: [
    EsocVerificationComponent
  ]
})
export class EsocVerificationModule { }
