import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Patient,
  patientSubmissionDropdown,
  submissionStatus,
  SubmissionStatusColor, submissionTypes,
  viewByVisitDropdown
} from "../models/patient";
import {PatientSubmissions, SubmissionList} from "../models/patientSubmissions";
import {User} from "../models/user";
import {Subscription} from "rxjs";
import {UserDataManager} from "../user/UserDataManager";
import {
  adminRoles,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  dateAndTimeFormat,
  dateFormat,
  dateFormatStartWithYear, DEFAULT_CASE_MANAGER_NAME_LENGTH,
  DEFAULT_PATIENT_ID_LENGTH,
  DEFAULT_ROLE_NAME_LENGTH, DEFAULT_SEARCH_MIN_CHARACTER,
  DEFAULT_UNMATCHED_MRN_LENGTH,
  RoleID,
  rowsPerPageOptions, SEARCH_PATIENT_BY_CASE_CLINICIAN,
  SEARCH_PATIENT_BY_MRN,
  SEARCH_UNMATCHED_VISIT_BY_ID
} from "../common/Constants";
import {ProgressBarService} from "../services/progress-bar.service";
import {PatientService} from "../services/patient/patient.service";
import {MessageHandlerService} from "../services/message-handler.service";
import {RoleEnum} from "../models/agency";
import {UtilService} from "../services/util/util.service";
import {DatePipe} from "@angular/common";
import {Paginator} from "primeng/paginator";
import {Router} from "@angular/router";
import {LocalStorageService} from "../services/local-storage.service";

declare var google : any
@Component({
  selector: 'app-unmatched-visits',
  templateUrl: './unmatched-visits.component.html',
  styleUrls: ['./unmatched-visits.component.scss']
})
export class UnmatchedVisitsComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: Paginator;
  selectViewByVisits: string = viewByVisitDropdown[1].value;
  totalUnMatchedVisits: number = 0;
  unmatchedVisits: SubmissionList[] = [];
  selectedSubmissions: SubmissionList[];
  currentUser: User;
  subscriptions: Subscription[] = [];
  RoleID = RoleID;
  selectedSubmissionStatus: string = submissionStatus[0].value;
  pageNo: number = 0;
  noOfRecordPerRow: number = 10;
  searchPatient: string = '';
  searchByVisitDate: any;
  searchByVisitDates: any = {};
  searchByClinician: string = '';
  searchByClinicianPlaceHolder = SEARCH_PATIENT_BY_CASE_CLINICIAN;
  selectedAgency: string = "";
  currentAgency: { agency: string, role: string };
  submissionStatus = submissionStatus;
  display = false
  patientSubmission: PatientSubmissions | any;
  patientSubmissionRowSelected: number;
  selectedVisitDates : string;
  selectedVisitType: string;
  options :any;
  locationPopupDisplay :boolean = false;
  overlays : any;
  isChangingPage: boolean = false;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  viewByVisitDropdown = viewByVisitDropdown;
  eSOCUploadDisplay: boolean = false;
  patients: Patient[] = [];
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  RoleEnum = RoleEnum;
  allowAdminRoles : boolean = false;
  rangeDates: Date[];
  selectedVisitDate: any = null;
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  searchPatientByMRN = SEARCH_PATIENT_BY_MRN;
  searchUnmatchedVisitByID = SEARCH_UNMATCHED_VISIT_BY_ID;
  defaultPatientIdLength =  DEFAULT_PATIENT_ID_LENGTH;
  defaultUnmatchedMrnLength = DEFAULT_UNMATCHED_MRN_LENGTH;
  defaultCaseManagerNameLength = DEFAULT_CASE_MANAGER_NAME_LENGTH;
  defaultRoleNameLength = DEFAULT_ROLE_NAME_LENGTH;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  selectedSubmissionTypesForAPI;
  selectedSubmissionTypes;
  submissionTypes = submissionTypes;

  constructor(
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private utilService : UtilService,
    private datePipe: DatePipe,
    private router: Router,
    private localStorageService: LocalStorageService,
    ) { }

  ngOnInit(): void {
    this.getDataFromLocalStorage();
    this.getUserProfile();
    this.getCurrentAgencyRole();
    this.adminRoles();
    this.sortSelectedVisits();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getDataFromLocalStorage() {
    const visitState = JSON.parse(this.localStorageService.get('unMatchedVisitState'));
    this.searchPatient = visitState?.searchPatient ? visitState?.searchPatient : '';
    this.searchByClinician = visitState?.searchByClinician ? visitState?.searchByClinician : '';
    this.rangeDates = visitState?.rangeDates ? visitState?.rangeDates : null;
    this.selectedSubmissionTypes = visitState?.selectedSubmissionTypes ? visitState?.selectedSubmissionTypes : '';

    if(this.selectedSubmissionTypes && this.selectedSubmissionTypes.length) {
      this.selectedSubmissionTypesForAPI = this.selectedSubmissionTypes.map(selectedSubmissionType => {
        return selectedSubmissionType.value;
      });
    }

    if (this.rangeDates && this.rangeDates.length && this.rangeDates[0]) {
      let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      this.searchByVisitDates.startDate = this.utilService.convertToISOString(startDateFilter, true);
      this.rangeDates[0] = new Date(this.rangeDates[0]);
    }

    if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1]) {
      let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);
      this.searchByVisitDates.endDate = this.utilService.convertToISOString(endDateFilter, false);
      this.rangeDates[1] = new Date(this.rangeDates[1]);
    }
  }

  onSubmissionTypeDropdownChange() {
    this.pageNo = 0;
    this.setDataInLocalStorage('pageNo', this.pageNo);
    this.setDataInLocalStorage('selectedSubmissionTypes', this.selectedSubmissionTypes);
    this.selectedSubmissionTypesForAPI = this.selectedSubmissionTypes.map(selectedSubmissionType => {
      return selectedSubmissionType.value;
    });
    this.getSubmissionWithOutPatients();
    this.resetPaginator();
  }

  clearSelectedValues() {
    if (this.selectedSubmissionTypes && this.selectedSubmissionTypes.length) {
      this.selectedSubmissionTypes = [];
      this.onSubmissionTypeDropdownChange();
    }
  }

  setDataInLocalStorage(key, value) {
    const visitState: any = JSON.parse(this.localStorageService.get('unMatchedVisitState'));
    if (visitState) {
      visitState[key] = value;
      this.localStorageService.set('unMatchedVisitState', JSON.stringify(visitState));
    } else {
      let visitNewState = {}
      visitNewState[key] = value;
      this.localStorageService.set('unMatchedVisitState', JSON.stringify(visitNewState));
    }
  }

  searchByPatient(searchParam: any) {
    this.setDataInLocalStorage('searchPatient', this.searchPatient);
    this.setDataInLocalStorage('searchByClinician', this.searchByClinician);

    if (searchParam.length >= this.defaultSearchMinCharacter || searchParam == "") {
      this.pageNo = 0;
      this.setDataInLocalStorage('pageNo', this.pageNo);
      this.getSubmissionWithOutPatients();
    }
    //this.pageNo = 0;
    //this.getSubmissionWithOutPatients();
  }

  isSearchEmpty() {
    if (
      this.searchPatient === undefined ||
      this.searchPatient === null ||
      this.searchPatient === ''
    ) {
      this.pageNo = 0;
      this.getSubmissionWithOutPatients();
    }
  }

  onlyAdminRoles() {
    return adminRoles.includes(this.currentUser.currentUserRole);
  }

  selectVisitDate() {
    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false)
    };

    this.pageNo = 0;

    this.setDataInLocalStorage('rangeDates', this.rangeDates)
    this.setDataInLocalStorage('searchByVisitDates', this.searchByVisitDates)
    this.setDataInLocalStorage('pageNo', this.pageNo)
    this.getSubmissionWithOutPatients();
  }

  onCalendarClear() {
    this.selectedVisitDate = null;
    this.rangeDates = null;
    this.searchByVisitDates = null;
    this.pageNo = 0;

    this.setDataInLocalStorage('pageNo', this.pageNo);
    this.setDataInLocalStorage('selectedVisitDate', this.selectedVisitDate);
    this.setDataInLocalStorage('rangeDates', this.rangeDates);
    this.setDataInLocalStorage('searchByVisitDates', this.searchByVisitDates);
    this.getSubmissionWithOutPatients();
  }

  getUserProfile() {
    this.subscriptions.push(this.userDataManager.user$.subscribe(res => {
      if (res) {
        this.currentUser = res;
      }
    }));
  }

  getSubmissionWithOutPatients() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      let request = {
        pagination: {
          pageNumber: this.pageNo,
          pageSize: this.noOfRecordPerRow
        },
        visitStatus:this.selectedSubmissionStatus,
        search: this.searchPatient,
        searchByClinician: this.searchByClinician,
        sort: "",
        agencyId: this.currentUser.currentUserRole === RoleID.Admin ? this.selectedAgency : this.currentAgency.agency,
        getUserId: this.currentUser.id,
        searchByVisitDate: this.searchByVisitDate,
        searchByVisitDates: this.searchByVisitDates,
        visitTypes: this.selectedSubmissionTypesForAPI,
      }
      this.patientService.getSubmissionWithOutPatients(request).subscribe(res => {
        this.unmatchedVisits = [];
        if (res?.success) {
          this.unmatchedVisits = res?.content?.totalElements !== 0 ? res.content.data : '';
          this.totalUnMatchedVisits = res?.content?.totalElements;

        } else if (res) {
          this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', res?.errorMessage);
        }
        this.progressBarService.setProgressBarVisibility(false);
      })
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', error);
    }
  }

  onSubmissionStatusDropdownChange() {
    this.getSubmissionWithOutPatients();
  }

  showLocationDialog(visitDate: string, visitType:string){
    this.selectedVisitDates = visitDate;
    this.selectedVisitType = visitType;
    this.initializeOptions();
    this.locationPopupDisplay = true;
  }

  initializeOptions(){
      this.options  ={
        center : {lat : (this.patientSubmission.submissionData.latitude),lng:(this.patientSubmission.submissionData.longitude)},
        zoom : 12
      }
      this.overlays = [
        new google.maps.Marker({ position: { lat: +this.patientSubmission.submissionData.latitude, lng: +this.patientSubmission.submissionData.longitude }, title: "Selected Visit" })

      ];

  }

  getLabelByValue(value: string): string {
    const status = submissionStatus?.find((status) => status?.value === value);
    return status ? status.label : '';
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.pageNo = event.page;
      this.noOfRecordPerRow = event.rows;
      this.getSubmissionWithOutPatients();
    }
  }

  statusColor(status) {
    if(status?.includes('_'))
    {
      return status?.replace(/_/g, '');
    }
    else{
      return status?.replace(/-/g, '');
    }
  }

  getCurrentAgencyRole() {
    this.subscriptions.push(this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
      this.currentAgency = currentRole;
      if (this.currentAgency.role == RoleID.Admin) {
        this.currentAgency.agency = "";
      }

      this.getSubmissionWithOutPatients();
    }));
  }

  adminRoles() {
    this.allowAdminRoles = adminRoles.includes(this.currentUser?.currentUserRole);
  }

  onSlideBarClose() {
    this.display = false;
  }

  unmatchedVisitDetails(
    unmatchedVisit?: any,
    dob?: string,
    mrn?: string,
    firstName?: string,
    lastName?: string,
    totalErrorAndWarnings?: number,
    picture?: string,
    rowIndex?: number
  ) {
    this.display = true;
    this.patientSubmission = unmatchedVisit;
    this.patientSubmission.questionnaire = this.utilService.extractQuestionAndAnswer(unmatchedVisit?.questionnaire);
    this.patientSubmission['mrn'] = mrn ? mrn : unmatchedVisit?.submissionData.mrn;
    this.patientSubmission['firstName'] = unmatchedVisit?.firstName ? unmatchedVisit?.firstName : '';
    this.patientSubmission['lastName'] = unmatchedVisit?.lastName ? unmatchedVisit?.lastName : '';
    this.patientSubmission['unmatchedVisits'] = true;

    const patientFirstName = '';
    const patientLastName = '';
    this.patientSubmission['patientName'] = patientFirstName + ' ' + patientLastName;
    //this.patientSubmission['dob'] = dob ? dob : unmatchedVisit?.patient?.dob;
    //this.patientSubmission['totalErrorAndWarnings'] = this.patientSubmission?.errorCount + this.patientSubmission.warningCount;
    //this.patientSubmission['picture'] = picture;

    this.patientSubmission['patientId'] = unmatchedVisit?.submissionData?._id;
    this.patientSubmission['questionnaire'] = this.getVisitsQuestionnaire(unmatchedVisit);
    //this.patientSubmission['patientId'] = patientSubmissionDetails?.submissionData?._id;
    this.patientSubmissionRowSelected = rowIndex;
  }

  getVisitsQuestionnaire(unmatchedVisit) {
    const questionnaire = [];
    for(const key in  unmatchedVisit?.submissionData.questionnaire) {
      questionnaire.push({
        question: key,
        answer: unmatchedVisit?.submissionData.questionnaire[key]
      });
    }
    return questionnaire;
  }

  openeSOCUploadPopup() {
    this.eSOCUploadDisplay = true;
    this.display = false;
  }

  resetPaginator() {
    this.paginator.changePageToFirst(event);
  }

  routeTo(e) {
    this.router.navigate(['/' + e]);
  }
  sortSelectedVisits() {
    this.submissionTypes = this.utilService.sortSelectedVisits(this.submissionTypes, this.selectedSubmissionTypes)
  }
}
