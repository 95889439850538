import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import { AgenciesComponent } from './agencies/agencies.component';
import {DashboardRoutingModule} from '../dashboard/dashboard-routing.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {SidebarModule} from "primeng/sidebar";
import {TableModule} from "primeng/table";
import {MultiSelectModule} from "primeng/multiselect";
import {DropdownModule} from "primeng/dropdown";
import {SliderModule} from "primeng/slider";
import {FileUploadModule} from "primeng/fileupload";
import {CalendarModule} from "primeng/calendar";
import {LoaderWrapperModule} from "../loader-wrapper/loader-wrapper.module";
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {SelectButtonModule} from "primeng/selectbutton";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from '@angular/material/icon';
import {InputMaskModule} from "primeng/inputmask";
import {EsocComponent} from './e-soc/esoc.component';
import {TooltipModule} from "primeng/tooltip";

import {SharedModule} from "src/app/shared/shared.module";
import {PaginatorModule} from "primeng/paginator";
import {InvoicesComponent} from "./invoices/invoices.component";
import {OthersComponent} from "./others/others.component";
import {OthersUploadComponent} from "./others-upload/othersUpload.component";


@NgModule({
  declarations: [
    EsocComponent,
    InvoicesComponent,
    OthersComponent,
    OthersUploadComponent,
  ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        SidebarModule,
        TableModule,
        MultiSelectModule,
        DropdownModule,
        SliderModule,
        FileUploadModule,
        CalendarModule,
        CheckboxModule,
        ConfirmDialogModule,
        LoaderWrapperModule,
        SelectButtonModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatIconModule,
        InputMaskModule,
        TooltipModule,
        SharedModule,
        PaginatorModule,
    ],exports: [
    EsocComponent,
    InvoicesComponent,
    OthersComponent,
    OthersUploadComponent,
  ],
  providers: [ConfirmationService]
})
export class EsocModule { }
