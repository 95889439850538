import { Component, OnInit } from '@angular/core';
import {LOGIN_PATH, LOGO_TEXT} from "../common/Constants";
import { Auth } from 'aws-amplify';
import {Router} from "@angular/router";
import {MessageHandlerService} from "../services/message-handler.service";
import {CognitoUserService} from "../services/cognito-user.service";
import {AuthService} from "../security/auth.service";

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  logoText = LOGO_TEXT;
  mfaCode: string;
  loading = false;

  constructor(private router : Router , private messageHandlerService : MessageHandlerService,
              private cognitoUserService:CognitoUserService,private authService : AuthService,) { }

  ngOnInit(): void {}

  navigateToPreviousPage()
  {
     this.router.navigate([LOGIN_PATH]);
  }

  verifyMFACode() {
    this.loading = true
      Auth.confirmSignIn(this.cognitoUserService.cognitoUser, this.mfaCode, 'SMS_MFA')
      .then(res => {
        let accessToken = res.signInUserSession.accessToken.jwtToken;
        this.authService.currentUserSubject.next(res);
        this.authService.setToken(accessToken);
        this.router.navigate(['']);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.messageHandlerService.generateToastMessage('error','',error?.message)
      });
  }
}
