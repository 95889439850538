import {PatientSubmissions} from 'src/app/models/patientSubmissions';

export interface Patient {
  id?: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  location: string;
  team: string;
  picture: string;
  branch: string;
  icdCodes: Object;
  payorSource: string[];
  referencingPhysician: string;
  admissionCoordinator: string;
  CEFSClaimBenefitPeriodDate: string[];
  CEFSClaimOrder: string[];
  pdf: string;
  contactInfo: object;
  verificationCode: string;
  mrn: string;
  agencies: {};
  userId: string;
  submissions?: PatientSubmissions[];
  totalErrors?: number;
  totalWarnings?: number;
  showSubmissionsDetails?: boolean;
  showEpisodeDetails?: boolean;
  CaseManagerFirstName?: string;
  CaseManagerLastName?: string;
}

export interface PatientInvoice {
  _id?: string;
  patientId: string;
  iDoc: string;
  deliveryStatus: string;
  activityDate: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
}

export const refreshTime = [
  { label: 'Refresh every 30 sec', value: '30' },
  { label: 'Refresh every 1 min', value: '60' },
  { label: 'Refresh every 5 min', value: '300' }
];

export const patientSubmissionDropdown = [
  { label: 'Episodes', value: 'episodes' },
  { label: 'Patients', value: 'p-visits' },
  { label: 'Visits', value: 'visits' },
  { label: 'Unmatched Visits', value: 'unmatched-visits' },
];

export const viewByVisitDropdown = [
  { label: 'Visits', value: 'visits' },
  { label: 'Unmatched Visits', value: 'unmatched-visits' },
];



export const submissionStatus = [
  { label: 'All', value: '' },
  { label: 'Started', value: 'in-progress' },
  { label: 'In Progress', value: 'incomplete'},
  { label: 'Completed', value: 'completed' },
  { label: 'Reviewed', value: 'reviewed' },
  { label: 'Recheck', value: 'recheck' },
  { label: 'Started', value: 'waiting_for_review' },
  { label: 'Late', value: 'late' },
];

export const submissionTypes = [
  { label: 'PT00', value: 'PT00' },
  { label: 'PT01', value: 'PT01' },
  { label: 'PT02', value: 'PT02' },
  { label: 'PT05', value: 'PT05' },
  { label: 'PT06', value: 'PT06' },
  { label: 'PT11', value: 'PT11' },
  { label: 'PT15', value: 'PT15' },
  { label: 'PT17', value: 'PT17' },
  { label: 'PT18', value: 'PT18' },
  { label: 'PT19', value: 'PT19' },
  { label: 'PT33', value: 'PT33' },
  { label: 'PT66', value: 'PT66' },
  { label: 'PT00X', value: 'PT00X' },
  { label: 'PT00Z', value: 'PT00Z' },
  { label: 'PA11', value: 'PA11' },
  { label: 'PT18X', value: 'PT18X' },
  { label: 'PT-NOMNC', value: 'PT-NOMNC' },
  { label: 'PA11RS', value: 'PA11RS' },
  { label: 'PTIHR11', value: 'PTIHR11' },
  { label: 'PA-NOMNC', value: 'PA-NOMNC' },
  { label: 'IVR11', value: 'IVR11' },
  { label: 'OT00', value: 'OT00' },
  { label: 'OT01', value: 'OT01' },
  { label: 'OT02', value: 'OT02' },
  { label: 'OT06', value: 'OT06' },
  { label: 'OT11', value: 'OT11' },
  { label: 'OT18', value: 'OT18' },
  { label: 'OT19', value: 'OT19' },
  { label: 'OT33', value: 'OT33' },
  { label: 'RN00', value: 'RN00' },
  { label: 'RN01', value: 'RN01' },
  { label: 'RN02', value: 'RN02' },
  { label: 'RN10', value: 'RN10' },
  { label: 'RN11', value: 'RN11' },
  { label: 'RN15', value: 'RN15' },
  { label: 'RN18', value: 'RN18' },
  { label: 'RN19', value: 'RN19' },
  { label: 'RN90', value: 'RN90' },
  { label: 'RN00X', value: 'RN00X' },
  { label: 'RN02X', value: 'RN02X' },
  { label: 'SLP00', value: 'SLP00' },
  { label: 'SLP01', value: 'SLP01' },
  { label: 'SLP02', value: 'SLP02' },
  { label: 'SLP06', value: 'SLP06' },
  { label: 'SLP11', value: 'SLP11' },
  { label: 'SLP18', value: 'SLP18' },
  { label: 'SLP19', value: 'SLP19' },
  { label: 'SLP33', value: 'SLP33' },
  { label: 'SLPNOMNC', value: 'SLPNOMNC' },
  { label: 'SN11N', value: 'SN11N' },
  { label: 'SN-NOMNC', value: 'SN-NOMNC' },
  { label: 'OT-NOMNC', value: 'OT-NOMNC' },
];

export const visitType = [
  { label: 'Follow Up', value: 'follow_up' },
  { label: 'Startup Care', value: 'startup_care' },
];

export enum SubmissionStatusColor {
  all = '',
  inprogress = '#6ACE6A',
  recheck = '#C66A9E',
  reviewed = '#1E38A9',
  completed = '#EF896A',
  waitingforreview = '#6ACE6A',
  incomplete = '#C66A9E'
}
export enum visitDetailColors {
  'Error' = '#C11574',
  'Warning' = '#1E38A9',
  'Ok' = '#6ACE6A',
  'All' = '#F9A51D',
  'Started' = '#6ACE6A',
  'Completed' = '#EF896A',
  'Recheck' = '#C66A9E',
  'Reviewed' = '#1E38A9',
  'In Progress' = '#0A6ED9',
  'Submitted' = '#EF896A',
}

export interface ESockSubmission{
  eSockFile: string;
  email: { email: string, preferred: boolean};
  phoneNumber: { countryCode: string, number: string, preferred: boolean};
  dob?: any;
  isChecked?: boolean;
}

export const PatientVisitRows = [
  {value: 'Q No', label: 'Item'},
  {value: 'Description', label: 'Description'},
  {value: 'Answer', label: 'Answer'},
  {value: 'Ans Description', label: 'Ans Description'},
  {value: 'Alert Type', label: 'Alert Type'},
  {value: 'Message', label: 'Message'},
  {value: 'Status', label: 'Status'},
  {value: 'Action', label: 'Action'},
];

export const defaultSelectedPatientVisitRows = [
  'Q No',
  'Answer',
  'Alert Type',
  'Message',
  'Status',
  'Action',
];
