import { Injectable } from '@angular/core';
import {ServiceClientService} from "src/app/services/service-client.service";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {Company, Response} from "src/app/models/company";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    constructor(private serviceClientService: ServiceClientService) { }

    getAllCompanies(request: any): Observable<Response<Company[]>> {
        return this.serviceClientService.post(environment.endPoints.getCompanies, request);
    }

    uploadFile(file): Observable<any> {
        return this.serviceClientService.postImage(environment.endPoints.uploadFile, file);
    }

    deleteFile(file): Observable<any> {
        return this.serviceClientService.post(environment.endPoints.deleteFile, file);
    }

    findCompanyByEmail(params?: HttpParams): Observable<Response<any>> {
        return this.serviceClientService.get(`${environment.endPoints.findCompanyByEmailOrId}/email`, params);
    }

    createCompanyy(request: Company): Observable<any> {
        return this.serviceClientService.post(environment.endPoints.createCompany, request);
    }

    updateCompany(request: Company): Observable<any> {
        return this.serviceClientService.put(environment.endPoints.updateCompany, request);
    }

    deleteCompany(id: string): Observable<any> {
        return this.serviceClientService.delete(`${environment.endPoints.updateCompany}/${id}`);
    }

    getCompanyReportsExport(payload): Observable<any>{
      return this.serviceClientService.post(environment.endPoints.getCompanyErrorsAndWarningsExportData, payload)
    }

    getCompanyUtilizationReportData(payload): Observable<any>{
      return this.serviceClientService.post(environment.endPoints.getCompanyUtilizationExportData, payload)
    }

    getIohCompanyUsersReportToExport(payload): Observable<any>{
      return this.serviceClientService.post(environment.endPoints.getIohCompanyUsersExportData, payload)
    }
}
