import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { COMPANY_ADMIN, DEFAULT_USER_IMAGE, IOH_ADMIN, LOGIN_PATH, RoleID, SUPER_ADMIN } from "../../common/Constants";
import { AuthService } from "../../security/auth.service";
import { Router } from "@angular/router";
import { UserDataManager } from "src/app/user/UserDataManager";
import { User } from "../../models/user";
import { Roles, userRoles } from "../../models/agency";
import { Observable, Subject, Subscription, takeUntil, throwError } from "rxjs";
import { SidenavService } from "./sidenav.service";
import { SidenavItem } from "./sidenav-item.interface";
import { SidenavState } from "./sidenav-state.enum";
import { map } from "rxjs/operators";
import { componentDestroyed } from "./component-destroyed";
import { ProgressBarService } from "../../services/progress-bar.service";
import { Auth } from "aws-amplify";
import { NonNullAssert } from "@angular/compiler";
import { AgencyService } from "src/app/services/agency/agency.service";
import { MessageHandlerService } from "src/app/services/message-handler.service";
import { filter } from "lodash-es";
import { Agencies } from "../../models/episode";
import { UtilService } from "../../services/util/util.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  items$: SidenavItem[] = [];
  sidenavState$: Observable<SidenavState>;
  sidenavState: string;
  roles: userRoles[];
  firstName: string;
  lastName: string;
  profilePic: string;
  userRole: string;
  currentUser;
  superAdmin = RoleID.Admin;
  userSubscription: Subscription;
  subscriptions: Subscription[] = [];
  defaultUserPicture = DEFAULT_USER_IMAGE;
  isEDocPurchased = false;
  private isCollapsedState: boolean;
  private isCollapsed: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private userDataManager: UserDataManager,
    private sidenavService: SidenavService,
    private readonly progressBarService: ProgressBarService,
    private cd: ChangeDetectorRef,
    private agencyService: AgencyService,
    private messageHandlerService: MessageHandlerService,
    private utilService: UtilService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.userSubscription = this.userDataManager.user$.subscribe((user: User) => {
        if (user.is_super_admin) {
          this.roles = [{ label: SUPER_ADMIN, value: RoleID.Admin }];
        } else if (user.is_IOH_admin) {
          this.roles = [{ label: SUPER_ADMIN, value: RoleID.iohAdmin }];
        } else if (user.is_company_admin) {
          this.roles = [{ label: SUPER_ADMIN, value: RoleID.companyAdmin }];
        } else {
          this.roles = Roles;
        }
        // this.roles = !user.is_super_admin ? !user.is_IOH_admin ? !user.is_company_admin ? [{label: COMPANY_ADMIN, value: RoleID.companyAdmin}] : Roles : [{label: IOH_ADMIN, value: RoleID.iohAdmin}] : [{label: SUPER_ADMIN, value: RoleID.Admin}];
        this.firstName = user?.firstName;
        this.lastName = user?.lastName;
        this.profilePic = user?.picture;

        if (user.is_super_admin) {
          this.userRole = this.roles.find((role) => role.value === RoleID.Admin).label;
        } else if (user.is_IOH_admin) {
          this.userRole = this.roles.find((role) => role.value === RoleID.iohAdmin).label;
        } else if (user.is_company_admin) {
          this.userRole = this.roles.find((role) => role.value === RoleID.companyAdmin).label;
        } else {
          this.userRole = this.roles.find((role) => role.value === user.defaultRole).label;
        }
        // this.userRole = !user.is_super_admin ? !user.is_IOH_admin ? !user.is_company_admin ? this.roles.find(role => role.value === RoleID.companyAdmin).label : this.roles.find(role => role.value === user.defaultRole).label : this.roles.find(role => role.value === RoleID.iohAdmin).label : this.roles.find(role => role.value === RoleID.Admin).label;

        this.onSelectedRoleChange();
      });

      this.sidenavService.items$
        .pipe(map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, "position")))
        .subscribe((res) => (this.items$ = res));

      this.sidenavState$ = this.sidenavService.sidenavState$;
      this.sidenavService.sidenavState$.subscribe((sidenavState) => (this.sidenavState = sidenavState));
    }
  }

  onSelectedRoleChange() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((res) => {
        if (res) {
          this.userRole = this.roles.find((role) => role?.value === res?.role)?.label;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
  clearGlobalStorage() {
    localStorage.removeItem("globalDates");
    localStorage.removeItem("globalPageLimit");
  }
  async logOut() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      Auth.signOut().then(() => {
        this.authService.clear();
        this.authService.authenticationSubject.next(false);
        this.utilService.clearStore();
        this.clearGlobalStorage();
        //this.userDataManager.activeAgencyRole$.next({agency: '', role: ''});
        this.subscriptions.push(
          this.userService.logOutWithMicrosoft().subscribe((data: any) => {
            window.location.href = data.url;
          }),
        );
        this.progressBarService.setProgressBarVisibility(false);
        //this.router.navigate([LOGIN_PATH]);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
