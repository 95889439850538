import {createReducer, on} from "@ngrx/store";
import * as CompanyActions from "./company.actions";
import {CompanyState} from "./agency.reducer";
import {removeAllAgencies} from "./agency.actions";
export const initialCompanyState: CompanyState = {
  companies: [],
};
export const companyReducer = createReducer(
  initialCompanyState,
  on(CompanyActions.addCompany, (state, { companies }) => {
    removeAllAgencies();
    return {companies: companies}
  }),
  on(CompanyActions.removeCompany, (state, { company }) => ({
    ...state,
    companies: state.companies.filter(c => c !== company),
  })),
  on(CompanyActions.removeAllCompanies, (state) => {
    return {companies: []}
  }),
);
