import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import {
    LOGO_TEXT,
    FORGOT_PASSWORD,
    PASSWORD_RESET_DONE,
    PASSWORD_STRENGTH_STRONG,
    PASSWORD_STRENGTH_STRONG_CLASS,
    PASSWORD_STRENGTH_MEDIUM,
    PASSWORD_STRENGTH_MEDIUM_CLASS,
    PASSWORD_STRENGTH_WEAK,
    PASSWORD_STRENGTH_WEAK_CLASS,
    INVALID_PASSWORD,
    INVALID_PASSWORD_CLASS,
    RESET_PASSWORD_FIELDS_MISSING,
    RESET_PASSWORDS_NOT_MATCHING,
    PASSWORD_STRENGTH_VALIDATION_MESSAGE,
    PASSWORDS_MUST_MATCH
} from 'src/app/common/Constants';
import { MessageHandlerService } from 'src/app/services/message-handler.service';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss'],
})
export class CreateNewPasswordComponent implements OnInit {
  logoText = LOGO_TEXT;
  passwordStrength: string; // Holds the password strength level
  passwordStrengthClass: string;
  verificationCode: string;
  newPassword: string;
  repeatPassword: string;
  email: string;
  passwordDirty = false;
  confirmPasswordDirty = false;
  PASSWORD_STRENGTH_VALIDATION_MESSAGE = PASSWORD_STRENGTH_VALIDATION_MESSAGE;
  PASSWORDS_MUST_MATCH = PASSWORDS_MUST_MATCH;
  constructor(private router : Router , private messageHandlerService : MessageHandlerService) {}

  ngOnInit(): void {

  }

  checkPasswordStrength(): void {
    const password = this.newPassword;
    const weakRegex = new RegExp("^.{1,}$");
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
    const mediumRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*\\d)[A-Za-z\\d]{8,}$");
    this.passwordDirty = true
    if (strongRegex.test(password)) {
      this.passwordStrength = PASSWORD_STRENGTH_STRONG;
      this.passwordStrengthClass = PASSWORD_STRENGTH_STRONG_CLASS;
    } else if (mediumRegex.test(password)) {
      this.passwordStrength = PASSWORD_STRENGTH_MEDIUM;
      this.passwordStrengthClass = PASSWORD_STRENGTH_MEDIUM_CLASS;
    } else if (weakRegex.test(password)) {
      this.passwordStrength = PASSWORD_STRENGTH_WEAK;
      this.passwordStrengthClass = PASSWORD_STRENGTH_WEAK_CLASS;
    } else {
      // Handle the case when the password doesn't match any of the patterns
      this.passwordStrength = INVALID_PASSWORD;
      this.passwordStrengthClass = INVALID_PASSWORD_CLASS;
    }

  }

  submitConfirmPasswordForm(form: any) {
    if(!(this.email && this.verificationCode && this.newPassword && this.repeatPassword)) {
      form.control.controls.email.touched = form.control.controls.email.invalid ?
        true : form.control.controls.email.touched;
      form.control.controls.verificationCode.touched = form.control.controls.verificationCode.invalid ?
        true : form.control.controls.verificationCode.touched;
      form.control.controls.newpassword.touched = form.control.controls.newpassword.invalid ?
        true : form.control.controls.newpassword.touched;
      form.control.controls.repeatpassword.touched = form.control.controls.repeatpassword.invalid ?
        true : form.control.controls.repeatpassword.touched;
      this.messageHandlerService.generateToastMessage('warn','', RESET_PASSWORD_FIELDS_MISSING);

      return;
    }

    if(this.newPassword !== this.repeatPassword) {
      this.messageHandlerService.generateToastMessage('error','', RESET_PASSWORDS_NOT_MATCHING);

      return;
    }

    Auth.forgotPasswordSubmit(
      this.email,
      this.verificationCode,
      this.newPassword
    ).then(() => {
       this.router.navigate([PASSWORD_RESET_DONE])
    }).catch((error)=>{
      this.messageHandlerService.generateToastMessage('error','',error?.message)
    });
  }
  navigateToPreviousPage()
  {
     this.router.navigate([FORGOT_PASSWORD])
  }
}
