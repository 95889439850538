import {Injectable} from '@angular/core';
import {catchError, map, Observable} from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {HttpErrorHandlerService} from "./http-error-handler.service";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class ServiceClientService {
  private defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Platform': 'web'
  });

  constructor(private http: HttpClient, private httpErrorHandlerService: HttpErrorHandlerService,
              private localStorageService: LocalStorageService) { }

  get<TResponse>(endPoint: string, params?: HttpParams): Observable<TResponse> {
    const headers = this.createHeader();

    return this.http.get<TResponse>(endPoint, { headers: headers, params: params }).pipe(
      map((resp: any) => resp),
      catchError(async (err) => this.httpErrorHandlerService.handleError(err)));
  }

  post<TRequest, TResponse>(endPoint: string, request: TRequest, headerParamName?, headerValue?): Observable<TResponse> {
    const headers = headerValue ? this.defaultHeaders.set(headerParamName, headerValue) : this.defaultHeaders;
    // @ts-ignore
    return this.http.post<TRequest, TResponse>(endPoint, request, { headers: headers }).pipe(
      map((resp) => resp),
      catchError(async (err) => {
        this.httpErrorHandlerService.handleError(err)
      }));
  }

  put<TRequest, TResponse>(endPoint: string, request: TRequest): Observable<TResponse> {
    const headers = this.createHeader();
    // @ts-ignore
    return this.http.put<TRequest, TResponse>(endPoint, request, { headers: headers }).pipe(
      map((resp) => resp),
      catchError(async (err) => this.httpErrorHandlerService.handleError(err)));
  }

  postImage<TRequest, TResponse>(endPoint: string, request: TRequest): Observable<TResponse> {
    // @ts-ignore
    return this.http.post<TRequest, TResponse>(endPoint, request).pipe(
      map((resp) => resp),
      catchError(async (err) => this.httpErrorHandlerService.handleError(err)));
  }

  delete<TResponse>(endPoint: string): Observable<TResponse> {
    const headers = this.createHeader();
    return this.http.delete<TResponse>(endPoint, { headers: headers }).pipe(
      map((resp: any) => resp),
      catchError(async (err) => this.httpErrorHandlerService.handleError(err)));
  }

  createHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Platform': 'web',
    });
  }
}
