import {ANSWER_MAPPING, RoleID} from "../../common/Constants";

export function swapLatitudeAndLongitude(latitude:number,longitude:number) {
  if(latitude < -90 || latitude > 90) {
    let temp = latitude;
    latitude = longitude;
    longitude = temp;
  }

  return {latitude,longitude};
}
export function isMultiAgenciesUserEnabled(userAgenciesRoles) {
  let roles = [];
  userAgenciesRoles.forEach((agency) => {
    roles.push(...agency.roles);
  });
  let unique = roles.filter((role, index,array) => array.indexOf(role) === index);
  const allowedRoles = [RoleID.AgencyAdmin.toString(),RoleID.OfficeWorker.toString(), RoleID.CaseManager.toString()];
  return unique.some(r=> allowedRoles.includes(r));
}

export function getAnswerLabel(ans: string) {
    if (ANSWER_MAPPING[ans]) {
      return ANSWER_MAPPING[ans];
    } else {
      return ans;
    }
  }


