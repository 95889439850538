import { Component, OnInit } from '@angular/core';
import {PatientInvoice} from "../../models/patient";
import {dateFormat} from "../../common/Constants";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  esocPatientsInvoiceData: PatientInvoice[] = [];
  dateFormat = dateFormat;

  constructor() { }

  ngOnInit(): void {
  }

}
