import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { SsoAuthorizeComponent } from './sso-authorize.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';


@NgModule({
  declarations: [
    SsoAuthorizeComponent,
  ],
    imports: [
        CommonModule,
        ProgressSpinnerModule
    ],exports: [
      SsoAuthorizeComponent
  ]
})
export class SsoAuthorizeModule { }
