export interface PatientSubmissions {
  id: string;
  mrn: string;
  patient: string;
  zgroup: string[];
  visitId: string[];
  quizData: QuizDataDto[];
  questionnaire: any;
  issues: PsIssueDto[];
  visitType: string;
  visitDate: string;
  submissionDate: string;
  status?: string;
  updatedAt: string;
  updatedBy: string;
  eSoc?: eSoc;
  latitude : number;
  langitude : number;
  contactInfo: any,
  nonOasisQuestionnaore : NonOasisQuestionnaire[],
  icdCodes?: any
}

export interface QuizDataDto {
  question: string;
  answer: string;
}

export interface PsIssueDto {
  message?: string;
  answer: string;
  timeStamp: string;
  byPass: boolean;
  questionNo: string;
  type: string;
  status :string;
  reason : string
}
export interface NonOasisQuestionnaire {
  answer : string;
  question : string;
  type : string
}
export interface SubmissionList{
  canHighlight?: boolean;
  _id: string;
  mrn: string;
  patient: PatientData;
  submissionData: SubmissionData;
  errorCount	:	number;
  warningCount	: number;
}

export interface PatientData{
  firstName	:	string;
  middleInitial	:	string;
  lastName	:	string;
  picture	:	string;
  dob?	:	string;

}
export interface SubmissionData {
  _id	:	string;
  mrn	:	string;
  zgroup	:	string;
  visitId	:	string;
  quizData: QuizDataDto;
  questionnaire: any;
  issues: PsIssueDto;
  visitType	: string
  updatedAt	:	string;
  updatedBy	:	string;
  status: string;
  submissionDate: Date
  visitDate?: string;
  crossRulesIssuesCount?: number;
}

export interface eSoc {
  file: { fileName: string, fileLocation: string }[];
  submittedOn: Date;
  status: eSockStatus;
  submittedBy: string;
  consent: boolean;
  email: { email: string, preferred: boolean, sent: boolean};
  phone: { countryCode: string, number: string, preferred: boolean, sent: boolean};
}

export enum eSockStatus {
  APPROVED = "APPROVED",
  UNAPPROVED = "UNAPPROVED",
}
