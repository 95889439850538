import { Component, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserDataManager } from '../user/UserDataManager';
import { User } from '../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ProgressBarService } from '../services/progress-bar.service';
import { EsocService } from '../services/e-soc/esoc.service';
import { MessageHandlerService } from '../services/message-handler.service';
import { EsocComponent } from '../e-soc/e-soc/esoc.component';
import { DATE_PICKER_FORMAT, dateFormat, DOB_DATE_FORMAT, DOB_NOT_MATCH, ePH_LINK_SEND } from '../common/Constants';
import { reformatDate } from '../helper/functions';
import { DatePipe } from '@angular/common';
import { UtilService } from '../services/util/util.service';

@Component({
  selector: 'app-esoc-verification',
  templateUrl: './esoc-verification.component.html',
  styleUrls: ['./esoc-verification.component.scss'],
})
export class EsocVerificationComponent implements OnInit {
  subscriptions: Subscription[] = [];
  currentUser: User;
  submissionId: string;
  agencyId: string;
  expiryTime: string;
  fileType: string;
  fileName: string;
  encryptedString: string;
  patientDob: string;
  dob: any;
  linkExpired: boolean = false;
  dateFormat = dateFormat;
  datepickerFormat = DATE_PICKER_FORMAT;
  dateRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  agencyInfo: any;
  agencyPicture: string = '';
  dobDateFormat = DOB_DATE_FORMAT;
  innerWidth = window.innerWidth;

  constructor(
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private esocService: EsocService,
    private esocComponent: EsocComponent,
    private messageHandlerService: MessageHandlerService,
    private datePipe: DatePipe,
    private utilService: UtilService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  submitForm() {
    this.esocService
      .checkFileValidity({ encryptedString: this.encryptedString, patientDob: this.formatDobDate(this.dob) })
      .subscribe(({ content }) => {
        this.linkExpired = content.data.expired;
        if (content.data.match) {
          this.fileName = content.data.fileName;
          this.esocComponent.downloadPdfFromS3(this.fileName);
          this.dob = '';
          this.updateEsocFileDownload();
        } else {
          this.messageHandlerService.generateToastMessage('error', '', DOB_NOT_MATCH);
          console.log("Entered DOB doesn't matched");
          this.dob = '';
        }
      });
  }

  changeTimestampToDate(dob) {
    const date = new Date(dob);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  ngOnInit(): void {
    this.getPathLink();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  formatDobDate(dob) {
    let formattedDate = this.utilService.toLocal(dob);
    return this.datePipe.transform(formattedDate, 'MM/dd/yyyy');
  }

  getPathLink() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get('code');
    this.encryptedString = queryParam.replace(/ /g, '+');
    this.esocService.checkEDocLinkValidity({ encryptedString: this.encryptedString }).subscribe(({ content }) => {
      this.linkExpired = content.data.expired;
      this.agencyId = content.data.agencyId;
      this.submissionId = content.data.submissionId;
      this.fileType = content.data.fileType;
      this.getAgencyInformation();
      this.updateEsocFileOpened();
    });
  }

  getAgencyInformation() {
    this.esocService.getAgencyInformation(this.agencyId).subscribe((res) => {
      if (res.success) {
        this.agencyPicture = res.data.picture;
      }
    });
  }

  updateEsocFileOpened() {
    this.esocService.updateEsocFileStatus({ id: this.submissionId, status: 'Opened', filename: this.fileName }).subscribe((res) => {});
  }

  updateEsocFileDownload() {
    this.subscriptions.push(
      this.esocService.updateEsocFileStatus({ id: this.submissionId, status: 'Downloaded', filename: this.fileName }).subscribe((res) => {}),
    );
  }

  resendLink() {
    try {
      const fileTypeAndSubmissionId = this.submissionId + '/' + this.fileType;
      this.progressBarService.setProgressBarVisibility(true);
      this.esocService.processEphResendRequest(fileTypeAndSubmissionId).subscribe((res) => {
        if (res?.success) {
          this.messageHandlerService.generateToastMessage('success', '', ePH_LINK_SEND);
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', error);
    }
  }

  getCurrentUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        this.currentUser = res;
      }),
    );
  }
}
