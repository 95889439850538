export interface Agency {
  id?: string;
  picture: string;
  name: string;
  phoneNumber: { countryCode: string; number: string };
  fax: { countryCode: string; number: string };
  email: string;
  status: string;
  agencyLevel: string;
  address: AddressDto;
  companyId?: string | any
  createdAt?: Date;
  createdBy?: string;
  totalAttempts?: number;
  linkExpireDuration?: number;
  appSettings?: AppSettings;
}

export interface AddressDto {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface AppSettings {
  showOK: string;
  showWarnError: string;
  highlightCircle: string;
  highlightSrect: string;
  highlightLrect: string;
  toastPopUp: string;
}

export interface AgencyExport{
  agencies: AgencyReportData[]
}
export interface AgencyReportData{
  agencyId: string;
  agencyName: string;
  clinicians: Clinicians[];
  totalErrors: number;
  totalErrorsForAgency: number;
  totalPatients: number;
  totalPatientsWithValidation: number;
  totalWarnings: number;
  totalWarningsForAgency: number;
  totalPatientsSeenInTimePeriod: number;
}

export interface Clinician{
  totalErrorsForClinician: number;
  totalPatientsSeenByClinician: number;
  totalWarningsForClinician: number;
  totalClinicianPatientsWithValidation: number;
  totalPatientsSeenInTimePeriodByClinician: number;
  userId: string;
  userName: string;
}

export interface Clinicians{
  clinicians: Clinician[];
  diagnosticGroupsData: any[];
  userIds: string[];
}

export const Roles = [
  // {value: 'SUPER_ADMIN', label: 'Super Admin',},
  {value: 'AGENCY_ADMINISTRATOR', label: 'Agency Administrator',},
  {value: 'CASE_MANAGER', label: 'Case Manager',},
  {value: 'OFFICE_WORKER', label: 'Office Worker',},
  {value: 'CLINICIAN', label: 'Clinician',}
];

export const Providers = [
  {value: 'RN', label: 'RN',},
  {value: 'PT', label: 'PT',},
  {value: 'OT', label: 'OT',},
  {value: 'SLP', label: 'SLP',},
  {value: 'PTA', label: 'PTA',},
  {value: 'OTA', label: 'OTA',},
];

export const ProvidersFilter = [
  {value: 'RN', label: 'RN',},
  {value: 'PT', label: 'PT',},
  {value: 'OT', label: 'OT',},
  {value: 'SLP', label: 'SLP',},
  {value: 'PTA', label: 'PTA',},
  {value: 'OTA', label: 'OTA',},
];

export const IOHRoles = [
  {value: 'AGENCY_ADMINISTRATOR', label: 'Agency Administrator',},
];

export enum RoleEnum {
  AGENCY_ADMINISTRATOR = 'Agency Administrator',
  OFFICE_WORKER = 'Office Worker',
  CASE_MANAGER = 'Case Manager',
  PATIENT_MANAGER = 'Patient Manager',
  CLINICIAN = 'Clinician',
  SUPER_ADMIN = 'Super Admin',
  COMPANY_ADMIN = 'Company Admin',
  IOH_ADMIN = 'IOH Admin',
  IOH_AGENCY_ADMINISTRATOR = 'IOH Agency Administrator',
}

export const statusValues = [
  {value: 'Active', label: 'Active',},
  {value: 'Inactive', label: 'Inactive',}
];

export enum status {
  Active = '#6ACE6A',
  Inactive = '#ff3c3c',
  active = '#6ACE6A',
  inactive = '#C66A9E',
}

export interface userRoles {
  value: string;
  label: string;
}

export const agencyStatusValues = [
  { label: 'All', value: '' },
  { value: 'Active', label: 'Active'},
  { value: 'Inactive', label: 'Inactive'}
];

export const agencyLevelValues = [
  { label: 'All', value: '' },
  { value: 'Gold', label: 'Gold'},
  { value: 'Silver', label: 'Silver'},
  { value: 'Bronze', label: 'Bronze'}
];

export enum AgencyFiltersOptions {
  STATUS =  'status',
  LEVEL =  'level'
}

export const IOHRoleFilterOptions = [
  {value: '', label: 'All',},
  {value: 'AGENCY_ADMINISTRATOR', label: 'Agency Administrator',},
];

export const NonIOHRoleFilterOptions = [
  {value: 'AGENCY_ADMINISTRATOR', label: 'Agency Administrator',},
  {value: 'CASE_MANAGER', label: 'Case Manager',},
  {value: 'OFFICE_WORKER', label: 'Office Worker',},
  {value: 'CLINICIAN', label: 'Clinician',}
];

export const DiagnosisGroupOptions = [
    {name: 'Other', value: 'Other',},
    {name: 'Joint Replacement - Knee or Hip', value: 'Joint Replacement - Knee or Hip',},
    {name: 'Cardiac', value: 'Cardiac',},
    {name: 'Respiratory', value: 'Respiratory',},
    {name: 'Neurological', value: 'Neurological',},
    {name: 'Wound Care', value: 'Wound Care',},
    {name: 'MSK', value: 'MSK',},
    {name: 'Surgical Aftercare', value: 'Surgical Aftercare',},
  ]

  export interface PortalFeatures {
    name: string,
    value: boolean
  }

export const defaultSelectedAgencyRows = [
  'Agency',
  'Email',
  'Phone',
  'Level',
  'Status'
]
export const AgencyListingRows = [
  {value: 'Agency', label: 'Agency'},
  {value: 'Email', label: 'Email'},
  {value: 'Phone', label: 'Phone'},
  {value: 'Level', label: 'Level'},
  {value: 'Status', label: 'Status'},
];
