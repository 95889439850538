import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import {LOGIN_PATH, HOME_PATH, DASHBOARD_PATH, USER_LISTING_PATH} from '../common/Constants';

@Injectable({
  providedIn: 'root'
})
export class RedirectIfLoginGuard 
{
  constructor(protected auth: AuthService, protected router: Router) {

  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {
    if(this.auth.authenticated)
    {
      this.router.navigate([USER_LISTING_PATH]);
      return false;
    }
    return true;
  }
}
