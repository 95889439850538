import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {UserDataManager} from "../user/UserDataManager";
import {Subscription} from "rxjs";
import {ProgressBarService} from "../services/progress-bar.service";
import {SupportService} from "../services/support.service";
import {MessageHandlerService} from "../services/message-handler.service";
import {FileUpload} from "primeng/fileupload";
import {AgencyService} from "../services/agency/agency.service";
import { User } from '../models/user';

@Component({
  selector: 'app-add-support-ticket',
  templateUrl: './add-support-ticket.component.html',
  styleUrls: ['./add-support-ticket.component.scss']
})
export class AddSupportTicketComponent implements OnInit ,OnDestroy{
  supportTicketForm : FormGroup
  supportTickets: any[] = [];
  subscription: Subscription;
  name: string;
  currentUser : User;
  currentAgencyRole : {agency : string, role : string}
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;

  constructor( private formBuilder : FormBuilder,private userDataManager: UserDataManager,
               private progressBarService: ProgressBarService,
               private supportService: SupportService,
               private agencyService: AgencyService,
               private messageHandlerService: MessageHandlerService) { }

  ngOnInit(): void {
    this.AddSupportTicketForm()
    this.subscription = this.userDataManager.user$.subscribe(user => {
      this.name = user.firstName.concat(" ",user.lastName);
      this.currentUser = user
    })
this.subscribeToActiveAgencyRole()
  }

  subscribeToActiveAgencyRole(){
    this.subscription   =  this.userDataManager.activeAgencyRole$.subscribe(res =>{
           this.currentAgencyRole = res
    })
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  AddSupportTicketForm(){
    this.supportTicketForm = this.formBuilder.group({
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      description: ['', Validators.required],
      file: ['']
    })
  }
  onSubmit(){
    try {
       if(this.supportTicketForm.invalid){
        return;
      }
      const subject = this.supportTicketForm.get('subject').value
      const email = this.supportTicketForm.get('email').value;
      const description = this.supportTicketForm.get('description').value;
      const file = this.supportTicketForm.get('file').value;
      const name = this.name;
      const supportTicket = {
        subject: subject,
        email: email,
        Description: description,
        fileUpload: file,
        name: name,
        userId : this.currentUser.id,
        agencyId : this.currentAgencyRole.agency
      };
      this.supportTickets.push(supportTicket)
      this.progressBarService.setProgressBarVisibility(true);
      this.supportService.addSupportTicket(supportTicket).subscribe(res => {
        if(res.success){
          this.messageHandlerService.generateToastMessage('success', '', 'Support ticket added successfully');
        }else{
          this.messageHandlerService.generateToastMessage('error', '', 'An error occurred while adding support ticket.');
        }
      this.progressBarService.setProgressBarVisibility(false);
      })
      this.supportTicketForm.reset();
    }catch (error){
      this.messageHandlerService.generateToastMessage('error', '', 'An error occurred while adding support ticket.');
    }
  }

  async onSelect(event) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (event.currentFiles && event.currentFiles.length > 0) {
      this.progressBarService.setProgressBarVisibility(true);
      await this.agencyService.uploadFile(formData).subscribe(res => {
        if (res?.message?.Location) {
          this.supportTicketForm.get('file').setValue(res.message.Location);
          this.fileUpload.clear();
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    }
  }

  getStringAfterLastSlash(url) {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

}
