import { Injectable } from '@angular/core';

import { ServiceClientService } from "./service-client.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VisitDetailsService {
  
    constructor(private serviceClientService: ServiceClientService) { }

    getSuggestionsFromFinApi(request: any){
        return this.serviceClientService.post(environment.endPoints.fin, request)
    }
}
