import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from './security/auth.service';
import { Router } from '@angular/router';
import {LocalStorageService} from "./services/local-storage.service";
import {Location} from "@angular/common";
import {PATH_BEFORE_REFRESH, RoleID, SUBMISSION_PATH} from "./common/Constants";
import {UserService} from "./services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  isAuthenticated: boolean;

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    if(this.location.path() != '/login')
      this.localStorageService.set(PATH_BEFORE_REFRESH, this.location.path())
  }

  constructor(private router : Router , private authService: AuthService, private localStorageService: LocalStorageService,
              private location: Location, private userService: UserService,) {
    this.isAuthenticated = false;
  }
   ngOnInit(): void {
    this.authService.isAuthenticated().then((success: boolean) => {
      this.isAuthenticated = success;
    }).catch((error) => {
      this.isAuthenticated = false;
    });
  }

}
