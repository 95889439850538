export interface Notifications {
  creator: string;
  receiver: string;
  message: string;
  createdAt: Date;
  read: boolean;
}

export enum notificationMessage {
  PLEASE_RECHECK_VISIT  = "Please recheck visit ",
  FOR = " for ",
  TO_RECHECK = "To Recheck"
  }

