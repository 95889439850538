import { Component, OnInit } from '@angular/core';
import {PatientInvoice} from "../../models/patient";
import {dateFormat} from "../../common/Constants";

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {
  esocPatientsOthersData: PatientInvoice[] = [];
  dateFormat = dateFormat;

  constructor() { }

  ngOnInit(): void {
  }

}
