import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {ServiceClientService} from "./service-client.service";
import {Observable} from "rxjs";
import {Response} from "../models/user";
import {Support} from "../models/support";

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private serviceClientService: ServiceClientService) { }

  getSupportData(request:any): Observable<Response<Support[]>>{
    return this.serviceClientService.post(environment.endPoints.support,request);
  }

  updateSupport(request:any): Observable<Response<any>>{
    return this.serviceClientService.post(environment.endPoints.updateSupport, request);
  }

  addSupportTicket(data): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.supportTicket, data);
  }
}
