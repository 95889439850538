import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserDataManager} from "../../user/UserDataManager";
import {RoleID, USER_LISTING_PATH} from "../../common/Constants";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-session-expired',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})
export class NotAllowedComponent implements OnInit, OnDestroy {
  subscription: Subscription;


  constructor(private userDataManager: UserDataManager, private router: Router) { }

  ngOnInit(): void {
      this.subscription = this.userDataManager.user$.subscribe(res => {
          if(res.currentUserRole == RoleID.AgencyAdmin || res.currentUserRole == RoleID.Admin) {
            this.router.navigate([USER_LISTING_PATH]);
          }
      })
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
