import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { User } from "src/app/models/user";
import { UserDataManager } from "../../user/UserDataManager";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { MessageHandlerService } from "src/app/services/message-handler.service";
import { NotificationService } from "src/app/services/notification.service";
import { PatientService } from "src/app/services/patient/patient.service";
import { notificationMessage, Notifications } from "src/app/models/notification";
import { defaultSelectedPatientVisitRows, PatientVisitRows, submissionStatus, visitDetailColors } from "src/app/models/patient";
import { Subscription } from "rxjs";
import { allowToUpdateVisitsRoles, dateAndTimeFormat, dateFormat, RoleID } from "../../common/Constants";
import { VisitDetailsService } from "src/app/services/visit-details.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { questionDescription } from "../../helper/question.description";
import { reformatNormalizedElementAnswersData, reformatVisitsAnswersData, reformatWithSpecialCharacters } from "../../helper/functions";

@Component({
  selector: "app-visit-details",
  templateUrl: "./visit-details.component.html",
  styleUrls: ["./visit-details.component.scss"],
})
export class VisitDetailsComponent implements OnInit, OnDestroy {
  @Input() patientSubmission: any;
  currentUser: User;
  isMasterCheckboxChecked: boolean = false;
  activeAgencyRole;
  rolesEnum = RoleID;
  selectedRows: any[] = [];
  selectedRowsForVisit: any[] = defaultSelectedPatientVisitRows;
  reProcessLoading = false;
  @Output() reloadPatientSubmission = new EventEmitter();
  @Output() closeSideBar = new EventEmitter();
  @Output() reprocessedObject = new EventEmitter();
  @Output() eSOCUploadDisplay = new EventEmitter();
  display;
  subscriptions: Subscription[] = [];
  IOHAgencyAdminAdministrator = RoleID.IOHAgencyAdmin;
  iohAdmin = RoleID.iohAdmin;
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  questions: any;
  suggestions: any;
  showDollarSign: boolean = false;
  showESOCUpload: boolean = false;
  visibleRows: any[] = PatientVisitRows;
  companyAdmin = RoleID.companyAdmin;
  visitDetailColors = visitDetailColors;
  crossRulesIssues = [];
  normalIssues = [];
  crossRulesIssuesCount = 0;
  allowToUpdateVisit: boolean = false;

  constructor(
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private userDataManager: UserDataManager,
    private notificationService: NotificationService,
    private visitDetailsService: VisitDetailsService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.getUserProfile();
    this.getActiveAgencyRole();
    this.getQuestionsObject();
    this.getSuggestionsFromFinApi();
    this.getSelectedRows();
    this.getCrossRulesIssues();
    this.nonProdRoles();
  }

  getCrossRulesIssues() {
    this.crossRulesIssues = [];
    this.normalIssues = [];
    this.crossRulesIssuesCount = 0;
    const issues = this.patientSubmission?.issues || [];

    issues.forEach((issue) => {
      if (issue?.crossRule && issue?.crossRuleType === "impact") {
        this.crossRulesIssues.push(issue);
      } else {
        this.normalIssues.push(issue);
      }
    });

    let handledIssues = [];

    for (let i = 0; i < this.crossRulesIssues.length; i++) {
      let normalizeList = [];
      let k = 0;
      for (let j = 0; j < this.crossRulesIssues[i].otherValues.length; j++) {
        let id1 = this.crossRulesIssues[i].question + this.crossRulesIssues[i].otherValues[j].title;

        let id2 = this.crossRulesIssues[i].otherValues[j].title + this.crossRulesIssues[i].question;

        if (handledIssues.includes(id1) || handledIssues.includes(id2)) {
          continue;
        } else {
          handledIssues.push(id1);
          handledIssues.push(id2);
          this.crossRulesIssuesCount++;

          let newEntry = {
            question: this.crossRulesIssues[i].question,
            questionDescription: this.crossRulesIssues[i]?.questionDescription,
            answers: this.crossRulesIssues[i].answers,
            status: k == 0 ? this.crossRulesIssues[i].status : undefined,
            answerDescription: this.crossRulesIssues[i]?.answerDescription,
            normalizedElement: {
              question: this.crossRulesIssues[i].otherValues[j].title,
              questionDescription: questionDescription[this.crossRulesIssues[i].otherValues[j].title],
              answers: this.crossRulesIssues[i].otherValues[j].answers,
              answerDescription: this.crossRulesIssues[i].otherValues[j].answerDescription,
            },
          };
          k++;
          reformatNormalizedElementAnswersData(newEntry?.normalizedElement);
          normalizeList.push(newEntry);
        }
      }
      this.crossRulesIssues[i].normalizedList = normalizeList;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  getUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        if (res) {
          if (res.currentUserRole == RoleID.Clinician) {
            this.showESOCUpload = true;
          }
          this.currentUser = res;
        }
      }),
    );
  }

  getActiveAgencyRole() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((res) => {
        if (res) {
          this.activeAgencyRole = res;
        }
      }),
    );
  }

  reProcess() {
    this.reProcessLoading = true;
    this.patientService.reProcessSubmission(this.patientSubmission?._id).subscribe((res) => {
      if (res.success && res.content) {
        this.patientSubmission.issues = res?.content?.submission?.issues;
        this.patientSubmission.totalErrorAndWarnings = res?.content?.errorCount + res?.content?.warningCount;
        this.reprocessedObject.emit(res?.content);
        this.patientSubmission.issues.forEach((issue) => {
          //return issue['message'] = issue?.message?.replace(/\n/g, '<br/>');
          return (issue["message"] = reformatWithSpecialCharacters(issue?.message));
        });

        this.patientSubmission["issues"].forEach((issue) => {
          return (issue["questionDescription"] = questionDescription[issue?.question]);
        });
        reformatVisitsAnswersData(this.patientSubmission["issues"]);
        this.getCrossRulesIssues();
      }
      this.reProcessLoading = false;
    });
  }

  updateStatus(action, issueIndex) {
    if (
      issueIndex == -1 ||
      (this.patientSubmission?.issues && this.patientSubmission?.issues.length && this.patientSubmission?.issues[issueIndex]?.status) ||
      (this.patientSubmission?.submissionData?.issues[issueIndex]?.status &&
        this.patientSubmission?.submissionData?.issues &&
        this.patientSubmission?.submissionData?.issues.length)
    )
      try {
        this.progressBarService.setProgressBarVisibility(true);
        if (action === "ACCEPT") {
          this.patientSubmission.issues
            ? (this.patientSubmission.issues[issueIndex].status = "reviewed")
            : (this.patientSubmission.submissionData.issues[issueIndex].status = "reviewed");
        } else if (action === "RECHECK") {
          this.patientSubmission.issues
            ? (this.patientSubmission.issues[issueIndex].status = "recheck")
            : (this.patientSubmission.submissionData.issues[issueIndex].status = "recheck");
        } else if (action === "ACCEPT ALL") {
          this.selectedRows.forEach((selectedRow) => {
            const selectedIndex = this.patientSubmission.issues.indexOf(selectedRow);
            if (selectedIndex != -1) {
              this.patientSubmission.issues
                ? (this.patientSubmission.issues[selectedIndex].status = "reviewed")
                : (this.patientSubmission.submissionData.issues.status = "reviewed");
            }
          });
        } else if (action === "RECHECK ALL") {
          this.selectedRows.forEach((selectedRow) => {
            const selectedIndex = this.patientSubmission.issues.indexOf(selectedRow);
            if (selectedIndex != -1) {
              this.patientSubmission.issues
                ? (this.patientSubmission.issues[selectedIndex].status = "recheck")
                : (this.patientSubmission.submissionData.issues.status = "recheck");
            }
          });
        }

        this.patientSubmission?.issues.forEach((issue) => {
          if (issue?.normalizedList) delete issue?.normalizedList;
        });

        this.patientService.updateStatus(this.patientSubmission).subscribe((res) => {
          if (res?.success) {
            this.messageHandlerService.generateToastMessage("success", "", res.message);
            this.reloadPatientSubmission.emit();
            //this.getSubmissionByPatients();
            if (issueIndex == -1 || this.patientSubmission.issues[issueIndex]?.status === "recheck") this.createNotification(issueIndex);

            this.getCrossRulesIssues();
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
        });
      } catch (error) {
        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
      }
  }
  createNotification(issueIndex: number) {
    let question = "";
    let type = "";

    if (issueIndex == -1) {
      this.selectedRows.forEach((selectedRow, index) => {
        if (this.selectedRows.length - 1 !== index && index < 2) question = question + selectedRow?.question + ", ";
        else if (index == 2) {
          question = question + selectedRow?.question;
        } else if (this.selectedRows.length - 1 == index && index <= 2) question = question + selectedRow?.question;
        else if (index == 3) question = question + "...";
      });

      if (this.selectedRows.length == 1) {
        type = ` (type: ${this.selectedRows[0]?.type})`;
      }
    } else {
      question = this.patientSubmission?.issues[issueIndex]?.questionNo
        ? this.patientSubmission?.issues[issueIndex]?.questionNo
        : this.patientSubmission?.issues[issueIndex]?.question;
      type = ` (type: ${this.patientSubmission?.issues[issueIndex]?.type})`;
    }
    const visitType = this.patientSubmission?.visitType ? this.patientSubmission?.visitType : this.patientSubmission?.submissionData?.visitType;
    let message = notificationMessage.PLEASE_RECHECK_VISIT + visitType + notificationMessage.FOR + question + type;

    const notification: Notifications = {
      creator: this.currentUser?._id,
      receiver: this.patientSubmission.userId,
      message: message,
      createdAt: new Date(),
      read: false,
    };
    this.notificationService.createNotification(notification).subscribe((res) => {
      if (!res.success) {
        this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
      }
    });
  }
  masterChangeCheckbox(event) {
    this.isMasterCheckboxChecked = event.checked;
  }
  getAlertType(input) {
    input = input.toLowerCase();
    if (["warning", "warn", "error"].includes(input) == true) {
      if (input == "error") {
        return "Error";
      }
      return "Warning";
    }
    return "Ok";
  }
  getLabelByValue(value: string): string {
    const status = submissionStatus?.find((status) => status?.value === value);
    return status ? status.label : "";
  }

  getQuestionsObject() {
    if (!this.patientSubmission?.submissionData?.questionnaire) {
      this.questions = this.formatQuestions();
    } else {
      this.questions = this.patientSubmission?.submissionData?.questionnaire;
    }
  }

  formatQuestions() {
    const outputObject = {};
    if (Array.isArray(this.patientSubmission.questionnaire)) {
      for (const item of this.patientSubmission.questionnaire) {
        const question = item.question;
        const answerString = item.answer.toString();
        const answerArray = answerString.split(",");

        outputObject[question] = answerArray.length === 1 ? [parseInt(answerArray[0])] : answerArray.map((val) => parseInt(val));
      }

      return outputObject;
    } else {
      return this.patientSubmission.queqstionnaire;
    }
  }

  getSuggestionsFromFinApi() {
    let requestPayload = {
      icd10Codes: this.patientSubmission?.icdCodes ? this.patientSubmission?.icdCodes : this.patientSubmission?.patient?.icdCodes,
      questionnaire: this.questions,
    };

    this.visitDetailsService.getSuggestionsFromFinApi(requestPayload).subscribe((res: any) => {
      this.suggestions = res?.suggestions;
      this.showDollarSign = true;
    });
  }

  isRowVisible(rowName: string) {
    if (this.selectedRowsForVisit && this.selectedRowsForVisit.length)
      return this.selectedRowsForVisit.find((selectedRow) => selectedRow === rowName);
  }

  getSelectedRows() {
    if (!this.localStorageService.get("visitSettings" + this.currentUser?.aws_cognito_id)) {
      this.selectedRowsForVisit = defaultSelectedPatientVisitRows;
      this.localStorageService.set(
        "visitSettings" + this.currentUser?.aws_cognito_id,
        JSON.stringify({ selectedPatientVisitRows: this.selectedRowsForVisit }),
      );
    } else {
      this.selectedRowsForVisit = JSON.parse(this.localStorageService.get("visitSettings" + this.currentUser?.aws_cognito_id))
        ?.selectedPatientVisitRows
        ? JSON.parse(this.localStorageService.get("visitSettings" + this.currentUser?.aws_cognito_id))?.selectedPatientVisitRows
        : defaultSelectedPatientVisitRows;
    }
  }

  onSelectedRowsChange() {
    let selectedVisits = { selectedPatientVisitRows: [] };

    if (this.localStorageService.get("visitSettings" + this.currentUser?.aws_cognito_id))
      selectedVisits = JSON.parse(this.localStorageService.get("visitSettings" + this.currentUser?.aws_cognito_id));

    selectedVisits.selectedPatientVisitRows = this.selectedRowsForVisit;
    this.localStorageService.set("visitSettings" + this.currentUser?.aws_cognito_id, JSON.stringify(selectedVisits));
  }

  nonProdRoles() {
    this.allowToUpdateVisit = allowToUpdateVisitsRoles.includes(this.currentUser?.currentUserRole);
  }
}
