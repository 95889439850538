import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';
import { LoginUser, User } from '../models/user';
import { CognitoUserService } from '../services/cognito-user.service';
import { LOGIN_PATH, REFRESH_TOKEN_INTERVAL, TOKEN_KEY, USER_PROFILE } from '../common/Constants';
import { environment } from '../../environments/environment';
import { MessageHandlerService } from '../services/message-handler.service';
import { Router } from '@angular/router';
import { UserDataManager } from '../user/UserDataManager';
import { LocalStorageService } from '../services/local-storage.service';
import { UtilService } from '../services/util/util.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public authenticationSubject: BehaviorSubject<any>;
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  userActive: boolean = false;
  loggedInUser: User;
  refreshTokenInterval: number = REFRESH_TOKEN_INTERVAL;
  lastActivityTimestamp: number;
  refreshTokenTimeout: any;
  subscribers: Subscription[] = [];

  constructor(
    private cognitoUserService: CognitoUserService,
    private messageHandlerService: MessageHandlerService,
    private router: Router,
    private localStorageService: LocalStorageService,
    public userDataManager: UserDataManager,
    private utilService: UtilService,
  ) {
    Amplify.configure({
      Auth: environment.cognito,
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
    this.cognitoUserService = new CognitoUserService();

    this.currentUserSubject = new BehaviorSubject<any>(<string>localStorage.getItem(TOKEN_KEY));
    this.currentUser = this.currentUserSubject.asObservable();
    this.lastActivityTimestamp = Date.now();
    this.initTokenRefresh();
    this.getCurrentUser();
  }

  get authenticated() {
    return this.getToken() != null;
  }

  initTokenRefresh(): void {
    document.addEventListener('mousemove', this.setUserActive);
    document.addEventListener('keydown', this.setUserActive);
    document.addEventListener('touchstart', this.setUserActive);
    document.addEventListener('keyup', this.setUserActive);
    this.startTokenRefreshInterval();
  }
  startTokenRefreshInterval(): void {
    clearInterval(this.refreshTokenTimeout);
    if (this.userActive) {
      this.refreshTokenTimeout = setInterval(() => {
        const timeSinceLastActivity = Date.now() - this.lastActivityTimestamp;
        if (timeSinceLastActivity <= this.refreshTokenInterval) {
          this.refreshToken();
        }

        this.startTokenRefreshInterval();
      }, this.refreshTokenInterval);
    }
  }

  ngOnDestroy(): void {
    this.clear();
    clearInterval(this.refreshTokenTimeout);
  }

  setUserActive = (): void => {
    this.userActive = true;
    this.lastActivityTimestamp = Date.now();
  };

  async refreshToken(): Promise<any> {
    try {
      const newTokan = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.setToken(newTokan.signInUserSession.accessToken.jwtToken);
      this.startTokenRefreshInterval();
      return await Promise.resolve();
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  signIn(user: LoginUser) {
    this.clear();
    return Auth.signIn(user.email, user.password)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        this.messageHandlerService.generateToastMessage('error', '', error?.message);
        throw error;
      });
  }

  clear() {
    if (this.subscribers.length > 0) {
      this.subscribers.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.flush();
    this.utilService.clearStore();
  }

  async signOut() {
    return await Auth.signOut().then(() => {
      this.clear();
      this.authenticationSubject.next(false);
      this.router.navigate([LOGIN_PATH]);
    });
  }

  updatePassword(newPassword: string, oldPassword: string, cognitoUser: any) {
    let picture = {};
    if (cognitoUser?.challengeParam?.requiredAttributes?.includes('picture')) {
      picture = { picture: 'src/assets/mockImg.jpg' };
    }
    const { email } = cognitoUser.challengeParam.userAttributes;
    return Auth.signIn(email, oldPassword)
      .then(() => {
        return Auth.completeNewPassword(cognitoUser, newPassword, picture);
      })
      .catch((error) => {
        if (error.code === 'NotAuthorizedException') {
          throw new Error('Invalid old password. Please enter the correct old password.');
        }
      });
  }

  isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {
          return !!user;
        })
        .catch(() => {
          return false;
        });
    }
  }

  async getCurrentUser() {
    this.subscribers.push(
      await this.userDataManager.user$.subscribe((user) => {
        if (user) {
          this.loggedInUser = user;
        }
      }),
    );
  }

  setToken(token: string) {
    if (token) localStorage.setItem(TOKEN_KEY, token);
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY) || null;
  }

  getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  flush() {
    let userData = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('userSettings')) {
        const value = localStorage.getItem(key);
        if (value) {
          // Parse the stored data if needed (localStorage stores data as strings)
          const parsedValue = JSON.parse(value);
          userData.push({ key, value: parsedValue });
        }
      }
    }

    let visitData = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('visitSettings')) {
        const value = localStorage.getItem(key);
        if (value) {
          // Parse the stored data if needed (localStorage stores data as strings)
          const parsedValue = JSON.parse(value);
          visitData.push({ key, value: parsedValue });
        }
      }
    }

    const userSettings = this.localStorageService.get(this.loggedInUser?.aws_cognito_id);
    const visitSettings = this.localStorageService.get('visitSettings');
    localStorage.clear();
    this.localStorageService.set(this.loggedInUser?.aws_cognito_id, userSettings);

    userData.forEach((item) => {
      const { key, value } = item;
      this.localStorageService.set(key, JSON.stringify(value));
    });

    visitData.forEach((item) => {
      const { key, value } = item;
      this.localStorageService.set(key, JSON.stringify(value));
    });
  }
}
