import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/security/auth.service';
import { UserService } from 'src/app/services/user.service';
import { jwtDecode } from 'jwt-decode';
import { MessageHandlerService } from 'src/app/services/message-handler.service';
import {NO_AUTHORIZE_USER} from 'src/app/common/Constants';

@Component({
  selector: 'app-sso-authorize',
  templateUrl: './sso-authorize.component.html',
  styleUrls: ['./sso-authorize.component.scss'],
})
export class SsoAuthorizeComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private messageHandlerService: MessageHandlerService,
  ) {}

  ngOnInit(): void {
    let access_token = '';
    let id_token = '';
    this.route.snapshot.fragment.split('&').forEach((e) => {
      const s = e.split('=');

      if (s.includes('access_token')) {
        access_token = s[1];
      }
      if (s.includes('id_token')) {
        id_token = s[1];
      }
    });

    const decodedToken: any = jwtDecode(id_token);

    this.userService.userActive({ email: decodedToken.email }).subscribe((res) => {
      if (!res?.success) this.messageHandlerService.generateToastMessage('error', '', NO_AUTHORIZE_USER);
      if (res.success) {
        this.authService.currentUserSubject.next(res.content);
      }
    });
    this.authService.setToken(access_token);
    this.authService.initTokenRefresh();
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {}
}
