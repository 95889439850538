import {Injectable} from "@angular/core";
import {Response, User} from "../models/user";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {UserService} from "../services/user.service";
import {environment} from "../../environments/environment";
import {ServiceClientService} from "../services/service-client.service";
import {RoleID} from "../common/Constants";
import {LocalStorageService} from "../services/local-storage.service";

@Injectable()
export class UserDataManager {
  user: User;
  user$ = new ReplaySubject<User>(1);
  globalRole$ = new BehaviorSubject('');
  activeAgencyRole$ = new BehaviorSubject({agency: '', role: ''});
  constructor(protected _getUser:UserService, private serviceClientService: ServiceClientService,
              private localStorageService: LocalStorageService) { }

  getUser(){
    this.getUserFromApi();
    return this.user$;

  }

   async getUserFromApi(): Promise<User>{
      return new Promise(async (resolve, reject) => {
      await this._getUser.getUserProfile().subscribe((response) => {
        this.user = response.content?.data;
        if ((this.localStorageService.get('currentAgency') || this.localStorageService.get('currentRole')) &&
          !response.content?.data?.is_super_admin) {
          let selectedRole;
          const selectedAgency = this.localStorageService.get('currentAgency');
          const currentAgency = response.content?.data?.agency_roles?.find(agency_role => agency_role?.agency?.agencyID == selectedAgency);
          const isValidRole = !!currentAgency?.roles?.find(role => role == this.localStorageService.get('currentRole'));

          if (isValidRole) {
            selectedRole = this.localStorageService.get('currentRole');
            this.user.currentUserRole = selectedRole;
          } else {
            if (response.content?.data?.is_super_admin) {
              this.user.currentUserRole = RoleID.Admin
            } else if (response.content?.data?.is_IOH_admin) {
              this.user.currentUserRole = RoleID.iohAdmin
            } else if (response.content?.data?.is_company_admin) {
              this.user.currentUserRole = RoleID.companyAdmin
            } else {
              this.user.currentUserRole = response?.content?.data?.defaultRole;
            }
            // this.user.currentUserRole = !response.content?.data?.is_super_admin ? !response.content?.data?.is_IOH_admin ? response.content?.data?.defaultRole : RoleID.iohAdmin : RoleID.Admin;
          }
        } else {
          if (response.content?.data?.is_super_admin) {
            this.user.currentUserRole = RoleID.Admin
          } else if (response.content?.data?.is_IOH_admin) {
            this.user.currentUserRole = RoleID.iohAdmin
          } else if (response.content?.data?.is_company_admin) {
            this.user.currentUserRole = RoleID.companyAdmin
          } else {
            this.user.currentUserRole = response.content?.data?.defaultRole
          }
          // this.user.currentUserRole = !response.content?.data?.is_super_admin ? !response.content?.data?.is_IOH_admin ? response.content?.data?.defaultRole : RoleID.iohAdmin : RoleID.Admin;
        }

        //this.user$.next(response.content?.data);
        this.user$.next(this.user);
        resolve(response.content.data);
    }, (error) => {
      reject(error);
    });
  });
  }

  getUserProfile(): Observable<Response<any>> {
    return this.serviceClientService.get(environment.endPoints.profile);
  }

  changeRole(user: User,role: string) {
      this.user.currentUserRole = role;
      this.user$.next(this.user);
  }

}
