import { Injectable } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { MessageHandlerService } from "./message-handler.service";

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {

  constructor(private messageHandlerService: MessageHandlerService) { }

  errorHandler: Subject<any> = new Subject;

  handleError(error: HttpErrorResponse) {
    // this.messageHandlerService.generateToastMessage('error', error.error, error.message)
    return error;
  }
}
