import { Component, OnInit } from '@angular/core';
import {PatientService} from "../services/patient/patient.service";
import {ProgressBarService} from "../services/progress-bar.service";
import {MessageHandlerService} from "../services/message-handler.service";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  uploadFile: string;
  isLoading = false;
  constructor(private patientService: PatientService, private readonly progressBarService: ProgressBarService,
              private messageHandlerService: MessageHandlerService) { }

  ngOnInit(): void {
  }

  async handleFileUpload(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    this.progressBarService.setProgressBarVisibility(true);
    this.isLoading = true;
    this.patientService.uploadFileReadCSVFile(formData).subscribe(res => {
        if (res?.success) {
          this.messageHandlerService.generateToastMessage('success','',res?.message)
        } else {
          this.messageHandlerService.generateToastMessage('error','',res?.message)
        }
      this.uploadFile= '';
      this.progressBarService.setProgressBarVisibility(false);
      this.isLoading = false;
    })
  }
}
