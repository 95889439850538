import { createReducer, on } from '@ngrx/store';
import * as AgencyActions from './agency.actions';

export interface AgencyState {
  agencies: string[];
}

export interface CompanyState {
  companies: string[];
}

export const initialAgencyState: AgencyState = {
  agencies: [],
};

export const agencyReducer = createReducer(
  initialAgencyState,
  on(AgencyActions.addAgency, (state, { agencies }) => ({
    agencies: agencies,
  })),
  on(AgencyActions.removeAgency, (state, { agency }) => ({
    ...state,
    agencies: state.agencies.filter(a => a !== agency),
  })),
  on(AgencyActions.removeAllAgencies, (state) => {
    return {agencies: []}
  })
);
