import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../security/auth.service'
import {Router} from "@angular/router";
import {FORBIDDEN, FORBIDDEN_MESSAGE, NOT_ALLOWED} from "../common/Constants";
import {MessageHandlerService} from "../services/message-handler.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, protected router: Router,
                private messageHandlerService: MessageHandlerService,) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
              if(err.status === 401){
                this.authenticationService.signOut();
              }
            console.log('err', err)
            if (err.status === 403) {
              this.messageHandlerService.generateToastMessage(
                'error',
                FORBIDDEN,
                err?.error?.message ? err?.error?.message : FORBIDDEN_MESSAGE
              );

              return null;
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}
