import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  EXPIRED_PASSWORD_TEXT,
  FORGOT_PASSWORD,
  PASSWORD_STRENGTH_STRONG,
  PASSWORD_STRENGTH_STRONG_CLASS,
  PASSWORD_STRENGTH_MEDIUM,
  PASSWORD_STRENGTH_MEDIUM_CLASS,
  PASSWORD_STRENGTH_WEAK,
  PASSWORD_STRENGTH_WEAK_CLASS,
  INVALID_PASSWORD,
  INVALID_PASSWORD_CLASS,
  RESET_PASSWORD_FIELDS_MISSING,
  RESET_PASSWORDS_NOT_MATCHING,
  PASSWORD_STRENGTH_VALIDATION_MESSAGE,
  PASSWORDS_MUST_MATCH,
  RESET_PASSWORDS_NOT_LONG_ENOUGH,
} from "src/app/common/Constants";
import { MessageHandlerService } from "src/app/services/message-handler.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-force-password-change",
  templateUrl: "./force-password-change.component.html",
  styleUrls: ["./force-password-change.component.scss"],
})
export class ForcePasswordChangeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  logoText = EXPIRED_PASSWORD_TEXT;
  passwordStrength: string;
  passwordStrengthClass: string;
  newPassword: string;
  repeatPassword: string;
  passwordDirty = false;
  confirmPasswordDirty = false;
  PASSWORD_STRENGTH_VALIDATION_MESSAGE = PASSWORD_STRENGTH_VALIDATION_MESSAGE;
  PASSWORDS_MUST_MATCH = PASSWORDS_MUST_MATCH;
  constructor(
    private router: Router,
    private messageHandlerService: MessageHandlerService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  checkPasswordStrength(): void {
    const password = this.newPassword;
    const weakRegex = new RegExp("^.{1,}$");
    const rS = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const strongRegex = new RegExp(rS);
    const mS = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/;
    const mediumRegex = new RegExp(mS);
    this.passwordDirty = true;
    if (strongRegex.test(password)) {
      this.passwordStrength = PASSWORD_STRENGTH_STRONG;
      this.passwordStrengthClass = PASSWORD_STRENGTH_STRONG_CLASS;
    } else if (mediumRegex.test(password)) {
      this.passwordStrength = PASSWORD_STRENGTH_MEDIUM;
      this.passwordStrengthClass = PASSWORD_STRENGTH_MEDIUM_CLASS;
    } else if (weakRegex.test(password)) {
      this.passwordStrength = PASSWORD_STRENGTH_WEAK;
      this.passwordStrengthClass = PASSWORD_STRENGTH_WEAK_CLASS;
    } else {
      this.passwordStrength = INVALID_PASSWORD;
      this.passwordStrengthClass = INVALID_PASSWORD_CLASS;
    }
  }

  submitConfirmPasswordForm(form: any) {
    if (!(this.newPassword && this.repeatPassword)) {
      form.control.controls.newpassword.touched = form.control.controls.newpassword.invalid ? true : form.control.controls.newpassword.touched;
      form.control.controls.repeatpassword.touched = form.control.controls.repeatpassword.invalid
        ? true
        : form.control.controls.repeatpassword.touched;
      this.messageHandlerService.generateToastMessage("warn", "", RESET_PASSWORD_FIELDS_MISSING);

      return;
    }

    if (this.newPassword !== this.repeatPassword) {
      this.messageHandlerService.generateToastMessage("error", "", RESET_PASSWORDS_NOT_MATCHING);

      return;
    }

    if (this.passwordStrength !== PASSWORD_STRENGTH_STRONG) {
      this.messageHandlerService.generateToastMessage("error", "", RESET_PASSWORDS_NOT_LONG_ENOUGH);
      return;
    }

    this.subscriptions.push(
      this.userService.updateUserPassword({ password: this.newPassword }).subscribe(
        (res: any) => {
          if (res && res.success) {
            this.messageHandlerService.generateToastMessage("success", "", res?.message);
            this.router.navigate(["/"]);
          } else {
            this.messageHandlerService.generateToastMessage("error", "", res?.message);
          }
        },
        (error) => {
          this.messageHandlerService.generateToastMessage("error", "", "Error in updating password");
        },
      ),
    );
  }
  navigateToPreviousPage() {
    this.router.navigate([FORGOT_PASSWORD]);
  }
}
