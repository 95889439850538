import { Injectable } from '@angular/core';
import { ServiceClientService } from "./service-client.service";
import {Response, User} from "../models/user";
import {BehaviorSubject, Observable} from "rxjs";
import { IOH, environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user = new BehaviorSubject<User>(null);
  public userProfile = this.user.asObservable();
  constructor(private serviceClientService: ServiceClientService) { }

  getAllUsers(pagination): Observable<Response<User[]>> {
    return this.serviceClientService.post(environment.endPoints.getUsers, pagination);
  }

  userActive(req): Observable<Response<User[]>> {
    return this.serviceClientService.post(environment.endPoints.userActive,req);
  }

  // get users with search
  getUsersWithSearch(search): Observable<Response<User[]>> {
    return this.serviceClientService.post(environment.endPoints.getUsersWithSearch, search);
  }

  searchUsers(search): Observable<Response<User[]>> {
    return this.serviceClientService.post(environment.endPoints.searchUsers, search);
  }

  uploadFile(file): Observable<any> {
    return this.serviceClientService.postImage(environment.endPoints.uploadFile, file);
  }

  bulkUpload(file): Observable<any> {
    return this.serviceClientService.postImage(environment.endPoints.bulkUpload, file);
  }

  uploadTemplate(file): Observable<any> {
    return this.serviceClientService.postImage(environment.endPoints.uploadTemplate, file);
  }

  deleteFile(file): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.deleteFile, file);
  }

  createUser(request: User): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.createUser, request);
  }

  updateUser(request: User): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.updateUser, request);
  }

  updateUserRegistrationStatus(request): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.updateUserRegistrationStatus, request);
  }

  updateUserPassword(request): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.forceResetPassword, request);
  }

  deleteUser(request): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.deleteUser, request);
  }

  findUserByEmail(request: any): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.findUserByEmail, request);
  }

  sendPermissionsEmail(request: any): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.updatePermissionsEmail, request);
  }

  sendActivationEmail(request: any): Observable<Response<any>> {
    return this.serviceClientService.post(environment.endPoints.sendActivationEmail, request);
  }

  getUserRole(): Observable<Response<any>> {
    return this.serviceClientService.get(environment.endPoints.getUserRole);
  }

  getUserProfile(): Observable<Response<any>> {
    return this.serviceClientService.get(environment.endPoints.profile);
  }

  checkExistingEmails(email): Observable<Response<any>> {
    // const params = new HttpParams().set('email', email);
    const endpoint = `${environment.endPoints.emailExists}/${email}`;
    return this.serviceClientService.get(endpoint);
  }

  checkPatientService(): Observable<any> {
    return this.serviceClientService.get(environment.endPoints.heartbeat);
  }

  resetPassword(email): Observable<Response<any>> {
    const endpoint = `${environment.endPoints.temporaryPassword}/${email}`;
    return this.serviceClientService.get(endpoint);
  }

  addUser(user: User) {
    this.user.next(user);
  }

  loginWithMicrosoft<TResponse>(): Observable<TResponse> {
    return this.serviceClientService.get(environment.endPoints.ssoMicrosoftLogin)
  }

  logOutWithMicrosoft<TResponse>(): Observable<TResponse> {
    return this.serviceClientService.get(environment.endPoints.ssoMicrosoftLogout)
  }

}
