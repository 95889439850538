export const answerDescription = {
  'GG0130A1-0': '',
  'GG0130A1-1': 'Dependent: 100% Assist',
  'GG0130A1-2': 'Maximal: > 50% Assistance',
  'GG0130A1-3': 'Moderate: < 50% Assistance',
  'GG0130A1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130A1-5': 'Setup or Clean-up Assist',
  'GG0130A1-6': 'Independent',
  'GG0130A1-7': 'Patient Refused',
  'GG0130A1-8': '',
  'GG0130A1-9': 'NA -Patient did not do activity prior',
  'GG0130A1-10': 'Not attempted due to environmental restrictions',
  'GG0130A1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130B1-0': '',
  'GG0130B1-1': 'Dependent: 100% Assist',
  'GG0130B1-2': 'Maximal: > 50% Assistance',
  'GG0130B1-3': 'Moderate: < 50% Assistance',
  'GG0130B1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130B1-5': 'Setup or Clean-up Assist',
  'GG0130B1-6': 'Independent',
  'GG0130B1-7': 'Patient Refused',
  'GG0130B1-8': '',
  'GG0130B1-9': 'NA -Patient did not do activity prior',
  'GG0130B1-10': 'Not attempted due to environmental restrictions',
  'GG0130B1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130C1-0': '',
  'GG0130C1-1': 'Dependent: 100% Assist',
  'GG0130C1-2': 'Maximal: > 50% Assistance',
  'GG0130C1-3': 'Moderate: < 50% Assistance',
  'GG0130C1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130C1-5': 'Setup or Clean-up Assist',
  'GG0130C1-6': 'Independent',
  'GG0130C1-7': 'Patient Refused',
  'GG0130C1-8': '',
  'GG0130C1-9': 'NA -Patient did not do activity prior',
  'GG0130C1-10': 'Not attempted due to environmental restrictions',
  'GG0130C1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130E1-0': '',
  'GG0130E1-1': 'Dependent: 100% Assist',
  'GG0130E1-2': 'Maximal: > 50% Assistance',
  'GG0130E1-3': 'Moderate: < 50% Assistance',
  'GG0130E1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130E1-5': 'Setup or Clean-up Assist',
  'GG0130E1-6': 'Independent',
  'GG0130E1-7': 'Patient Refused',
  'GG0130E1-8': '',
  'GG0130E1-9': 'NA -Patient did not do activity prior',
  'GG0130E1-10': 'Not attempted due to environmental restrictions',
  'GG0130E1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130F1-0': '',
  'GG0130F1-1': 'Dependent: 100% Assist',
  'GG0130F1-2': 'Maximal: > 50% Assistance',
  'GG0130F1-3': 'Moderate: < 50% Assistance',
  'GG0130F1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130F1-5': 'Setup or Clean-up Assist',
  'GG0130F1-6': 'Independent',
  'GG0130F1-7': 'Patient Refused',
  'GG0130F1-8': '',
  'GG0130F1-9': 'NA -Patient did not do activity prior',
  'GG0130F1-10': 'Not attempted due to environmental restrictions',
  'GG0130F1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130G1-0': '',
  'GG0130G1-1': 'Dependent: 100% Assist',
  'GG0130G1-2': 'Maximal: > 50% Assistance',
  'GG0130G1-3': 'Moderate: < 50% Assistance',
  'GG0130G1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130G1-5': 'Setup or Clean-up Assist',
  'GG0130G1-6': 'Independent',
  'GG0130G1-7': 'Patient Refused',
  'GG0130G1-8': '',
  'GG0130G1-9': 'NA -Patient did not do activity prior',
  'GG0130G1-10': 'Not attempted due to environmental restrictions',
  'GG0130G1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130H1-0': '',
  'GG0130H1-1': 'Dependent: 100% Assist',
  'GG0130H1-2': 'Maximal: > 50% Assistance',
  'GG0130H1-3': 'Moderate: < 50% Assistance',
  'GG0130H1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130H1-5': 'Setup or Clean-up Assist',
  'GG0130H1-6': 'Independent',
  'GG0130H1-7': 'Patient Refused',
  'GG0130H1-8': '',
  'GG0130H1-9': 'NA -Patient did not do activity prior',
  'GG0130H1-10': 'Not attempted due to environmental restrictions',
  'GG0130H1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170A1-0': '',
  'GG0170A1-1': 'Dependent: 100% Assist',
  'GG0170A1-2': 'Maximal: > 50% Assistance',
  'GG0170A1-3': 'Moderate: < 50% Assistance',
  'GG0170A1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170A1-5': 'Setup or Clean-up Assist',
  'GG0170A1-6': 'Independent',
  'GG0170A1-7': 'Patient Refused',
  'GG0170A1-8': '',
  'GG0170A1-9': 'NA -Patient did not do activity prior',
  'GG0170A1-10': 'Not attempted due to environmental restrictions',
  'GG0170A1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170B1-0': '',
  'GG0170B1-1': 'Dependent: 100% Assist',
  'GG0170B1-2': 'Maximal: > 50% Assistance',
  'GG0170B1-3': 'Moderate: < 50% Assistance',
  'GG0170B1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170B1-5': 'Setup or Clean-up Assist',
  'GG0170B1-6': 'Independent',
  'GG0170B1-7': 'Patient Refused',
  'GG0170B1-8': '',
  'GG0170B1-9': 'NA -Patient did not do activity prior',
  'GG0170B1-10': 'Not attempted due to environmental restrictions',
  'GG0170B1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170C1-0': '',
  'GG0170C1-1': 'Dependent: 100% Assist',
  'GG0170C1-2': 'Maximal: > 50% Assistance',
  'GG0170C1-3': 'Moderate: < 50% Assistance',
  'GG0170C1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170C1-5': 'Setup or Clean-up Assist',
  'GG0170C1-6': 'Independent',
  'GG0170C1-7': 'Patient Refused',
  'GG0170C1-8': '',
  'GG0170C1-9': 'NA -Patient did not do activity prior',
  'GG0170C1-10': 'Not attempted due to environmental restrictions',
  'GG0170C1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170D1-0': '',
  'GG0170D1-1': 'Dependent: 100% Assist',
  'GG0170D1-2': 'Maximal: > 50% Assistance',
  'GG0170D1-3': 'Moderate: < 50% Assistance',
  'GG0170D1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170D1-5': 'Setup or Clean-up Assist',
  'GG0170D1-6': 'Independent',
  'GG0170D1-7': 'Patient Refused',
  'GG0170D1-8': '',
  'GG0170D1-9': 'NA -Patient did not do activity prior',
  'GG0170D1-10': 'Not attempted due to environmental restrictions',
  'GG0170D1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170E1-0': '',
  'GG0170E1-1': 'Dependent: 100% Assist',
  'GG0170E1-2': 'Maximal: > 50% Assistance',
  'GG0170E1-3': 'Moderate: < 50% Assistance',
  'GG0170E1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170E1-5': 'Setup or Clean-up Assist',
  'GG0170E1-6': 'Independent',
  'GG0170E1-7': 'Patient Refused',
  'GG0170E1-8': '',
  'GG0170E1-9': 'NA -Patient did not do activity prior',
  'GG0170E1-10': 'Not attempted due to environmental restrictions',
  'GG0170E1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170F1-0': '',
  'GG0170F1-1': 'Dependent: 100% Assist',
  'GG0170F1-2': 'Maximal: > 50% Assistance',
  'GG0170F1-3': 'Moderate: < 50% Assistance',
  'GG0170F1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170F1-5': 'Setup or Clean-up Assist',
  'GG0170F1-6': 'Independent',
  'GG0170F1-7': 'Patient Refused',
  'GG0170F1-8': '',
  'GG0170F1-9': 'NA -Patient did not do activity prior',
  'GG0170F1-10': 'Not attempted due to environmental restrictions',
  'GG0170F1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170G1-0': '',
  'GG0170G1-1': 'Dependent: 100% Assist',
  'GG0170G1-2': 'Maximal: > 50% Assistance',
  'GG0170G1-3': 'Moderate: < 50% Assistance',
  'GG0170G1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170G1-5': 'Setup or Clean-up Assist',
  'GG0170G1-6': 'Independent',
  'GG0170G1-7': 'Patient Refused',
  'GG0170G1-8': '',
  'GG0170G1-9': 'NA -Patient did not do activity prior',
  'GG0170G1-10': 'Not attempted due to environmental restrictions',
  'GG0170G1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170I1-0': '',
  'GG0170I1-1': 'Dependent: 100% Assist',
  'GG0170I1-2': 'Maximal: > 50% Assistance',
  'GG0170I1-3': 'Moderate: < 50% Assistance',
  'GG0170I1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170I1-5': 'Setup or Clean-up Assist',
  'GG0170I1-6': 'Independent',
  'GG0170I1-7': 'Patient Refused',
  'GG0170I1-8': '',
  'GG0170I1-9': 'NA -Patient did not do activity prior',
  'GG0170I1-10': 'Not attempted due to environmental restrictions',
  'GG0170I1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170J1-0': '',
  'GG0170J1-1': 'Dependent: 100% Assist',
  'GG0170J1-2': 'Maximal: > 50% Assistance',
  'GG0170J1-3': 'Moderate: < 50% Assistance',
  'GG0170J1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170J1-5': 'Setup or Clean-up Assist',
  'GG0170J1-6': 'Independent',
  'GG0170J1-7': 'Patient Refused',
  'GG0170J1-8': '',
  'GG0170J1-9': 'NA -Patient did not do activity prior',
  'GG0170J1-10': 'Not attempted due to environmental restrictions',
  'GG0170J1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170K1-0': '',
  'GG0170K1-1': 'Dependent: 100% Assist',
  'GG0170K1-2': 'Maximal: > 50% Assistance',
  'GG0170K1-3': 'Moderate: < 50% Assistance',
  'GG0170K1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170K1-5': 'Setup or Clean-up Assist',
  'GG0170K1-6': 'Independent',
  'GG0170K1-7': 'Patient Refused',
  'GG0170K1-8': '',
  'GG0170K1-9': 'NA -Patient did not do activity prior',
  'GG0170K1-10': 'Not attempted due to environmental restrictions',
  'GG0170K1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170L1-0': '',
  'GG0170L1-1': 'Dependent: 100% Assist',
  'GG0170L1-2': 'Maximal: > 50% Assistance',
  'GG0170L1-3': 'Moderate: < 50% Assistance',
  'GG0170L1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170L1-5': 'Setup or Clean-up Assist',
  'GG0170L1-6': 'Independent',
  'GG0170L1-7': 'Patient Refused',
  'GG0170L1-8': '',
  'GG0170L1-9': 'NA -Patient did not do activity prior',
  'GG0170L1-10': 'Not attempted due to environmental restrictions',
  'GG0170L1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170M1-0': '',
  'GG0170M1-1': 'Dependent: 100% Assist',
  'GG0170M1-2': 'Maximal: > 50% Assistance',
  'GG0170M1-3': 'Moderate: < 50% Assistance',
  'GG0170M1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170M1-5': 'Setup or Clean-up Assist',
  'GG0170M1-6': 'Independent',
  'GG0170M1-7': 'Patient Refused',
  'GG0170M1-8': '',
  'GG0170M1-9': 'NA -Patient did not do activity prior',
  'GG0170M1-10': 'Not attempted due to environmental restrictions',
  'GG0170M1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170N1-0': '',
  'GG0170N1-1': 'Dependent: 100% Assist',
  'GG0170N1-2': 'Maximal: > 50% Assistance',
  'GG0170N1-3': 'Moderate: < 50% Assistance',
  'GG0170N1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170N1-5': 'Setup or Clean-up Assist',
  'GG0170N1-6': 'Independent',
  'GG0170N1-7': 'Patient Refused',
  'GG0170N1-8': '',
  'GG0170N1-9': 'NA -Patient did not do activity prior',
  'GG0170N1-10': 'Not attempted due to environmental restrictions',
  'GG0170N1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170O1-0': '',
  'GG0170O1-1': 'Dependent: 100% Assist',
  'GG0170O1-2': 'Maximal: > 50% Assistance',
  'GG0170O1-3': 'Moderate: < 50% Assistance',
  'GG0170O1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170O1-5': 'Setup or Clean-up Assist',
  'GG0170O1-6': 'Independent',
  'GG0170O1-7': 'Patient Refused',
  'GG0170O1-8': '',
  'GG0170O1-9': 'NA -Patient did not do activity prior',
  'GG0170O1-10': 'Not attempted due to environmental restrictions',
  'GG0170O1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170P1-0': '',
  'GG0170P1-1': 'Dependent: 100% Assist',
  'GG0170P1-2': 'Maximal: > 50% Assistance',
  'GG0170P1-3': 'Moderate: < 50% Assistance',
  'GG0170P1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170P1-5': 'Setup or Clean-up Assist',
  'GG0170P1-6': 'Independent',
  'GG0170P1-7': 'Patient Refused',
  'GG0170P1-8': '',
  'GG0170P1-9': 'NA -Patient did not do activity prior',
  'GG0170P1-10': 'Not attempted due to environmental restrictions',
  'GG0170P1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170Q1-0': '',
  'GG0170Q1-1': 'Dependent: 100% Assist',
  'GG0170Q1-2': 'Maximal: > 50% Assistance',
  'GG0170Q1-3': 'Moderate: < 50% Assistance',
  'GG0170Q1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170Q1-5': 'Setup or Clean-up Assist',
  'GG0170Q1-6': 'Independent',
  'GG0170Q1-7': 'Patient Refused',
  'GG0170Q1-8': '',
  'GG0170Q1-9': 'NA -Patient did not do activity prior',
  'GG0170Q1-10': 'Not attempted due to environmental restrictions',
  'GG0170Q1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170R1-0': '',
  'GG0170R1-1': 'Dependent: 100% Assist',
  'GG0170R1-2': 'Maximal: > 50% Assistance',
  'GG0170R1-3': 'Moderate: < 50% Assistance',
  'GG0170R1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170R1-5': 'Setup or Clean-up Assist',
  'GG0170R1-6': 'Independent',
  'GG0170R1-7': 'Patient Refused',
  'GG0170R1-8': '',
  'GG0170R1-9': 'NA -Patient did not do activity prior',
  'GG0170R1-10': 'Not attempted due to environmental restrictions',
  'GG0170R1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170RR1-0': '',
  'GG0170RR1-1': 'Dependent: 100% Assist',
  'GG0170RR1-2': 'Maximal: > 50% Assistance',
  'GG0170RR1-3': 'Moderate: < 50% Assistance',
  'GG0170RR1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170RR1-5': 'Setup or Clean-up Assist',
  'GG0170RR1-6': 'Independent',
  'GG0170RR1-7': 'Patient Refused',
  'GG0170RR1-8': '',
  'GG0170RR1-9': 'NA -Patient did not do activity prior',
  'GG0170RR1-10': 'Not attempted due to environmental restrictions',
  'GG0170RR1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170S1-0': '',
  'GG0170S1-1': 'Dependent: 100% Assist',
  'GG0170S1-2': 'Maximal: > 50% Assistance',
  'GG0170S1-3': 'Moderate: < 50% Assistance',
  'GG0170S1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170S1-5': 'Setup or Clean-up Assist',
  'GG0170S1-6': 'Independent',
  'GG0170S1-7': 'Patient Refused',
  'GG0170S1-8': '',
  'GG0170S1-9': 'NA -Patient did not do activity prior',
  'GG0170S1-10': 'Not attempted due to environmental restrictions',
  'GG0170S1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170SS1-0': '',
  'GG0170SS1-1': 'Dependent: 100% Assist',
  'GG0170SS1-2': 'Maximal: > 50% Assistance',
  'GG0170SS1-3': 'Moderate: < 50% Assistance',
  'GG0170SS1-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170SS1-5': 'Setup or Clean-up Assist',
  'GG0170SS1-6': 'Independent',
  'GG0170SS1-7': 'Patient Refused',
  'GG0170SS1-8': '',
  'GG0170SS1-9': 'NA -Patient did not do activity prior',
  'GG0170SS1-10': 'Not attempted due to environmental restrictions',
  'GG0170SS1-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130A2-0': '',
  'GG0130A2-1': 'Dependent: 100% Assist',
  'GG0130A2-2': 'Maximal: > 50% Assistance',
  'GG0130A2-3': 'Moderate: < 50% Assistance',
  'GG0130A2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130A2-5': 'Setup or Clean-up Assist',
  'GG0130A2-6': 'Independent',
  'GG0130A2-7': 'Patient Refused',
  'GG0130A2-8': '',
  'GG0130A2-9': 'NA -Patient did not do activity prior',
  'GG0130A2-10': 'Not attempted due to environmental restrictions',
  'GG0130A2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130B2-0': '',
  'GG0130B2-1': 'Dependent: 100% Assist',
  'GG0130B2-2': 'Maximal: > 50% Assistance',
  'GG0130B2-3': 'Moderate: < 50% Assistance',
  'GG0130B2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130B2-5': 'Setup or Clean-up Assist',
  'GG0130B2-6': 'Independent',
  'GG0130B2-7': 'Patient Refused',
  'GG0130B2-8': '',
  'GG0130B2-9': 'NA -Patient did not do activity prior',
  'GG0130B2-10': 'Not attempted due to environmental restrictions',
  'GG0130B2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130C2-0': '',
  'GG0130C2-1': 'Dependent: 100% Assist',
  'GG0130C2-2': 'Maximal: > 50% Assistance',
  'GG0130C2-3': 'Moderate: < 50% Assistance',
  'GG0130C2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130C2-5': 'Setup or Clean-up Assist',
  'GG0130C2-6': 'Independent',
  'GG0130C2-7': 'Patient Refused',
  'GG0130C2-8': '',
  'GG0130C2-9': 'NA -Patient did not do activity prior',
  'GG0130C2-10': 'Not attempted due to environmental restrictions',
  'GG0130C2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130E2-0': '',
  'GG0130E2-1': 'Dependent: 100% Assist',
  'GG0130E2-2': 'Maximal: > 50% Assistance',
  'GG0130E2-3': 'Moderate: < 50% Assistance',
  'GG0130E2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130E2-5': 'Setup or Clean-up Assist',
  'GG0130E2-6': 'Independent',
  'GG0130E2-7': 'Patient Refused',
  'GG0130E2-8': '',
  'GG0130E2-9': 'NA -Patient did not do activity prior',
  'GG0130E2-10': 'Not attempted due to environmental restrictions',
  'GG0130E2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130F2-0': '',
  'GG0130F2-1': 'Dependent: 100% Assist',
  'GG0130F2-2': 'Maximal: > 50% Assistance',
  'GG0130F2-3': 'Moderate: < 50% Assistance',
  'GG0130F2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130F2-5': 'Setup or Clean-up Assist',
  'GG0130F2-6': 'Independent',
  'GG0130F2-7': 'Patient Refused',
  'GG0130F2-8': '',
  'GG0130F2-9': 'NA -Patient did not do activity prior',
  'GG0130F2-10': 'Not attempted due to environmental restrictions',
  'GG0130F2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130G2-0': '',
  'GG0130G2-1': 'Dependent: 100% Assist',
  'GG0130G2-2': 'Maximal: > 50% Assistance',
  'GG0130G2-3': 'Moderate: < 50% Assistance',
  'GG0130G2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130G2-5': 'Setup or Clean-up Assist',
  'GG0130G2-6': 'Independent',
  'GG0130G2-7': 'Patient Refused',
  'GG0130G2-8': '',
  'GG0130G2-9': 'NA -Patient did not do activity prior',
  'GG0130G2-10': 'Not attempted due to environmental restrictions',
  'GG0130G2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0130H2-0': '',
  'GG0130H2-1': 'Dependent: 100% Assist',
  'GG0130H2-2': 'Maximal: > 50% Assistance',
  'GG0130H2-3': 'Moderate: < 50% Assistance',
  'GG0130H2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0130H2-5': 'Setup or Clean-up Assist',
  'GG0130H2-6': 'Independent',
  'GG0130H2-7': 'Patient Refused',
  'GG0130H2-8': '',
  'GG0130H2-9': 'NA -Patient did not do activity prior',
  'GG0130H2-10': 'Not attempted due to environmental restrictions',
  'GG0130H2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170A2-0': '',
  'GG0170A2-1': 'Dependent: 100% Assist',
  'GG0170A2-2': 'Maximal: > 50% Assistance',
  'GG0170A2-3': 'Moderate: < 50% Assistance',
  'GG0170A2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170A2-5': 'Setup or Clean-up Assist',
  'GG0170A2-6': 'Independent',
  'GG0170A2-7': 'Patient Refused',
  'GG0170A2-8': '',
  'GG0170A2-9': 'NA -Patient did not do activity prior',
  'GG0170A2-10': 'Not attempted due to environmental restrictions',
  'GG0170A2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170B2-0': '',
  'GG0170B2-1': 'Dependent: 100% Assist',
  'GG0170B2-2': 'Maximal: > 50% Assistance',
  'GG0170B2-3': 'Moderate: < 50% Assistance',
  'GG0170B2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170B2-5': 'Setup or Clean-up Assist',
  'GG0170B2-6': 'Independent',
  'GG0170B2-7': 'Patient Refused',
  'GG0170B2-8': '',
  'GG0170B2-9': 'NA -Patient did not do activity prior',
  'GG0170B2-10': 'Not attempted due to environmental restrictions',
  'GG0170B2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170C2-0': '',
  'GG0170C2-1': 'Dependent: 100% Assist',
  'GG0170C2-2': 'Maximal: > 50% Assistance',
  'GG0170C2-3': 'Moderate: < 50% Assistance',
  'GG0170C2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170C2-5': 'Setup or Clean-up Assist',
  'GG0170C2-6': 'Independent',
  'GG0170C2-7': 'Patient Refused',
  'GG0170C2-8': '',
  'GG0170C2-9': 'NA -Patient did not do activity prior',
  'GG0170C2-10': 'Not attempted due to environmental restrictions',
  'GG0170C2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170D2-0': '',
  'GG0170D2-1': 'Dependent: 100% Assist',
  'GG0170D2-2': 'Maximal: > 50% Assistance',
  'GG0170D2-3': 'Moderate: < 50% Assistance',
  'GG0170D2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170D2-5': 'Setup or Clean-up Assist',
  'GG0170D2-6': 'Independent',
  'GG0170D2-7': 'Patient Refused',
  'GG0170D2-8': '',
  'GG0170D2-9': 'NA -Patient did not do activity prior',
  'GG0170D2-10': 'Not attempted due to environmental restrictions',
  'GG0170D2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170E2-0': '',
  'GG0170E2-1': 'Dependent: 100% Assist',
  'GG0170E2-2': 'Maximal: > 50% Assistance',
  'GG0170E2-3': 'Moderate: < 50% Assistance',
  'GG0170E2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170E2-5': 'Setup or Clean-up Assist',
  'GG0170E2-6': 'Independent',
  'GG0170E2-7': 'Patient Refused',
  'GG0170E2-8': '',
  'GG0170E2-9': 'NA -Patient did not do activity prior',
  'GG0170E2-10': 'Not attempted due to environmental restrictions',
  'GG0170E2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170F2-0': '',
  'GG0170F2-1': 'Dependent: 100% Assist',
  'GG0170F2-2': 'Maximal: > 50% Assistance',
  'GG0170F2-3': 'Moderate: < 50% Assistance',
  'GG0170F2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170F2-5': 'Setup or Clean-up Assist',
  'GG0170F2-6': 'Independent',
  'GG0170F2-7': 'Patient Refused',
  'GG0170F2-8': '',
  'GG0170F2-9': 'NA -Patient did not do activity prior',
  'GG0170F2-10': 'Not attempted due to environmental restrictions',
  'GG0170F2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170G2-0': '',
  'GG0170G2-1': 'Dependent: 100% Assist',
  'GG0170G2-2': 'Maximal: > 50% Assistance',
  'GG0170G2-3': 'Moderate: < 50% Assistance',
  'GG0170G2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170G2-5': 'Setup or Clean-up Assist',
  'GG0170G2-6': 'Independent',
  'GG0170G2-7': 'Patient Refused',
  'GG0170G2-8': '',
  'GG0170G2-9': 'NA -Patient did not do activity prior',
  'GG0170G2-10': 'Not attempted due to environmental restrictions',
  'GG0170G2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170I2-0': '',
  'GG0170I2-1': 'Dependent: 100% Assist',
  'GG0170I2-2': 'Maximal: > 50% Assistance',
  'GG0170I2-3': 'Moderate: < 50% Assistance',
  'GG0170I2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170I2-5': 'Setup or Clean-up Assist',
  'GG0170I2-6': 'Independent',
  'GG0170I2-7': 'Patient Refused',
  'GG0170I2-8': '',
  'GG0170I2-9': 'NA -Patient did not do activity prior',
  'GG0170I2-10': 'Not attempted due to environmental restrictions',
  'GG0170I2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170J2-0': '',
  'GG0170J2-1': 'Dependent: 100% Assist',
  'GG0170J2-2': 'Maximal: > 50% Assistance',
  'GG0170J2-3': 'Moderate: < 50% Assistance',
  'GG0170J2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170J2-5': 'Setup or Clean-up Assist',
  'GG0170J2-6': 'Independent',
  'GG0170J2-7': 'Patient Refused',
  'GG0170J2-8': '',
  'GG0170J2-9': 'NA -Patient did not do activity prior',
  'GG0170J2-10': 'Not attempted due to environmental restrictions',
  'GG0170J2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170K2-0': '',
  'GG0170K2-1': 'Dependent: 100% Assist',
  'GG0170K2-2': 'Maximal: > 50% Assistance',
  'GG0170K2-3': 'Moderate: < 50% Assistance',
  'GG0170K2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170K2-5': 'Setup or Clean-up Assist',
  'GG0170K2-6': 'Independent',
  'GG0170K2-7': 'Patient Refused',
  'GG0170K2-8': '',
  'GG0170K2-9': 'NA -Patient did not do activity prior',
  'GG0170K2-10': 'Not attempted due to environmental restrictions',
  'GG0170K2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170L2-0': '',
  'GG0170L2-1': 'Dependent: 100% Assist',
  'GG0170L2-2': 'Maximal: > 50% Assistance',
  'GG0170L2-3': 'Moderate: < 50% Assistance',
  'GG0170L2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170L2-5': 'Setup or Clean-up Assist',
  'GG0170L2-6': 'Independent',
  'GG0170L2-7': 'Patient Refused',
  'GG0170L2-8': '',
  'GG0170L2-9': 'NA -Patient did not do activity prior',
  'GG0170L2-10': 'Not attempted due to environmental restrictions',
  'GG0170L2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170M2-0': '',
  'GG0170M2-1': 'Dependent: 100% Assist',
  'GG0170M2-2': 'Maximal: > 50% Assistance',
  'GG0170M2-3': 'Moderate: < 50% Assistance',
  'GG0170M2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170M2-5': 'Setup or Clean-up Assist',
  'GG0170M2-6': 'Independent',
  'GG0170M2-7': 'Patient Refused',
  'GG0170M2-8': '',
  'GG0170M2-9': 'NA -Patient did not do activity prior',
  'GG0170M2-10': 'Not attempted due to environmental restrictions',
  'GG0170M2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170N2-0': '',
  'GG0170N2-1': 'Dependent: 100% Assist',
  'GG0170N2-2': 'Maximal: > 50% Assistance',
  'GG0170N2-3': 'Moderate: < 50% Assistance',
  'GG0170N2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170N2-5': 'Setup or Clean-up Assist',
  'GG0170N2-6': 'Independent',
  'GG0170N2-7': 'Patient Refused',
  'GG0170N2-8': '',
  'GG0170N2-9': 'NA -Patient did not do activity prior',
  'GG0170N2-10': 'Not attempted due to environmental restrictions',
  'GG0170N2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170O2-0': '',
  'GG0170O2-1': 'Dependent: 100% Assist',
  'GG0170O2-2': 'Maximal: > 50% Assistance',
  'GG0170O2-3': 'Moderate: < 50% Assistance',
  'GG0170O2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170O2-5': 'Setup or Clean-up Assist',
  'GG0170O2-6': 'Independent',
  'GG0170O2-7': 'Patient Refused',
  'GG0170O2-8': '',
  'GG0170O2-9': 'NA -Patient did not do activity prior',
  'GG0170O2-10': 'Not attempted due to environmental restrictions',
  'GG0170O2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170P2-0': '',
  'GG0170P2-1': 'Dependent: 100% Assist',
  'GG0170P2-2': 'Maximal: > 50% Assistance',
  'GG0170P2-3': 'Moderate: < 50% Assistance',
  'GG0170P2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170P2-5': 'Setup or Clean-up Assist',
  'GG0170P2-6': 'Independent',
  'GG0170P2-7': 'Patient Refused',
  'GG0170P2-8': '',
  'GG0170P2-9': 'NA -Patient did not do activity prior',
  'GG0170P2-10': 'Not attempted due to environmental restrictions',
  'GG0170P2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170R2-0': '',
  'GG0170R2-1': 'Dependent: 100% Assist',
  'GG0170R2-2': 'Maximal: > 50% Assistance',
  'GG0170R2-3': 'Moderate: < 50% Assistance',
  'GG0170R2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170R2-5': 'Setup or Clean-up Assist',
  'GG0170R2-6': 'Independent',
  'GG0170R2-7': 'Patient Refused',
  'GG0170R2-8': '',
  'GG0170R2-9': 'NA -Patient did not do activity prior',
  'GG0170R2-10': 'Not attempted due to environmental restrictions',
  'GG0170R2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0170S2-0': '',
  'GG0170S2-1': 'Dependent: 100% Assist',
  'GG0170S2-2': 'Maximal: > 50% Assistance',
  'GG0170S2-3': 'Moderate: < 50% Assistance',
  'GG0170S2-4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  'GG0170S2-5': 'Setup or Clean-up Assist',
  'GG0170S2-6': 'Independent',
  'GG0170S2-7': 'Patient Refused',
  'GG0170S2-8': '',
  'GG0170S2-9': 'NA -Patient did not do activity prior',
  'GG0170S2-10': 'Not attempted due to environmental restrictions',
  'GG0170S2-88': 'Not attempted due to medical conditions/safety concerns',
  'GG0100A-0': '',
  'GG0100A-1': 'Dependent: A helper completed all activities',
  'GG0100A-2': 'Needed some help',
  'GG0100A-3': 'Independent',
  'GG0100A-4': '',
  'GG0100A-5': '',
  'GG0100A-6': '',
  'GG0100A-7': '',
  'GG0100A-8': 'Unknown',
  'GG0100A-9': 'NA -Patient did not do activity prior',
  'GG0100A-10': '',
  'GG0100A-88': '',
  'GG0100B-0': '',
  'GG0100B-1': 'Dependent: A helper completed all activities',
  'GG0100B-2': 'Needed some help',
  'GG0100B-3': 'Independent',
  'GG0100B-4': '',
  'GG0100B-5': '',
  'GG0100B-6': '',
  'GG0100B-7': '',
  'GG0100B-8': 'Unknown',
  'GG0100B-9': 'NA -Patient did not do activity prior',
  'GG0100B-10': '',
  'GG0100B-88': '',
  'GG0100C-0': '',
  'GG0100C-1': 'Dependent: A helper completed all activities',
  'GG0100C-2': 'Needed some help',
  'GG0100C-3': 'Independent',
  'GG0100C-4': '',
  'GG0100C-5': '',
  'GG0100C-6': '',
  'GG0100C-7': '',
  'GG0100C-8': 'Unknown',
  'GG0100C-9': 'NA -Patient did not do activity prior',
  'GG0100C-10': '',
  'GG0100C-88': '',
  'GG0100D-0': '',
  'GG0100D-1': 'Dependent: A helper completed all activities',
  'GG0100D-2': 'Needed some help',
  'GG0100D-3': 'Independent',
  'GG0100D-4': '',
  'GG0100D-5': '',
  'GG0100D-6': '',
  'GG0100D-7': '',
  'GG0100D-8': 'Unknown',
  'GG0100D-9': 'NA -Patient did not do activity prior',
  'GG0100D-10': '',
  'GG0100D-88': '',
  'GG0110A-0': '',
  'GG0110A-1': '',
  'GG0110A-2': '',
  'GG0110A-3': '',
  'GG0110A-4': '',
  'GG0110A-5': '',
  'GG0110A-6': '',
  'GG0110A-7': '',
  'GG0110A-8': '',
  'GG0110A-9': '',
  'GG0110A-10': '',
  'GG0110A-88': '',
  'GG0110B-0': '',
  'GG0110B-1': '',
  'GG0110B-2': '',
  'GG0110B-3': '',
  'GG0110B-4': '',
  'GG0110B-5': '',
  'GG0110B-6': '',
  'GG0110B-7': '',
  'GG0110B-8': '',
  'GG0110B-9': '',
  'GG0110B-10': '',
  'GG0110B-88': '',
  'GG0110C-0': '',
  'GG0110C-1': '',
  'GG0110C-2': '',
  'GG0110C-3': '',
  'GG0110C-4': '',
  'GG0110C-5': '',
  'GG0110C-6': '',
  'GG0110C-7': '',
  'GG0110C-8': '',
  'GG0110C-9': '',
  'GG0110C-10': '',
  'GG0110C-88': '',
  'GG0110D-0': '',
  'GG0110D-1': '',
  'GG0110D-2': '',
  'GG0110D-3': '',
  'GG0110D-4': '',
  'GG0110D-5': '',
  'GG0110D-6': '',
  'GG0110D-7': '',
  'GG0110D-8': '',
  'GG0110D-9': '',
  'GG0110D-10': '',
  'GG0110D-88': '',
  'GG0110E-0': '',
  'GG0110E-1': '',
  'GG0110E-2': '',
  'GG0110E-3': '',
  'GG0110E-4': '',
  'GG0110E-5': '',
  'GG0110E-6': '',
  'GG0110E-7': '',
  'GG0110E-8': '',
  'GG0110E-9': '',
  'GG0110E-10': '',
  'GG0110E-88': '',
  'GG0110Z-0': '',
  'GG0110Z-1': '',
  'GG0110Z-2': '',
  'GG0110Z-3': '',
  'GG0110Z-4': '',
  'GG0110Z-5': '',
  'GG0110Z-6': '',
  'GG0110Z-7': '',
  'GG0110Z-8': '',
  'GG0110Z-9': '',
  'GG0110Z-10': '',
  'GG0110Z-88': '',
  'M0150-0': '',
  'M0150-1': '',
  'M0150-2': '',
  'M0150-3': '',
  'M0150-4': '',
  'M0150-5': '',
  'M0150-6': '',
  'M0150-7': '',
  'M0150-8': '',
  'M0150-9': '',
  'M0150-10': '',
  'M0150-88': '',
  'M0150(1)-0': '',
  'M0150(1)-1': '',
  'M0150(1)-2': '',
  'M0150(1)-3': '',
  'M0150(1)-4': '',
  'M0150(1)-5': '',
  'M0150(1)-6': '',
  'M0150(1)-7': '',
  'M0150(1)-8': '',
  'M0150(1)-9': '',
  'M0150(1)-10': '',
  'M0150(1)-88': '',
  'M0150(11)-0': '',
  'M0150(11)-1': '',
  'M0150(11)-2': '',
  'M0150(11)-3': '',
  'M0150(11)-4': '',
  'M0150(11)-5': '',
  'M0150(11)-6': '',
  'M0150(11)-7': '',
  'M0150(11)-8': '',
  'M0150(11)-9': '',
  'M0150(11)-10': '',
  'M0150(11)-88': '',
  'M0150(12)-0': '',
  'M0150(12)-1': '',
  'M0150(12)-2': '',
  'M0150(12)-3': '',
  'M0150(12)-4': '',
  'M0150(12)-5': '',
  'M0150(12)-6': '',
  'M0150(12)-7': '',
  'M0150(12)-8': '',
  'M0150(12)-9': '',
  'M0150(12)-10': '',
  'M0150(12)-88': '',
  'M0150(13)-0': '',
  'M0150(13)-1': '',
  'M0150(13)-2': '',
  'M0150(13)-3': '',
  'M0150(13)-4': '',
  'M0150(13)-5': '',
  'M0150(13)-6': '',
  'M0150(13)-7': '',
  'M0150(13)-8': '',
  'M0150(13)-9': '',
  'M0150(13)-10': '',
  'M0150(13)-88': '',
  'M0150(2)-0': '',
  'M0150(2)-1': '',
  'M0150(2)-2': '',
  'M0150(2)-3': '',
  'M0150(2)-4': '',
  'M0150(2)-5': '',
  'M0150(2)-6': '',
  'M0150(2)-7': '',
  'M0150(2)-8': '',
  'M0150(2)-9': '',
  'M0150(2)-10': '',
  'M0150(2)-88': '',
  'M0150(3)-0': '',
  'M0150(3)-1': '',
  'M0150(3)-2': '',
  'M0150(3)-3': '',
  'M0150(3)-4': '',
  'M0150(3)-5': '',
  'M0150(3)-6': '',
  'M0150(3)-7': '',
  'M0150(3)-8': '',
  'M0150(3)-9': '',
  'M0150(3)-10': '',
  'M0150(3)-88': '',
  'M0150(4)-0': '',
  'M0150(4)-1': '',
  'M0150(4)-2': '',
  'M0150(4)-3': '',
  'M0150(4)-4': '',
  'M0150(4)-5': '',
  'M0150(4)-6': '',
  'M0150(4)-7': '',
  'M0150(4)-8': '',
  'M0150(4)-9': '',
  'M0150(4)-10': '',
  'M0150(4)-88': '',
  'M0150(5)-0': '',
  'M0150(5)-1': '',
  'M0150(5)-2': '',
  'M0150(5)-3': '',
  'M0150(5)-4': '',
  'M0150(5)-5': '',
  'M0150(5)-6': '',
  'M0150(5)-7': '',
  'M0150(5)-8': '',
  'M0150(5)-9': '',
  'M0150(5)-10': '',
  'M0150(5)-88': '',
  'M0150(6)-0': '',
  'M0150(6)-1': '',
  'M0150(6)-2': '',
  'M0150(6)-3': '',
  'M0150(6)-4': '',
  'M0150(6)-5': '',
  'M0150(6)-6': '',
  'M0150(6)-7': '',
  'M0150(6)-8': '',
  'M0150(6)-9': '',
  'M0150(6)-10': '',
  'M0150(6)-88': '',
  'M0150(7)-0': '',
  'M0150(7)-1': '',
  'M0150(7)-2': '',
  'M0150(7)-3': '',
  'M0150(7)-4': '',
  'M0150(7)-5': '',
  'M0150(7)-6': '',
  'M0150(7)-7': '',
  'M0150(7)-8': '',
  'M0150(7)-9': '',
  'M0150(7)-10': '',
  'M0150(7)-88': '',
  'M0150(8)-0': '',
  'M0150(8)-1': '',
  'M0150(8)-2': '',
  'M0150(8)-3': '',
  'M0150(8)-4': '',
  'M0150(8)-5': '',
  'M0150(8)-6': '',
  'M0150(8)-7': '',
  'M0150(8)-8': '',
  'M0150(8)-9': '',
  'M0150(8)-10': '',
  'M0150(8)-88': '',
  'M0150(9)-0': '',
  'M0150(9)-1': '',
  'M0150(9)-2': '',
  'M0150(9)-3': '',
  'M0150(9)-4': '',
  'M0150(9)-5': '',
  'M0150(9)-6': '',
  'M0150(9)-7': '',
  'M0150(9)-8': '',
  'M0150(9)-9': '',
  'M0150(9)-10': '',
  'M0150(9)-88': '',
  'M1033-0': '',
  'M1033-1': '',
  'M1033-2': '',
  'M1033-3': '',
  'M1033-4': '',
  'M1033-5': '',
  'M1033-6': '',
  'M1033-7': '',
  'M1033-8': '',
  'M1033-9': '',
  'M1033-10': '',
  'M1033-88': '',
  'M1033(1)-0': '',
  'M1033(1)-1': '',
  'M1033(1)-2': '',
  'M1033(1)-3': '',
  'M1033(1)-4': '',
  'M1033(1)-5': '',
  'M1033(1)-6': '',
  'M1033(1)-7': '',
  'M1033(1)-8': '',
  'M1033(1)-9': '',
  'M1033(1)-10': '',
  'M1033(1)-88': '',
  'M1033(2)-0': '',
  'M1033(2)-1': '',
  'M1033(2)-2': '',
  'M1033(2)-3': '',
  'M1033(2)-4': '',
  'M1033(2)-5': '',
  'M1033(2)-6': '',
  'M1033(2)-7': '',
  'M1033(2)-8': '',
  'M1033(2)-9': '',
  'M1033(2)-10': '',
  'M1033(2)-88': '',
  'M1033(3)-0': '',
  'M1033(3)-1': '',
  'M1033(3)-2': '',
  'M1033(3)-3': '',
  'M1033(3)-4': '',
  'M1033(3)-5': '',
  'M1033(3)-6': '',
  'M1033(3)-7': '',
  'M1033(3)-8': '',
  'M1033(3)-9': '',
  'M1033(3)-10': '',
  'M1033(3)-88': '',
  'M1033(4)-0': '',
  'M1033(4)-1': '',
  'M1033(4)-2': '',
  'M1033(4)-3': '',
  'M1033(4)-4': '',
  'M1033(4)-5': '',
  'M1033(4)-6': '',
  'M1033(4)-7': '',
  'M1033(4)-8': '',
  'M1033(4)-9': '',
  'M1033(4)-10': '',
  'M1033(4)-88': '',
  'M1033(5)-0': '',
  'M1033(5)-1': '',
  'M1033(5)-2': '',
  'M1033(5)-3': '',
  'M1033(5)-4': '',
  'M1033(5)-5': '',
  'M1033(5)-6': '',
  'M1033(5)-7': '',
  'M1033(5)-8': '',
  'M1033(5)-9': '',
  'M1033(5)-10': '',
  'M1033(5)-88': '',
  'M1033(6)-0': '',
  'M1033(6)-1': '',
  'M1033(6)-2': '',
  'M1033(6)-3': '',
  'M1033(6)-4': '',
  'M1033(6)-5': '',
  'M1033(6)-6': '',
  'M1033(6)-7': '',
  'M1033(6)-8': '',
  'M1033(6)-9': '',
  'M1033(6)-10': '',
  'M1033(6)-88': '',
  'M1033(7)-0': '',
  'M1033(7)-1': '',
  'M1033(7)-2': '',
  'M1033(7)-3': '',
  'M1033(7)-4': '',
  'M1033(7)-5': '',
  'M1033(7)-6': '',
  'M1033(7)-7': '',
  'M1033(7)-8': '',
  'M1033(7)-9': '',
  'M1033(7)-10': '',
  'M1033(7)-88': '',
  'M1060A-0': '',
  'M1060A-1': '',
  'M1060A-2': '',
  'M1060A-3': '',
  'M1060A-4': '',
  'M1060A-5': '',
  'M1060A-6': '',
  'M1060A-7': '',
  'M1060A-8': '',
  'M1060A-9': '',
  'M1060A-10': '',
  'M1060A-88': '',
  'M1060B-0': '',
  'M1060B-1': '',
  'M1060B-2': '',
  'M1060B-3': '',
  'M1060B-4': '',
  'M1060B-5': '',
  'M1060B-6': '',
  'M1060B-7': '',
  'M1060B-8': '',
  'M1060B-9': '',
  'M1060B-10': '',
  'M1060B-88': '',
  'M1340-0': 'No',
  'M1340-1': 'Yes',
  'M1340-2': 'Wound known but not observable due to non-removable dressing',
  'M1340-3': '',
  'M1340-4': '',
  'M1340-5': '',
  'M1340-6': '',
  'M1340-7': '',
  'M1340-8': '',
  'M1340-9': '',
  'M1340-10': '',
  'M1340-88': '',
  'M1342-0': 'Newly epithelialized',
  'M1342-1': 'Fully granulating',
  'M1342-2': 'Early/partial granulation',
  'M1342-3': 'Not healing',
  'M1342-4': '',
  'M1342-5': '',
  'M1342-6': '',
  'M1342-7': '',
  'M1342-8': '',
  'M1342-9': '',
  'M1342-10': '',
  'M1342-88': '',
  'M1400-0': 'Not short of breath',
  'M1400-1': 'Walking > 20 feet, climbing stairs',
  'M1400-2': 'Moderate exertion',
  'M1400-3': 'Minimal exertion',
  'M1400-4': 'At rest',
  'M1400-5': '',
  'M1400-6': '',
  'M1400-7': '',
  'M1400-8': '',
  'M1400-9': '',
  'M1400-10': '',
  'M1400-88': '',
  'M1600-0': 'No',
  'M1600-1': 'Yes',
  'M1600-2': '',
  'M1600-3': '',
  'M1600-4': '',
  'M1600-5': '',
  'M1600-6': '',
  'M1600-7': '',
  'M1600-8': '',
  'M1600-9': '',
  'M1600-10': 'Patient on prophylactic Tx',
  'M1600-88': 'Unknown',
  'M1610-0': 'No incontinence or catheter',
  'M1610-1': 'Incontinent',
  'M1610-2': 'Requires catheter',
  'M1610-3': '',
  'M1610-4': '',
  'M1610-5': '',
  'M1610-6': '',
  'M1610-7': '',
  'M1610-8': '',
  'M1610-9': '',
  'M1610-10': '',
  'M1610-88': '',
  'M1700-0': 'Alert & Oriented',
  'M1700-1': 'Requires prompting under stressful or unfamiliar conditions',
  'M1700-2': 'Requires assistance and some direction OR consistently requires low stimulus environment',
  'M1700-3': 'Requires moderate assistance in routine situations, unable to shift attention and recall > 50% of time ',
  'M1700-4': '100% dependent',
  'M1700-5': '',
  'M1700-6': '',
  'M1700-7': '',
  'M1700-8': '',
  'M1700-9': '',
  'M1700-10': '',
  'M1700-88': '',
  'M1710-0': 'Never',
  'M1710-1': 'In new or complex situations',
  'M1710-2': 'On awakening or evenings',
  'M1710-3': 'Intermittent during day',
  'M1710-4': 'Constantly',
  'M1710-5': '',
  'M1710-6': '',
  'M1710-7': '',
  'M1710-8': '',
  'M1710-9': '',
  'M1710-10': 'Not Responsive',
  'M1710-88': '',
  'M1745-0': 'Never',
  'M1745-1': '< 1x/month',
  'M1745-2': '1x/month',
  'M1745-3': 'Several times/month',
  'M1745-4': 'Several times/week',
  'M1745-5': 'At least daily',
  'M1745-6': '',
  'M1745-7': '',
  'M1745-8': '',
  'M1745-9': '',
  'M1745-10': '',
  'M1745-88': '',
  'M1800-0': 'Independent',
  'M1800-1': 'Needs help with set up',
  'M1800-2': 'Needs assistance',
  'M1800-3': 'Dependent',
  'M1800-4': '',
  'M1800-5': '',
  'M1800-6': '',
  'M1800-7': '',
  'M1800-8': '',
  'M1800-9': '',
  'M1800-10': '',
  'M1800-88': '',
  'M1810-0': 'Independent',
  'M1810-1': 'Needs help with set up',
  'M1810-2': 'Needs assistance',
  'M1810-3': 'Dependent',
  'M1810-4': '',
  'M1810-5': '',
  'M1810-6': '',
  'M1810-7': '',
  'M1810-8': '',
  'M1810-9': '',
  'M1810-10': '',
  'M1810-88': '',
  'M1820-0': 'Independent',
  'M1820-1': 'Needs help with set up',
  'M1820-2': 'Needs assistance',
  'M1820-3': 'Dependent',
  'M1820-4': '',
  'M1820-5': '',
  'M1820-6': '',
  'M1820-7': '',
  'M1820-8': '',
  'M1820-9': '',
  'M1820-10': '',
  'M1820-88': '',
  'M1830-0': 'Independent',
  'M1830-1': 'Independent with assistive device',
  'M1830-2': 'Requires intermittent assistance',
  'M1830-3': 'Requires assistance/supervision the entire time',
  'M1830-4': 'Unable to use shower/tub but independent bathing in bed/sink, etc.',
  'M1830-5': 'Can participate in bathing self with assistance  in bed/sink, etc.',
  'M1830-6': 'Dependent',
  'M1830-7': '',
  'M1830-8': '',
  'M1830-9': '',
  'M1830-10': '',
  'M1830-88': '',
  'M1840-0': 'Independent',
  'M1840-1': 'Requires assistance',
  'M1840-2': 'Independent with bedside commode',
  'M1840-3': 'Independent with bedpan/urinal',
  'M1840-4': 'Dependent',
  'M1840-5': '',
  'M1840-6': '',
  'M1840-7': '',
  'M1840-8': '',
  'M1840-9': '',
  'M1840-10': '',
  'M1840-88': '',
  'M1845-0': 'Independent',
  'M1845-1': 'Needs help with set up',
  'M1845-2': 'Assistance',
  'M1845-3': 'Dependent',
  'M1845-4': '',
  'M1845-5': '',
  'M1845-6': '',
  'M1845-7': '',
  'M1845-8': '',
  'M1845-9': '',
  'M1845-10': '',
  'M1845-88': '',
  'M1850-0': 'Independent',
  'M1850-1': 'Use of assistive device or minimal assistance',
  'M1850-2': 'Bear weight and pivot but unable to transfer',
  'M1850-3': 'Unable to transfer or weight bear',
  'M1850-4': 'Independent bed mobility',
  'M1850-5': 'Dependent transfer and bed mobility',
  'M1850-6': '',
  'M1850-7': '',
  'M1850-8': '',
  'M1850-9': '',
  'M1850-10': '',
  'M1850-88': '',
  'M1860-0': 'Independent',
  'M1860-1': 'Independent with one-handed device',
  'M1860-2': 'Requires two-handed device',
  'M1860-3': 'Requires supervision/assistance at all times',
  'M1860-4': 'Unable to ambulate, independent wheelchair',
  'M1860-5': 'Unable to wheelchair',
  'M1860-6': 'Bedfast',
  'M1860-7': '',
  'M1860-8': '',
  'M1860-9': '',
  'M1860-10': '',
  'M1860-88': '',
  'M1870-0': 'Independent',
  'M1870-1': 'Needs help with set up, intermittent assistance or liquid, pureed diet',
  'M1870-2': 'Requires supervision/assistance',
  'M1870-3': 'Takes nutrients orally and receives supplements through ng tube/gastrostomy',
  'M1870-4': 'Able to eat and receives supplement through NG or Gastronomy Tube',
  'M1870-5': 'Unable to take nutrients orally or by tube',
  'M1870-6': '',
  'M1870-7': '',
  'M1870-8': '',
  'M1870-9': '',
  'M1870-10': '',
  'M1870-88': '',
  'M2001-0': 'No Issues',
  'M2001-1': 'Yes Issues',
  'M2001-2': '',
  'M2001-3': '',
  'M2001-4': '',
  'M2001-5': '',
  'M2001-6': '',
  'M2001-7': '',
  'M2001-8': '',
  'M2001-9': '',
  'M2001-10': 'Not taking any meds',
  'M2001-88': '',
  'M2003-0': 'No',
  'M2003-1': 'Yes',
  'M2003-2': '',
  'M2003-3': '',
  'M2003-4': '',
  'M2003-5': '',
  'M2003-6': '',
  'M2003-7': '',
  'M2003-8': '',
  'M2003-9': '',
  'M2003-10': '',
  'M2003-88': '',
  'M2010-0': 'No',
  'M2010-1': 'Yes',
  'M2010-2': '',
  'M2010-3': '',
  'M2010-4': '',
  'M2010-5': '',
  'M2010-6': '',
  'M2010-7': '',
  'M2010-8': '',
  'M2010-9': '',
  'M2010-10': 'Not taking high risk drugs OR patient/caregiver fully knowledgeable',
  'M2010-88': '',
  'M2020-0': 'Independent',
  'M2020-1': 'Can take meds if help with prep and medication diary',
  'M2020-2': 'Can take at correct time if given reminders by another person',
  'M2020-3': 'Dependent -must be administered by another person',
  'M2020-4': '',
  'M2020-5': '',
  'M2020-6': '',
  'M2020-7': '',
  'M2020-8': '',
  'M2020-9': '',
  'M2020-10': '',
  'M2020-88': '',
  'M2102(4)-0': 'No assistance needed',
  'M2102(4)-1': 'Non-agency caregivers provide assistance',
  'M2102(4)-2': 'Non-agency caregivers need training/supportive services to provide assistance',
  'M2102(4)-3': 'Non-agency caregivers not likely to provide assistance',
  'M2102(4)-4': 'Assistance needed, but no non-agency caregivers available',
  'M2102(4)-5': '',
  'M2102(4)-6': '',
  'M2102(4)-7': '',
  'M2102(4)-8': '',
  'M2102(4)-9': '',
  'M2102(4)-10': '',
  'M2102(4)-88': '',
  'M1021(1)-0': '',
  'M1021(1)-1': '',
  'M1021(1)-2': 'symptoms affecting daily function',
  'M1021(1)-3': 'symptoms poorly controlled, requires frequent adjustment in treatment and dose',
  'M1021(1)-4': 'poorly controlled symptoms, history of re-hospitalizations',
  'M1021(1)-5': '',
  'M1021(1)-6': '',
  'M1021(1)-7': '',
  'M1021(1)-8': '',
  'M1021(1)-9': '',
  'M1021(1)-10': '',
  'M1021(1)-88': '',
  'M1023(1)-0': '',
  'M1023(1)-1': '',
  'M1023(1)-2': 'symptoms affecting daily function',
  'M1023(1)-3': 'symptoms poorly controlled, requires frequent adjustment in treatment and dose',
  'M1023(1)-4': 'poorly controlled symptoms, history of re-hospitalizations',
  'M1023(1)-5': '',
  'M1023(1)-6': '',
  'M1023(1)-7': '',
  'M1023(1)-8': '',
  'M1023(1)-9': '',
  'M1023(1)-10': '',
  'M1023(1)-88': '',
  'M1023(2)-0': '',
  'M1023(2)-1': '',
  'M1023(2)-2': 'symptoms affecting daily function',
  'M1023(2)-3': 'symptoms poorly controlled, requires frequent adjustment in treatment and dose',
  'M1023(2)-4': 'poorly controlled symptoms, history of re-hospitalizations',
  'M1023(2)-5': '',
  'M1023(2)-6': '',
  'M1023(2)-7': '',
  'M1023(2)-8': '',
  'M1023(2)-9': '',
  'M1023(2)-10': '',
  'M1023(2)-88': '',
  'M1023(3)-0': '',
  'M1023(3)-1': '',
  'M1023(3)-2': 'symptoms affecting daily function',
  'M1023(3)-3': 'symptoms poorly controlled, requires frequent adjustment in treatment and dose',
  'M1023(3)-4': 'poorly controlled symptoms, history of re-hospitalizations',
  'M1023(3)-5': '',
  'M1023(3)-6': '',
  'M1023(3)-7': '',
  'M1023(3)-8': '',
  'M1023(3)-9': '',
  'M1023(3)-10': '',
  'M1023(3)-88': '',
  'M1023(4)-0': '',
  'M1023(4)-1': '',
  'M1023(4)-2': '',
  'M1023(4)-3': 'symptoms affecting daily function',
  'M1023(4)-4': 'symptoms poorly controlled, requires frequent adjustment in treatment and dose',
  'M1023(4)-5': 'poorly controlled symptoms, history of re-hospitalizations',
  'M1023(4)-6': '',
  'M1023(4)-7': '',
  'M1023(4)-8': '',
  'M1023(4)-9': '',
  'M1023(4)-10': '',
  'M1023(4)-88': '',
  'M1023(5)-0': '',
  'M1023(5)-1': '',
  'M1023(5)-2': '',
  'M1023(5)-3': 'symptoms affecting daily function',
  'M1023(5)-4': 'symptoms poorly controlled, requires frequent adjustment in treatment and dose',
  'M1023(5)-5': 'poorly controlled symptoms, history of re-hospitalizations',
  'M1023(5)-6': '',
  'M1023(5)-7': '',
  'M1023(5)-8': '',
  'M1023(5)-9': '',
  'M1023(5)-10': '',
  'M1023(5)-88': '',
    // '1': 'Dependent: 100% Assist',
  // '2': 'Maximal: > 50% Assistance',
  // '3': 'Moderate: < 50% Assistance',
  // '4': 'Supervision: Contact Guard, Verbal Cues, Intermittent Assist',
  // '5': 'Setup or Clean-up Assist',
  // '6': 'Independent',
  // '7': 'Patient Refused',
  // '8': '',
  // '9': 'NA -Patient did not do activity prior',
  // '10': 'Not attempted due to environmental restrictions',
  // '11': '',
  // '12': '',
  // '13': '',
  // '14': '',
  // '15': '',
  // '16': '',
  // '17': '',
  // '18': '',
  // '19': '',
  // '20': '',
  // '88': 'Not attempted due to medical conditions/safety concerns',
}
