import { Component, OnInit } from '@angular/core';
import {CURRENT_RELEASE_VERSION, FOOTER_TEXT} from "../common/Constants";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-ioh-status',
  templateUrl: './ioh-status.component.html',
  styleUrls: ['./ioh-status.component.scss']
})
export class IohStatusComponent implements OnInit {
  footerText = FOOTER_TEXT;
  currentReleaseVersion = CURRENT_RELEASE_VERSION;
  statusDays = new Array(90);
  isServiceUp = false;
  constructor( private userService: UserService) { }

  ngOnInit(): void {
    this.getServiceStatus();
  }

  getServiceStatus() {
    this.userService.checkPatientService().subscribe(res => {
      this.isServiceUp = !!(res && res.message);
    });

    //return status;
  }

}
