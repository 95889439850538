import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {ServiceClientService} from "./service-client.service";
import {Notifications} from "../models/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private serviceClientService: ServiceClientService) { }

  createNotification(request: Notifications): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.createNotification, request);
  }

  getNotifications(request): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getNotification, request);
  }

  updateNotifications(request): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.updateNotifications, request);
  }
}
