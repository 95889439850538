import { Component, Input, OnInit } from '@angular/core';
import { SPINNER_ICON } from 'src/app/common/Constants';

@Component({
  selector: 'app-loader-wrapper',
  templateUrl: './loader-wrapper.component.html',
  styleUrls: ['./loader-wrapper.component.scss']
})
export class LoaderWrapperComponent implements OnInit {
   @Input() loading : boolean 
   spinnerIcon : string;
  constructor() {
    this.spinnerIcon = SPINNER_ICON
   }

  ngOnInit(): void {
  }

}
