import { NgModule } from "@angular/core";
import { SupportComponent } from "./support.component";
import { TableModule } from "primeng/table";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { SliderModule } from "primeng/slider";
import { DialogModule } from "primeng/dialog";
import { MultiSelectModule } from "primeng/multiselect";
import { ContextMenuModule } from "primeng/contextmenu";
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { InputTextModule } from "primeng/inputtext";
import { ProgressBarModule } from "primeng/progressbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { SidebarModule } from "primeng/sidebar";
import { PaginatorModule } from "primeng/paginator";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [SupportComponent],
  exports: [SupportComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    SidebarModule,
    PaginatorModule,
    SharedModule,
  ],
})
export class SupportModule {}
