import { ActionReducerMap } from '@ngrx/store';
import {agencyReducer, AgencyState, CompanyState} from "./agency.reducer";
import {companyReducer} from "./company.reducer";

export interface AppState {
  agencyState: AgencyState;
  companyState: CompanyState;
}

export const reducers: ActionReducerMap<AppState> = {
  agencyState: agencyReducer,
  companyState: companyReducer,
};
