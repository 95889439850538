import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { PatientService } from "src/app/services/patient/patient.service";
import { Patient, patientSubmissionDropdown, submissionStatus, SubmissionStatusColor } from "src/app/models/patient";
import { MessageHandlerService } from "../services/message-handler.service";
import { PatientSubmissions } from "../models/patientSubmissions";
import { Subscription } from "rxjs";
import { UserDataManager } from "../user/UserDataManager";
import { User } from "../models/user";
import {
  adminRoles,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  dateAndTimeFormat,
  dateFormat,
  dateFormatStartWithYear,
  DEBOUNCE_TIME_SET,
  DEFAULT_MRN_LENGTH,
  DEFAULT_PATIENT_ID_LENGTH,
  DEFAULT_SEARCH_MIN_CHARACTER,
  DOB_DATE_FORMAT,
  OnlyAdminAllowedRoles,
  OnlySuperAdminAllowedRoles,
  PHONE_NUMBER_REGEX,
  RoleID,
  rowsPerPageOptions,
  SEARCH_PATIENT_BY_CASE_MANAGER,
  SEARCH_PATIENT_BY_ID,
  SEARCH_PATIENT_BY_NAME_OR_MRN,
  superAdminRoles,
  DEFAULT_PATIENT_DATE_FILTER,
  NO_OF_RECORDS_PER_PAGE,
} from "../common/Constants";
import { DatePipe, Location } from "@angular/common";

import { Paginator } from "primeng/paginator";
import { AgencyService } from "../services/agency/agency.service";
import { countries } from "../models/country-data";
import { Dropdown } from "primeng/dropdown";
import { RoleEnum } from "src/app/models/agency";
import { NotificationService } from "../services/notification.service";
import { Notifications } from "../models/notification";
import { UtilService } from "../services/util/util.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "../services/local-storage.service";
import { swapLatitudeAndLongitude } from "../services/util/helper.function";
import { questionDescription } from "../helper/question.description";
import { reformatVisitsAnswersData, reformatWithSpecialCharacters } from "../helper/functions";
import { ConfirmationService, SelectItem } from "primeng/api";
import { visitDetailColors } from "src/app/models/patient";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

declare var google: any;

@Component({
  selector: "app-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.scss"],
})
export class PatientComponent implements OnInit, OnDestroy {
  display = false;
  displayPatient = false;
  isPatientEditForm = false;
  showPatientCreationSuccessPopup = false;
  eSockSubmissionForm: any;
  isValidPhoneNumber: boolean = true;
  isPreferredContactSelected: boolean = false;
  searchPatient: string = "";
  searchByCaseManager: string = "";
  patients = [];
  totalPatient: number = 0;
  selectedPatients: Patient[];
  selectRefreshTime: number = 30;
  patientSubmissionDropdown = patientSubmissionDropdown;
  selectPatientSubmissionOptions: string = patientSubmissionDropdown[1].value;
  selectedVisitDate: any = null;
  searchByVisitDate: any;
  searchByVisitDates: any = {};
  isMasterCheckboxChecked: boolean = false;
  subscriptions: Subscription[] = [];
  currentUser: User;
  pageNo: number = 0;
  noOfRecordPerRow: number = NO_OF_RECORDS_PER_PAGE;
  currentAgency: { agency: string; role: string };
  patientSubmission: PatientSubmissions | any;
  noOfSubmissionsPerRows: number = 5;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  submissionStatusColorEnum = SubmissionStatusColor;
  intervalId: any;
  filteredAgencies: any;
  originalFilteredAgenciesValues: any;
  selectedAgency: string[] = [];
  SUPER_ADMIN = RoleID.Admin;
  @ViewChild("paginator") paginator: Paginator;
  eSOCUploadDisplay: boolean = false;
  RoleID = RoleID;
  options: any;
  overlays: any;
  locationPopupDisplay: boolean = false;
  patientRowSelected: number;
  episodeRowSelected: number;
  patientSubmissionRowSelected: number;
  selectedVisitDates: string;
  selectedVisitType: string;
  selectedCaseManagerName: string[] = [];
  caseManagerFilter: SelectItem[] = [];
  originalCaseManagerFilter: SelectItem[] = [];
  @ViewChild("dropdown") dropdown: Dropdown;
  rangeDates: Date[];
  RoleEnum = RoleEnum;
  caseManagerFilterNames: any;
  createdByFilterNames: any;
  originalCreatedByFilterNames: any;
  selectedCaseManager: any = "All";
  selectedCreatedByUserName: any[] = [];
  defaultMrnLength = DEFAULT_MRN_LENGTH;
  defaultPatientIdLength = DEFAULT_PATIENT_ID_LENGTH;
  // showcasemanagerNameFilter: boolean = true;
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  allowAdminRoles: boolean = false;
  loading: boolean = false;
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  searchPatientByNameOrMrn = SEARCH_PATIENT_BY_NAME_OR_MRN;
  searchPatientById = SEARCH_PATIENT_BY_ID;
  searchByCaseManagerPlaceHolder = SEARCH_PATIENT_BY_CASE_MANAGER;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  dobDateFormat = DOB_DATE_FORMAT;
  roleID = RoleID;
  editPatientAgencyId;
  protected readonly countries = countries;
  visitDetailColors = visitDetailColors;
  sortOrder = -1;

  search = new Subject<string>();

  constructor(
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private confirmationService: ConfirmationService,
    private userDataManager: UserDataManager,
    private datePipe: DatePipe,
    private location: Location,
    private agencyService: AgencyService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    this.search.pipe(debounceTime(DEBOUNCE_TIME_SET), distinctUntilChanged()).subscribe((value) => {
      this.searchByPatientOrCaseManager(value);
    });
    this.setDefaultDtRange();
  }

  setDefaultDtRange() {
    let dd = new Date();
    dd.setDate(dd.getDate() - DEFAULT_PATIENT_DATE_FILTER);
    this.rangeDates = [dd, null];

    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };
  }

  ngOnInit(): void {
    this.getDataFromLocalStorage();
    this.getUserProfile();
    this.getCurrentAgencyRole();
    this.allAdminRoles();
    if (OnlySuperAdminAllowedRoles.includes(this.currentUser.currentUserRole)) {
      this.getAgencyListing();
    }

    // this.getPatientsWithCaseManagerNames();
    this.getPatientCreatedByNames();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onSelectCaseManagerFilter() {
    this.setDataInLocalStorage("selectedCaseManagerName", this.selectedCaseManagerName);
    this.getPatientWithSubmissions();
  }

  getCaseManagerList() {
    let currentAgency = this.localStorageService.get("currentAgency");
    let requestPayload = {
      agencyId: currentAgency ? currentAgency : {},
    };
    this.patientService.getCaseManagerList(requestPayload).subscribe((res) => {
      if (res?.success) {
        this.caseManagerFilter = res?.content?.distinctCaseManagers
          .filter((caseManager) => caseManager?.fullName != null && caseManager?.fullName.trim() !== "")
          .map((caseManager) => {
            return { label: caseManager?.fullName, value: caseManager?.firstName ? caseManager?.firstName : caseManager?.lastName };
          });

        this.utilService.sortSelectItemByLabel(this.caseManagerFilter);

        this.originalCaseManagerFilter = this.caseManagerFilter.map((obj) => Object.assign({}, obj));
      }
    });
  }

  getDataFromLocalStorage() {
    const patientState = JSON.parse(this.localStorageService.get("patientState"));
    this.searchPatient = patientState?.searchPatientById ? patientState?.searchPatientById : "";
    this.searchByCaseManager = patientState?.searchByCaseManager ? patientState?.searchByCaseManager : "";
    this.selectedVisitDate = patientState?.selectedVisitDate ? patientState?.selectedVisitDate : "";
    this.rangeDates = patientState?.rangeDates ? patientState?.rangeDates : null;
    this.selectedCreatedByUserName = patientState?.selectedCreatedByUserName ? patientState?.selectedCreatedByUserName : [];
    this.selectedAgency = patientState?.selectedAgency ? patientState?.selectedAgency : [];
    this.noOfRecordPerRow = patientState?.noOfRecordPerRow ? patientState?.noOfRecordPerRow : NO_OF_RECORDS_PER_PAGE;
    this.pageNo = patientState?.pageNo ? patientState?.pageNo : 0;
    this.selectedCaseManagerName = patientState?.selectedCaseManagerName ? patientState?.selectedCaseManagerName : [];

    // if (this.rangeDates && this.rangeDates.length && this.rangeDates[0] && patientState?.rangeDates) {
    //   let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    //   this.searchByVisitDates.startDate = this.utilService.convertToISOString(startDateFilter, true);
    //   this.rangeDates[0] = new Date(this.rangeDates[0]);
    // }

    // if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1] && patientState?.rangeDates) {
    //   let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);
    //   this.searchByVisitDates.endDate = this.utilService.convertToISOString(endDateFilter, false);
    //   this.rangeDates[1] = new Date(this.rangeDates[1]);
    // }

    // if (!patientState?.rangeDates) {
    this.rangeDates = [new Date(new Date().setDate(new Date().getDate() - DEFAULT_PATIENT_DATE_FILTER)), new Date()];
    // }
    this.prePopulateDate();
    this.prePopulatePerPage();
  }

  prePopulateDate() {
    const global = JSON.parse(this.localStorageService.get("globalDates"));
    if (global) {
      this.searchByVisitDates.startDate = this.utilService.convertToISOString(new Date(global.startDate), true);
      this.searchByVisitDates.endDate = this.utilService.convertToISOString(new Date(global.endDate), false);
      this.rangeDates = [new Date(global.startDate), new Date(global.endDate)];
    }
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get("globalPageLimit"));
    if (global) {
      this.noOfRecordPerRow = global.perPage;
    }
  }

  setDataInLocalStorage(key, value) {
    const patientState: any = JSON.parse(this.localStorageService.get("patientState"));
    if (patientState) {
      patientState[key] = value;
      this.localStorageService.set("patientState", JSON.stringify(patientState));
    } else {
      let patientNewState = {};
      patientNewState[key] = value;
      this.localStorageService.set("patientState", JSON.stringify(patientNewState));
    }
  }

  initializeOptions() {
    const mapCoordinates = swapLatitudeAndLongitude(
      this.patientSubmission.submissionData?.latitude,
      this.patientSubmission.submissionData?.longitude,
    );
    const latitude = mapCoordinates?.latitude;
    const longitude = mapCoordinates?.longitude;

    this.options = {
      center: {
        lat: latitude, //this.patientSubmission?.latitude,
        lng: longitude, //this.patientSubmission?.longitude,
      },
      zoom: 12,
    };
    this.overlays = [
      new google.maps.Marker({
        position: {
          lat: +this.patientSubmission?.latitude,
          lng: +this.patientSubmission?.longitude,
        },
        title: "Selected Submission",
      }),
    ];
  }
  showLocationDialog(visitDate: string, visitType: string) {
    this.selectedVisitDates = visitDate;
    this.selectedVisitType = visitType;
    this.initializeOptions();
    this.locationPopupDisplay = true;
  }

  changeSortOrder() {
    this.sortOrder = this.sortOrder == -1 ? 1 : -1;
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.getPatientWithSubmissions();
  }

  // get patient submission data
  getPatientWithSubmissions() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;
      let request = {
        pagination: {
          pageNumber: this.pageNo,
          pageSize: this.noOfRecordPerRow,
        },
        search: this.searchPatient,
        sortOrder: this.sortOrder,
        agencyId: superAdminRoles.includes(this.currentUser.currentUserRole) ? this.selectedAgency : this.currentAgency.agency,
        mrnOrId: "",
        searchByVisitDate: this.searchByVisitDate,
        searchByVisitDates: this.searchByVisitDates,
        //searchByCaseManagerName: this.selectedCaseManager,
        getPatientByCreator: this.selectedCreatedByUserName,
        caseManagerSearch: this.searchByCaseManager ? this.searchByCaseManager : this.selectedCaseManagerName,
      };
      this.patientService.getPatientSubmissions(request).subscribe((res) => {
        this.patients = [];
        if (res?.success) {
          this.patients = res.content.data.map((patient) => {
            const lastUpdate =
              patient?.submissions != null
                ? patient.submissions[patient.submissions?.length - 1]?.submissions[
                    patient.submissions[patient.submissions?.length - 1].submissions?.length - 1
                  ].submissionDate
                : "";
            const updatedSubmissions = patient?.submissions?.map((submission) => {
              const episodeCreateDate = new Date(submission.episodeCreatedAt);
              const currentDate = new Date();
              const timeDifferenceMs = currentDate.getTime() - episodeCreateDate.getTime();
              const numberOfDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
              const episodeName = submission.episodeName ? submission.episodeName.replace("Episode ", "Ep") : "";

              let totalErrorsAndWarnings = submission?.submissions.map((submission) => {
                return {
                  submissionErrorCount: submission.submissionErrorCount,
                  submissionWarningCount: submission.submissionWarningCount,
                };
              });

              // Initialize variables to hold the sums
              let totalEpisodeErrors = 0;
              let totalEpisodeWarnings = 0;

              // Use reduce to calculate the sums
              totalErrorsAndWarnings.forEach((item) => {
                totalEpisodeErrors += item.submissionErrorCount;
                totalEpisodeWarnings += item.submissionWarningCount;
              });
              return {
                ...submission,
                episodeName: episodeName,
                episodeCreatedAt: this.formatDateTime(submission?.episodeCreatedAt ? submission?.episodeCreatedAt : ""),
                numberOfDays: !isNaN(numberOfDays) ? numberOfDays : "",
                totalEpisodeErrors: totalEpisodeErrors,
                totalEpisodeWarnings: totalEpisodeWarnings,
              };
            });
            const patientFirstName = patient?.firstName ? patient?.firstName : "";
            const patientLastName = patient?.lastName ? patient?.lastName : "";
            const patientName = (patientFirstName ? patientFirstName : "") + " " + (patientLastName ? patientLastName : "");

            return {
              patient: {
                ...patient,
                episodes: updatedSubmissions,
                patientName: patientName,
                lastUpdate,
              },
              users: {
                ...patient?.users,
                createdAt: this.formatDateTime(patient?.users?.createdAt),
              },
            };
          });
          this.totalPatient = res?.content?.totalElements;
        } else if (res) {
          this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
        }
        this.loading = false;
        this.progressBarService.setProgressBarVisibility(false);
      });
    } catch (error) {
      this.loading = false;
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  formatDateTime(date: string): string {
    return this.utilService.formatDateTime(date);
  }

  searchByPatientOrCaseManager(searchParam: string) {
    this.setDataInLocalStorage("searchPatientById", this.searchPatient);
    this.setDataInLocalStorage("searchByCaseManager", this.searchByCaseManager);

    if (searchParam.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.getPatientWithSubmissions();
      this.resetPaginator();
    } else {
      this.isCaseManagerSearchEmpty();
    }
  }

  isSearchEmpty() {
    if (this.searchPatient === undefined || this.searchPatient === null || this.searchPatient === "") {
      this.pageNo = 0;
      this.getPatientWithSubmissions();
    }
  }

  isCaseManagerSearchEmpty() {
    if (this.searchByCaseManager === undefined || this.searchByCaseManager === null || this.searchByCaseManager === "") {
      this.pageNo = 0;
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.getPatientWithSubmissions();
      this.resetPaginator();
    }
  }

  getUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        if (res) {
          this.currentUser = res;
        }
      }),
    );
  }

  onSlideBarClose() {
    this.display = false;
    this.isMasterCheckboxChecked = false;
  }

  onPatientSlideBarClose() {
    this.displayPatient = false;
  }

  createPatientForm() {
    this.eSockSubmissionForm = {
      firstName: "",
      lastName: "",
      middleInitial: "",
      mrn: "",
      platform: "web",
      email: { email: "", preferred: false },
      phoneNumber: { countryCode: countries[0].dial_code, number: "", preferred: false },
      dob: "",
      consent: false,
    };
    this.isPatientEditForm = false;
    this.displayPatient = true;
  }

  createPatient(update = false) {
    this.eSockSubmissionForm.contactInfo = {
      email: this.eSockSubmissionForm?.email?.email ? this.eSockSubmissionForm?.email?.email : "",
      phone: this.eSockSubmissionForm?.phoneNumber?.number
        ? this.eSockSubmissionForm?.phoneNumber?.countryCode + "" + this.eSockSubmissionForm?.phoneNumber?.number
        : "",
      preferredOption:
        this.eSockSubmissionForm?.email?.preferred && this.eSockSubmissionForm?.phoneNumber?.preferred
          ? "both"
          : this.eSockSubmissionForm?.email?.preferred
            ? "email"
            : this.eSockSubmissionForm?.phoneNumber?.preferred
              ? "phone"
              : "",
      consent: this.eSockSubmissionForm?.consent,
    };
    this.eSockSubmissionForm.dob = this.formatDate(this.eSockSubmissionForm.dob);
    this.patientService.createPatient(this.eSockSubmissionForm, update ? this.editPatientAgencyId : this.currentAgency?.agency).subscribe((res) => {
      if (res && res.firstName) {
        this.getPatientWithSubmissions();
        this.messageHandlerService.generateToastMessage("success", "", `Patient ${update ? "updated" : "created"} successfully`);
        this.displayPatient = false;
      } else {
        this.messageHandlerService.generateToastMessage("error", "", res);
      }
    });
  }

  formatDate(patientDob) {
    let dobDate = this.utilService.toLocal(patientDob);
    return this.datePipe.transform(dobDate, "MM/dd/yyyy");
  }

  patientDeleteConfirmation() {
    this.confirmationService.confirm({
      header: "Delete Patient?",
      message: "Please confirm if you want to delete this patient.",
      acceptButtonStyleClass: "btn-delete",
      accept: () => {
        this.onPatientDelete();
      },
    });
  }

  patientUpdateConfirmation() {
    this.confirmationService.confirm({
      header: "Confirm Changes?",
      message: "Please confirm if you want to save all the changes that you've made to this profile.",
      icon: "pi pi-cog",
      accept: () => {
        this.createPatient(true);
      },
    });
  }

  onPatientDelete() {
    const req = {
      patientIds: [this.eSockSubmissionForm.patientId],
      platform: "web",
    };
    this.patientService.deletePatient(req).subscribe((res) => {
      if (res && res.success) {
        this.getPatientWithSubmissions();
        this.messageHandlerService.generateToastMessage("success", "", "Patient deleted successfully");
      } else {
        this.messageHandlerService.generateToastMessage("error", "", "Error during patient deletion");
      }
      this.displayPatient = false;
    });
  }

  onEditPatient(index) {
    this.editPatientAgencyId = this.patients[index]?.patient?.agencyId;
    const countryCode = this.patients[index]?.patient?.contactInfo?.phone
      ? this.patients[index]?.patient?.contactInfo?.phone.substring(0, this.patients[index]?.patient?.contactInfo?.phone.length - 10)
      : countries[0].dial_code;
    const phoneNumber = this.patients[index]?.patient?.contactInfo?.phone
      ? this.patients[index]?.patient?.contactInfo?.phone.slice(this.patients[index]?.patient?.contactInfo?.phone.length - 10)
      : "";
    this.eSockSubmissionForm = {
      firstName: this.patients[index]?.patient?.firstName ? this.patients[index]?.patient?.firstName : "",
      lastName: this.patients[index]?.patient?.lastName ? this.patients[index]?.patient?.lastName : "",
      middleInitial: this.patients[index]?.patient?.middleInitial ? this.patients[index]?.patient?.middleInitial : "",
      mrn: this.patients[index]?.patient?.mrn ? this.patients[index]?.patient?.mrn : "",
      platform: "web",
      email: {
        email: this.patients[index]?.patient?.contactInfo?.email ? this.patients[index]?.patient?.contactInfo?.email : "",
        preferred:
          this.patients[index]?.patient?.contactInfo?.preferredOption == "email" ||
          this.patients[index]?.patient?.contactInfo?.preferredOption == "both",
      },
      phoneNumber: {
        countryCode: countryCode,
        number: phoneNumber,
        preferred:
          this.patients[index]?.patient?.contactInfo?.preferredOption == "phone" ||
          this.patients[index]?.patient?.contactInfo?.preferredOption == "both",
      },
      dob: this.patients[index]?.patient?.dob ? this.patients[index]?.patient?.dob : "",
      consent: this.patients[index]?.patient?.contactInfo?.consent ? this.patients[index]?.patient?.contactInfo?.consent : false,
      patientId: this.patients[index]?.patient?._id,
    };
    this.isPatientEditForm = true;
    this.displayPatient = true;
  }

  isPhoneNumberValid(event?) {
    if (this.eSockSubmissionForm?.phoneNumber?.preferred) {
      if (this.eSockSubmissionForm?.phoneNumber?.number?.length > 0) {
        let phone = event ? event : this.eSockSubmissionForm?.phoneNumber?.number;
        if (phone?.includes("-")) {
          phone = phone?.split("-").join("");
        }
        this.isValidPhoneNumber = new RegExp(PHONE_NUMBER_REGEX).test(phone);
      } else {
        this.isValidPhoneNumber = false;
      }
    } else {
      this.isValidPhoneNumber = true;
    }
    return this.isValidPhoneNumber;
  }

  changeCheckboxValue() {
    if (this.eSockSubmissionForm?.email?.preferred && this.eSockSubmissionForm?.phoneNumber?.preferred) {
      this.isPreferredContactSelected = true;
    } else if (!this.eSockSubmissionForm?.email?.preferred && !this.eSockSubmissionForm?.phoneNumber?.preferred) {
      this.isPreferredContactSelected = false;
    } else if (!this.eSockSubmissionForm?.email?.preferred && this.eSockSubmissionForm?.phoneNumber?.preferred) {
      this.isPreferredContactSelected = true;
    } else if (this.eSockSubmissionForm?.email?.preferred && !this.eSockSubmissionForm?.phoneNumber?.preferred) {
      this.isPreferredContactSelected = false;
    }
    this.isPhoneNumberValid();
  }

  isEmailMandatory() {
    return this.eSockSubmissionForm?.email?.preferred;
  }

  openeSOCUploadPopup() {
    this.eSOCUploadDisplay = true;
    this.display = false;
  }

  eSOCUploadPopupClose() {
    this.eSOCUploadDisplay = false;
    this.display = false;
  }

  refreshListing() {
    this.getPatientWithSubmissions();
  }

  openPatientSubmissionDetails(
    patientSubmissionDetails?: any,
    dob?: string,
    mrn?: string,
    firstName?: string,
    lastName?: string,
    totalErrorAndWarnings?: number,
    picture?: string,
    rowIndex?: number,
    contactInfo?: any,
    patientId?: string,
    icdCodes?: any,
  ) {
    this.display = true;
    this.patientSubmission = patientSubmissionDetails;
    if (!Array.isArray(patientSubmissionDetails?.questionnaire)) {
      this.patientSubmission.questionnaire = this.utilService.extractQuestionAndAnswer(patientSubmissionDetails?.questionnaire);
    } else {
      this.patientSubmission.questionnaire = patientSubmissionDetails?.questionnaire;
    }
    this.patientSubmission["firstName"] = firstName ? firstName : patientSubmissionDetails?.patient?.firstName;
    this.patientSubmission["lastName"] = lastName ? lastName : patientSubmissionDetails?.patient?.lastName;
    const patientFirstName = firstName ? firstName : patientSubmissionDetails?.patient?.firstName;
    const patientLastName = lastName ? lastName : patientSubmissionDetails?.patient?.lastName;
    this.patientSubmission["patientName"] = (patientFirstName ? patientFirstName : "") + " " + (patientLastName ? patientLastName : "");
    this.patientSubmission["mrn"] = mrn ? mrn : patientSubmissionDetails?.patient.mrn;
    this.patientSubmission["dob"] = dob ? dob : patientSubmissionDetails?.patient?.dob;
    this.patientSubmission["totalErrorAndWarnings"] = this.patientSubmission.submissionErrorCount + this.patientSubmission.submissionWarningCount;
    this.patientSubmission["picture"] = picture;
    this.patientSubmission["contactInfo"] = contactInfo;
    this.patientSubmission["patientId"] = patientId;
    this.patientSubmission["icdCodes"] = icdCodes;
    this.patientSubmission["issues"].forEach((issue) => {
      //return issue['message'] = issue?.message?.replace(/\n/g, '<br/>');
      return (issue["message"] = reformatWithSpecialCharacters(issue?.message));
    });
    this.patientSubmission["issues"].forEach((issue) => {
      return (issue["questionDescription"] = questionDescription[issue?.question]);
    });

    reformatVisitsAnswersData(this.patientSubmission["issues"]);
    this.patientSubmissionRowSelected = rowIndex;
  }

  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }

  onPatientSubmissionDropdownChange() {
    this.pageNo = 0;
    this.noOfRecordPerRow = 10;
    this.searchPatient = "";
    this.selectedVisitDate = null;
    this.rangeDates = null;
    this.searchByVisitDates = null;
    if (this.selectPatientSubmissionOptions === this.patientSubmissionDropdown[1].value) {
      this.getPatientWithSubmissions();
    }
  }

  routeTo(e) {
    this.router.navigate(["/" + e]);
  }

  selectVisitDate() {
    this.pageNo = 0;
    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };

    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.getPatientWithSubmissions();
    this.resetPaginator();

    this.utilService.setDataInLocalStorage("globalDates", "startDate", this.rangeDates[0]);
    this.utilService.setDataInLocalStorage("globalDates", "endDate", this.rangeDates[1]);
  }

  getCurrentAgencyRole() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
        this.getCaseManagerList();
        this.currentAgency = currentRole;
        if (this.currentAgency.role == RoleID.Admin) {
          this.currentAgency.agency = "";
        }
        if (this.selectPatientSubmissionOptions === this.patientSubmissionDropdown[1].value) {
          this.getPatientWithSubmissions();
        }
      }),
    );
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage("globalPageLimit", "perPage", event.rows);
      this.pageNo = event.page;
      this.noOfRecordPerRow = event.rows;
      this.setDataInLocalStorage("noOfRecordPerRow", this.noOfRecordPerRow);
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.getPatientWithSubmissions();
    }
  }

  updateStatus(action, issueIndex) {
    if (
      issueIndex == -1 ||
      (this.patientSubmission?.issues && this.patientSubmission?.issues.length && this.patientSubmission?.issues[issueIndex]?.status) ||
      (this.patientSubmission?.submissionData?.issues[issueIndex]?.status &&
        this.patientSubmission?.submissionData?.issues &&
        this.patientSubmission?.submissionData?.issues.length)
    )
      try {
        this.progressBarService.setProgressBarVisibility(true);
        if (action === "ACCEPT") {
          this.patientSubmission.issues
            ? (this.patientSubmission.issues[issueIndex].status = "reviewed")
            : (this.patientSubmission.submissionData.issues[issueIndex].status = "reviewed");
        } else if (action === "RECHECK") {
          this.patientSubmission.issues
            ? (this.patientSubmission.issues[issueIndex].status = "recheck")
            : (this.patientSubmission.submissionData.issues[issueIndex].status = "recheck");
        } else if (action === "ACCEPT ALL") {
          this.patientSubmission.issues
            ? this.patientSubmission.issues.forEach((issue) => (issue.status = "reviewed"))
            : this.patientSubmission.submissionData.issues.forEach((issue) => (issue.status = "reviewed"));
        } else if (action === "RECHECK ALL") {
          this.patientSubmission.issues
            ? this.patientSubmission.issues.forEach((issue) => (issue.status = "recheck"))
            : this.patientSubmission.submissionData.issues.forEach((issue) => (issue.status = "recheck"));
        }
        this.patientService.updateStatus(this.patientSubmission).subscribe((res) => {
          if (res?.success) {
            this.messageHandlerService.generateToastMessage("success", "", res.message);
            this.getPatientWithSubmissions();
            if (this.patientSubmission.issues[issueIndex].status === "recheck" && this.currentUser._id !== this.patientSubmission.userId)
              this.createNotification(issueIndex);
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
        });
      } catch (error) {
        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
      }
  }

  statusColor(status) {
    if (status?.includes("_")) {
      return status?.replace(/_/g, "");
    } else {
      return status?.replace(/-/g, "");
    }
  }

  getLabelByValue(value: string): string {
    const status = submissionStatus?.find((status) => status?.value === value);
    return status ? status.label : "";
  }

  masterChangeCheckbox(event) {
    this.isMasterCheckboxChecked = event.checked;
  }

  getAgencyListing() {
    this.agencyService.getAgencies().subscribe((res) => {
      if (res.success) {
        const agencies = res.content.data;
        const mappedAgencies = agencies.map(({ name, id }) => ({
          label: name,
          value: id,
        }));
        this.filteredAgencies = mappedAgencies;
        this.utilService.sortSelectItemByLabel(this.filteredAgencies);

        this.originalFilteredAgenciesValues = this.filteredAgencies.map((obj) => Object.assign({}, obj));
      }
    });
  }

  onSelectAgency() {
    this.pageNo = 0;
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedAgency", this.selectedAgency);
    this.getPatientWithSubmissions();
    this.resetPaginator();
  }

  onCalendarClear() {
    this.pageNo = 0;
    this.selectedVisitDate = null;
    this.rangeDates = null;
    this.searchByVisitDates = null;

    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedVisitDate", this.selectedVisitDate);
    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.getPatientWithSubmissions();
    this.resetPaginator();

    this.localStorageService.remove("globalDates");
  }

  onPatientRowClick(rowIndex: number) {
    this.patientRowSelected = rowIndex;
  }

  onEpisodeRowClick(rowIndex: number) {
    this.episodeRowSelected = rowIndex;
  }

  getAlertType(input) {
    input = input.toLowerCase();
    if (["warning", "warn", "error"].includes(input) == true) {
      if (input == "error") {
        return "Error";
      }
      return "Warning";
    }
    return "Ok";
  }

  adminRoles() {
    return adminRoles.includes(this.currentUser.currentUserRole);
  }

  // onSelectedCaseManagerName() {
  //   this.pageNo = 0;
  //   this.noOfRecordPerRow = 10;
  //   this.getPatientWithSubmissions();
  //   this.resetPaginator();
  // }

  resetPaginator() {
    this.paginator.changePageToFirst(event);
  }

  // getPatientsWithCaseManagerNames() {
  //   this.patientService.getPatientsWithCaseManagerNames().subscribe((res) => {
  //     const caseManagerSet = new Set();
  //     if (res.content && res.success) {
  //       this.showcasemanagerNameFilter =
  //         res?.content?.patients[0]?.names?.length &&
  //         res?.content?.patients[0]?.names?.length !== 0;

  //       const allOption = { label: 'All', value: 'All' };
  //       res?.content?.patients[0]?.names.forEach((obj) => {
  //         const fullName = {
  //           firstName: obj?.CaseManagerFirstName,
  //           lastName: obj?.CaseManagerLastName,
  //         };
  //         const fullNameStr = JSON.stringify(fullName);
  //         if (obj?.CaseManagerFirstName || obj?.CaseManagerLastName) {
  //           if (!caseManagerSet.has(fullNameStr)) {
  //             caseManagerSet.add(fullNameStr);
  //           }
  //         }
  //       });

  //       const caseManagerNames = Array.from(caseManagerSet).map(
  //         (fullNameStr: any) => {
  //           const fullName = JSON.parse(fullNameStr);
  //           return {
  //             label: `${fullName.firstName} ${fullName.lastName}`,
  //             value: {
  //               firstName: fullName.firstName,
  //               lastName: fullName.lastName,
  //             },
  //           };
  //         }
  //       );
  //       this.caseManagerFilterNames = [allOption, ...caseManagerNames];
  //     }
  //   });
  // }

  createNotification(issueIndex: number) {
    const question = this.patientSubmission?.issues[issueIndex]?.questionNo
      ? this.patientSubmission?.issues[issueIndex]?.questionNo
      : this.patientSubmission?.issues[issueIndex]?.question;
    let message = "Please recheck " + question + " for visit " + this.patientSubmission?.issues[issueIndex]?.type;
    const notification: Notifications = {
      creator: this.currentUser?._id,
      receiver: this.patientSubmission.userId,
      message: message,
      createdAt: new Date(),
      read: false,
    };
    this.notificationService.createNotification(notification).subscribe((res) => {
      if (!res.success) {
        this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
      }
    });
  }

  getPatientCreatedByNames() {
    let options;
    this.patientService.getPatientCreatedByNames().subscribe(
      (res) => {
        if (res?.success && res?.content) {
          options = res?.content?.clinicianList?.map((obj) => {
            return { label: `${obj?.userList?.firstName} ${obj?.userList?.lastName}`, value: obj?.userList?.id };
          });

          this.createdByFilterNames = options;
          this.utilService.sortSelectItemByLabel(this.createdByFilterNames);

          this.originalCreatedByFilterNames = this.createdByFilterNames.map((obj) => Object.assign({}, obj));
        }
      },
      (err) => {
        console.log("error in getting created by names: ", err);
      },
    );
  }

  onSelectedCreatedByUserName() {
    this.pageNo = 0;
    this.noOfRecordPerRow = 10;
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedCreatedByUserName", this.selectedCreatedByUserName);
    this.getPatientWithSubmissions();
    this.resetPaginator();
  }

  allAdminRoles() {
    this.allowAdminRoles = OnlyAdminAllowedRoles.includes(this.currentUser?.currentUserRole);
  }

  clearSelectedValues(selectedValues: any[]) {
    if (selectedValues && selectedValues.length) {
      selectedValues.splice(0, selectedValues.length);
      this.setMultiSelectValuesInLocalStorage();
      this.getPatientWithSubmissions();
    }
  }

  setMultiSelectValuesInLocalStorage() {
    this.setDataInLocalStorage("selectedCaseManagerName", this.selectedCaseManagerName);
    this.setDataInLocalStorage("selectedCreatedByUserName", this.selectedCreatedByUserName);
    this.setDataInLocalStorage("selectedAgency", this.selectedAgency);
  }

  sortSelectedValues() {
    this.caseManagerFilter = this.utilService.sortSelectedValuesOfMultiSelect(this.originalCaseManagerFilter, this.selectedCaseManagerName);
    this.createdByFilterNames = this.utilService.sortSelectedValuesOfMultiSelect(this.originalCreatedByFilterNames, this.selectedCreatedByUserName);
    this.filteredAgencies = this.utilService.sortSelectedValuesOfMultiSelect(this.originalFilteredAgenciesValues, this.selectedAgency);
  }
}
