import { EnvironmentModel, IOHURLS } from './environment.model';
import { Endpoints } from './endpoints';

export const IOH: IOHURLS = {
  // userServiceBaseURL: 'http://localhost:3000',
  // patientServiceBaseURL: 'http://localhost:3001',
  userServiceBaseURL: 'http://portal.dev.iohealthtech.com',
  patientServiceBaseURL: 'http://portal.dev.iohealthtech.com',
  rulesServiceBaseUrl: 'http://portal.dev.iohealthtech.com',
};

const endpoints = new Endpoints();
export const environment: EnvironmentModel = {
  production: false,
  cognito: {
    userPoolId: 'us-east-1_qmQdTL7VB',
    userPoolWebClientId: '1vldcbffsofvpin6jv89c67lgl',
    region: 'us-east-1',
  },
  endPoints: { ...endpoints.getGenericEndpoints() },
  awsCredentials: {
    region: 'us-east-2',
    signatureVersion: 'v4',
  },
};
