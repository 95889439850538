import {Pipe} from "@angular/core";

@Pipe({
  name: 'replaceStringInHtml'
})
export class ReplaceStringInHtmlPipe {
  transform(value: string, replaceString: string, replaceWith: string): string {
    if (value) {
      return value.replace(new RegExp(replaceString, 'g'), replaceWith);
    } else {
      return null;
    }
  }
}
