import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProgressBarComponent} from "../progress-bar/progress-bar.component";
import {ProgressBarModule} from "primeng/progressbar";
import {ProgressBarService} from "../../services/progress-bar.service";


@NgModule({
  declarations: [
    ProgressBarComponent,
  ],
  exports: [
    ProgressBarComponent,
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
  ],
  providers:[
    ProgressBarService
  ]
})
export class ProgressBarModuleLib {
}
