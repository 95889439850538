import { Pipe, PipeTransform } from "@angular/core";
import { isArray } from "lodash-es";
import { getAnswerLabel } from "../../services/util/helper.function";

@Pipe({
  name: "replaceNegativeOne",
})
export class ReplaceNegativeOnePipe implements PipeTransform {
  transform(answers) {
    if (answers) {
      if (!isArray(answers)) {
        answers = answers?.split(",");
      }
      return answers.map((obj) => getAnswerLabel(obj)); // old code return answers.map(obj => obj == -1 ? 'Not Assessed' : obj)
    } else {
      return [];
    }
  }
}
