export interface EsocPatient {
  id?: string;
  firstName: string;
  submittedOn: string;
  submittedBy: string;
  consent: string;
  sentVia: string;
  status: string;
  totalSoc: string;
  picture: string;
  email: string;
  submissions: EsocPatientSubmission;
}

export interface EsocPatientSubmission {
  file: string;
}

export interface eSocPatientSubmissions {
  _id?: string;
  mrn: string;
  patient: patient;
  zgroup: string[];
  visitId: string;
  quizData: {
    question: string;
    answer: string;
  }[];
  issues: {
    questionNo: string;
    message: string;
    answer: string;
    timeStamp: string;
    byPass: boolean;
    type: string;
    status: string;
    reason: string;
  }[];
  visitType: string;
  visitDate: string;
  submissionDate: string;
  status: string;
  eSocket: string;
  updatedAt: string;
  updatedBy: string;
  errorCount: number;
  warningCount: number;
  patientData: {
    firstName: string;
    middleInitial: string;
    lastName: string;
    picture: string;
  };
  isPatientInAgency: any;
  eSock?: eSoc;
  submissions?: {
    file?: string;
  };
}

export interface patient {
  contactInfo: {
    email: string;
    phone: string;
    prefferedOption: string;
  };
  agencyId: string;
  dob: string;
}

export interface eSoc {
  file: { fileName: string; fileLocation: string };
  submittedOn: Date;
  status: string;
  submittedBy: string;
  consent: boolean;
}

export enum EsocSubmissionStatusColor {
  APPROVED = '#10B981',
  UNAPPROVED = '#FF3C3C',
}

export enum EsocSubmissionStatusValues {
  APPROVED = 'Approved',
  UNAPPROVED = 'Unapproved',
}

export const eSocStatus = [
  { label: 'All', value: 'ALL' },
  { label: 'Not Sent', value: 'not-sent' },
  { label: 'Sent', value: 'sent' },
];
export const consentStatus = [
  { label: 'All', value: 'ALL' },
  { label: 'Opt In', value: true },
  { label: 'Opt Out', value: false },
];

export const eDocComplete = [
  { label: 'All', value: 'ALL' },
  { label: 'Complete', value: true },
  { label: 'Incomplete', value: false },
  { label: 'ePH', value: 'ePH' },
  { label: 'PIReport', value: 'PIReport' },
];
