import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";
import {dateAndTimeFormat, US_TIME_ZONE} from "../Constants";

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  dateAndTimeFormat = dateAndTimeFormat;

  transform(value: any, format:string = dateAndTimeFormat,timezone:string = US_TIME_ZONE): any {
    if (value) {
      const datePipe = new DatePipe('en-US');
      return datePipe.transform(value, format,timezone);
    }else {
      return null;
    }
  }

}
