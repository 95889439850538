import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderWrapperComponent } from './loader-wrapper/loader-wrapper.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';



@NgModule({
  declarations: [
    LoaderWrapperComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule
  ],
  exports : [
    LoaderWrapperComponent
  ]
})
export class LoaderWrapperModule { }
