import {Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from './auth.service';
import {UserDataManager} from '../user/UserDataManager';
import {EDOCS, LOGIN_PATH, RoleID, SUBMISSION_PATH} from '../common/Constants';

@Injectable({
  providedIn: 'root'
})
export class AuthChildrenGuard  implements OnDestroy {
  public busy = false;
  subscription: Subscription;
  navigationPath;

  constructor(protected auth: AuthService,
    protected userDataManager: UserDataManager,
    protected router: Router)
  {

  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.auth.authenticated) return this.navigateTo(LOGIN_PATH);
    this.busy = true;

    if (!next.data || !next.data.roles) {
      return true;
    }

    return this.canNavigate(next).then(response => {
      if (!response) {
        this.navigateTo(SUBMISSION_PATH);
        return false;
      }
      return response;
    });
  }

  canNavigate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      this.userDataManager.user$.subscribe(user => {
        this.busy = false;
        if (!user) {
          resolve(false);
        }
        const hasAllowedRole = next.data.roles.includes(user.currentUserRole);
        if (hasAllowedRole) {
          resolve(true)
        } else {
          this.navigateAccordingToRole(next.routeConfig.path, user.currentUserRole);
          resolve(false);
        }

        resolve(false);
      });
    });
  }

  navigateAccordingToRole(path: string, currentUserRole): any {
    path = '/' + path;
    if(currentUserRole === RoleID.Clinician && path === EDOCS) {
      this.navigationPath = SUBMISSION_PATH;
    }
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  protected navigateTo(path: string, message:string = '') {
    if (message)
    {
      this.router.navigate([path], {queryParams: {message: message}});
    }
    else
    {
      this.router.navigate([path]);
    }

    this.busy = false;
    return false;
  }
}
