import { IOH } from './environment';

export class Endpoints {
  getGenericEndpoints() {
    return {
      getUsers: IOH.userServiceBaseURL + '/user/pagination',
      userActive: IOH.userServiceBaseURL + '/user/active',
      findUserByEmail: IOH.userServiceBaseURL + '/user/email',
      emailExists: IOH.userServiceBaseURL + '/user/email',
      getUserStatusExportData: IOH.userServiceBaseURL + '/report/deletedUsersReport',
      temporaryPassword: IOH.userServiceBaseURL + '/user/reset-password',
      uploadFile: IOH.userServiceBaseURL + '/user/upload-file',
      bulkUpload: IOH.userServiceBaseURL + '/user/bulk-upload',
      uploadTemplate: IOH.userServiceBaseURL + '/user/upload-template',
      updateUser: IOH.userServiceBaseURL + '/user/update',
      updateUserRegistrationStatus: IOH.userServiceBaseURL + '/user/update-registration-status',
      deleteUser: IOH.userServiceBaseURL + '/auth/delete',
      deleteFile: IOH.userServiceBaseURL + '/user/delete-file',
      searchUsers: IOH.userServiceBaseURL + '/user/search',
      profile: IOH.userServiceBaseURL + '/user/profile',
      updatePermissionsEmail: IOH.userServiceBaseURL + '/user/update-permissions-email',
      getAgencies: IOH.userServiceBaseURL + '/agency',
      checkIsEdocEnabled: IOH.userServiceBaseURL + '/agency/isEDocEnabled',
      getAgenciesWithSearch: IOH.userServiceBaseURL + '/agency/getAgencies',
      createUser: IOH.userServiceBaseURL + '/auth/register',
      getUserRole: IOH.userServiceBaseURL + '/user/role',
      createAgency: IOH.userServiceBaseURL + '/agency',
      getAgenciesByRole: IOH.userServiceBaseURL + '/agency/by-role',
      getUsersWithSearch: IOH.userServiceBaseURL + '/user/getUsers',
      getEsocPatientsWithSearch: IOH.userServiceBaseURL + '/esoc-patient/patients',
      getEsocPatientsSubmissions: IOH.userServiceBaseURL + 'esoc-patient/submissions',
      resendEsocEmail: IOH.userServiceBaseURL + '/patient/send-esoc-email',
      processEsocRequest: IOH.patientServiceBaseURL + '/patient_esoc/processEsocRequest',
      processEsocResendRequest: IOH.userServiceBaseURL + '/patient/process-esoc-resend-request',
      resendEsocSms: IOH.userServiceBaseURL + '/patient/send-esoc-sms',
      esocVerification: IOH.userServiceBaseURL + '/esoc-verification/',
      deleteAgency: IOH.userServiceBaseURL + '/agency/',
      findAgencyByEmail: IOH.userServiceBaseURL + '/agency/email',
      updateAgency: IOH.userServiceBaseURL + '/agency/update',
      getPatientSubmissions: IOH.patientServiceBaseURL + '/patient/patientSubmissions',
      getSubmissionByPatient: IOH.patientServiceBaseURL + '/patient_visit/getSubmissionByPatients',
      getAllVisits: IOH.patientServiceBaseURL + '/patient_visit/getAllVisits',
      supportTicket: IOH.userServiceBaseURL + '/user/support',
      updateSupport: IOH.userServiceBaseURL + '/user/support/update',
      support: IOH.userServiceBaseURL + '/user/support/all',
      uploadeSocFile: IOH.patientServiceBaseURL + '/patient_esoc/uploadFile',
      updatePatientSubmissionEsoc: IOH.patientServiceBaseURL + '/patient_esoc/updatePatienteSoc',
      updateStatus: IOH.patientServiceBaseURL + '/patient_visit/',
      eSocPatientSubmissions: IOH.patientServiceBaseURL + '/patient/eSoc-patient-submissions',
      changeConsent: IOH.patientServiceBaseURL + '/patient/changeConsent',
      getUserEsocPatientSubmissions: IOH.userServiceBaseURL + '/user/getUserEsocPatientSubmissions',
      deleteEsockFile: IOH.patientServiceBaseURL + '/patient_visit/delete-eSock-file',
      createNotification: IOH.patientServiceBaseURL + '/patient/push-notification',
      getNotification: IOH.patientServiceBaseURL + '/patient/push-notification/getNotifications',
      updateNotifications: IOH.patientServiceBaseURL + '/patient/push-notification/updateNotifications',
      getPatientsWithCaseManagerName: IOH.patientServiceBaseURL + '/patient/getCaseManagerNames',
      fin: IOH.rulesServiceBaseUrl + '/engine/api/fin/v1/process',
      getSubmissionWithOutPatients: IOH.patientServiceBaseURL + '/patient_visit/getSubmissionWithOutPatients',
      getPatientCreatedByNames: IOH.patientServiceBaseURL + '/patient/getPatientCreatedByNames',
      getAgencyInformation: IOH.userServiceBaseURL + '/agency/agency-information',
      getPatientEsocListing: IOH.patientServiceBaseURL + '/patient_esoc/patientEsocListing',
      getPatientEsocById: IOH.patientServiceBaseURL + '/patient_esoc/getPatientEsocById',
      deleteESocFile: IOH.patientServiceBaseURL + '/patient_esoc/deleteESocFile',
      processEphResendRequest: IOH.patientServiceBaseURL + '/patient_esoc/process-eph-resend-request',
      getEPHfileObjectUrl: IOH.patientServiceBaseURL + '/patient_esoc/get-eph-file-url',
      reProcessSubmission: IOH.patientServiceBaseURL + '/patient_visit/reprocessSubmission',
      patientESOCExportData: IOH.patientServiceBaseURL + '/patient_esoc/patientESOCExportData',
      getCompanies: IOH.userServiceBaseURL + '/company/getCompanies',
      createCompany: IOH.userServiceBaseURL + '/company',
      updateCompany: IOH.userServiceBaseURL + '/company',
      findCompanyByEmailOrId: IOH.userServiceBaseURL + '/company/findBy',
      deleteCompany: IOH.userServiceBaseURL + '/company',
      getAgencyErrorsAndWarningsExportData: IOH.patientServiceBaseURL + '/patient/reports/agency_report',
      getCompanyErrorsAndWarningsExportData: IOH.patientServiceBaseURL + '/patient/reports/company-utilization-stats',
      getCompanyUtilizationExportData: IOH.patientServiceBaseURL + '/patient/reports/company-report',
      getIohCompanyUsersExportData: IOH.userServiceBaseURL + '/report/ioh-company-users',
      createPatient: IOH.patientServiceBaseURL + '/patient/',
      deletePatient: IOH.patientServiceBaseURL + '/patient/deletePatient',
      generateIOHUserReport: IOH.userServiceBaseURL + '/report/ioh-users',
      heartbeat: IOH.patientServiceBaseURL + '/patient/heartbeat',
      getClinicianList: IOH.patientServiceBaseURL + '/patient_visit/getClinicianList',
      getCaseManagerList: IOH.patientServiceBaseURL + '/patient/getCaseManagerList',
      readCSVFile: IOH.patientServiceBaseURL + '/file-parse/readCSVFile',
      releaseVersion: IOH.userServiceBaseURL + '/user/release-version',
      forceResetPassword: IOH.userServiceBaseURL + '/auth/force-change-password',
      updateEsocFileStatus: IOH.patientServiceBaseURL + '/patient_esoc/updateEsocFileStatus',
      ssoMicrosoftLogin: IOH.userServiceBaseURL + '/auth/sso-authorize',
      ssoMicrosoftLogout: IOH.userServiceBaseURL + '/auth/sso-logout',
      checkFileValidity: IOH.patientServiceBaseURL + '/patient_esoc/file-validity',
      checkEDocLinkValidity: IOH.patientServiceBaseURL + '/patient_esoc/check-eDoc-link',
      clinicianChangeReport: IOH.patientServiceBaseURL + '/patient/reports/clinician-change-report',
      clinicianSuggestionCalReport: IOH.patientServiceBaseURL + '/patient/reports/clinician-suggestions-stats',
      getBulkUploadUserTemplate: IOH.userServiceBaseURL + '/user/get-bulk-user-template',
      userActivationReport: IOH.userServiceBaseURL + '/report/usersActivationReport',
      sendActivationEmail: IOH.userServiceBaseURL + '/user/send-activation-email',
    };
  }
}
