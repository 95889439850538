import {Injectable} from '@angular/core';
import {Auth} from "aws-amplify";
import {MessageHandlerService} from "../message-handler.service";
import {DatePipe} from "@angular/common";
import {Agency} from 'src/app/models/agency';
import {Company} from "../../models/company";
import {environment} from "../../../environments/environment";
import {ServiceClientService} from "../service-client.service";
import {Observable} from "rxjs";
import {Response} from "../../models/user";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/reducers";
import {removeAllAgencies} from "../../store/agency.actions";
import {removeAllCompanies} from "../../store/company.actions";
import {submissionTypes} from "../../models/patient";
import {LocalStorageService} from "../local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private messageHandlerService: MessageHandlerService,
              private datePipe: DatePipe,
              private serviceClientService:ServiceClientService,
              private localStorageService: LocalStorageService,
              private store: Store<AppState>,) { }

  clearStore() {
    this.store.dispatch(removeAllAgencies());
    this.store.dispatch(removeAllCompanies());
  }

  formatDateTime(date: string): string {
    if(date){
      const dateObj = new Date(date); // Convert the date string to a Date object

      // Extract individual date and time components
      const year = dateObj.getUTCFullYear();
      const month = this.addLeadingZero(dateObj.getUTCMonth() + 1);
      const day = this.addLeadingZero(dateObj.getUTCDate());
      const hours = this.addLeadingZero(dateObj.getUTCHours());
      const minutes = this.addLeadingZero(dateObj.getUTCMinutes());

      // Concatenate the formatted parts into the desired format
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }else{
      return '';
    }
  }


  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  sendResetPasswordEmail(email: string) {
    Auth.forgotPassword(email)
      .then(() => {
        this.messageHandlerService.generateToastMessage('success', '', 'Email Sent Successfully')
      })
      .catch((error) => {
        this.messageHandlerService.generateToastMessage('error', '', 'Email Not Sent');
      });
  }

  extractQuestionAndAnswer(questionnaire: any): Array<any> {
    const questionAttempts = [];
    for (const key in questionnaire) {
      if (Array.isArray(questionnaire[key])) {
        questionAttempts.push({
          question: key,
          answer: questionnaire[key].join(',   '),
        });
      } else {
        questionAttempts.push({
          question: key,
          answer: questionnaire[key] || '',
        });
      }
    }
    return questionAttempts;
  }

  convertToISOString(date: Date, isStartDate: boolean): string {
    if (date) {
      date = new Date(date);
      if (isStartDate) {
        date.setHours(0, 0, 0, 0);
      } else {
        date.setHours(23, 59, 59, 999);
      }
      return date.toISOString();
    }
    return null;
  }

  convertDateFilterToISOString(date: Date, isStartDate: boolean): string {
    if (date) {
      date = new Date(date);
      if (!isStartDate) {
        date.setHours(date.getHours()+24 + date.getTimezoneOffset()/60);
      } else {
        date.setHours(date.getHours() + date.getTimezoneOffset()/60);
      }
      return date.toISOString();
    }
    return null;
  }

  isPhoneAndFaxNumberValid(userData) {
    let phone = userData?.phone?.number || "";
    if (phone.includes('-')) {
      phone = phone.split('-').join("");
      phone = phone.replace(new RegExp('_', 'g'), '');
    }

    let fax = userData?.fax?.number || "";
    if (fax.includes('-')) {
      fax = fax.split('-').join("");
      fax = fax.replace(new RegExp('_', 'g'), '');
    }

    return (phone.length == 0 || phone.length == 10) && (fax.length == 0 || fax.length == 10);
  }

  toLocal(dt: Date, timeZone?: string) {
    if (timeZone) {
      return new Date(dt).toLocaleString('en-US', { timeZone: timeZone })
      // let dobDate = new Date(new Date(dt).toLocaleString('en-US', { timeZone: timeZone }))
      // return new Date(new Date(Date.UTC(dobDate.getFullYear(), dobDate.getMonth(), dobDate.getDate())).toLocaleString('en-US', { timeZone: timeZone }))
    } else {
      return new Date(dt);
    }
  }

  getSelectedAgenciesIds(agencies: Agency[] | Company[]): string[]{
    if(agencies){
      return agencies.map(obj => {
        return obj.id
      });
    }
    else{
      return [];
    }
  }
  sortSelectItemByLabel(data) {
    data = data.sort((a, b) => {
      const nameA = a.label.trim().toUpperCase();
      const nameB = b.label.trim().toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
  }

  getReleaseVersion(): Observable<Response<any>> {
    return this.serviceClientService.get(environment.endPoints.releaseVersion);
  }

  sortSelectedVisits(allSubmissionTypes, selectedSubmissionTypes) {
    allSubmissionTypes = [...submissionTypes];

    if (selectedSubmissionTypes && selectedSubmissionTypes.length)
      selectedSubmissionTypes.forEach(selectedSubmissionType => {
        allSubmissionTypes.unshift(selectedSubmissionType);
      })

    allSubmissionTypes = this.removeDuplicates(allSubmissionTypes);

    return allSubmissionTypes;
  }

  removeDuplicates(array) {
    return array.filter((item, index, self) =>
        index === self.findIndex(obj => (
          obj.value === item.value
        ))
    );
  }

  sortSelectedValuesOfMultiSelect(orignalFilterValues, selectedSubmissionTypes) {
    let orignalValues = orignalFilterValues.map(obj => Object.assign({}, obj));

    if (selectedSubmissionTypes && selectedSubmissionTypes.length)
      selectedSubmissionTypes.forEach(selectedSubmissionType => {
        const selectedValue = orignalValues.find(orignalValue => orignalValue.value == selectedSubmissionType);

        if (selectedValue)
          orignalValues.unshift(selectedValue);
      })

    orignalValues = this.removeDuplicates(orignalValues);

    return orignalValues;
  }

  setDataInLocalStorage(componentKey, key, value) {
    const iohUserState: any = JSON.parse(this.localStorageService.get(componentKey));
    if (iohUserState) {
      iohUserState[key] = value;
      this.localStorageService.set(componentKey, JSON.stringify(iohUserState));
    } else {
      let newIohUserState = {}
      newIohUserState[key] = value;
      this.localStorageService.set(componentKey, JSON.stringify(newIohUserState));
    }
  }

  calculateInconsistencyWarnings(visit) {
    let crossRulesIssuesCount = 0;
    let crossRulesIssues = [];
    const issues = visit?.issues || [];
    issues.forEach(issue => {
      if (issue?.crossRule && issue?.crossRuleType === "impact") {
        crossRulesIssues.push(issue);
      }
    });

    let handledIssues = [];

    for (let i = 0; i < crossRulesIssues.length; i++) {
      for (let j = 0; j < crossRulesIssues[i].otherValues.length; j++) {
        let id1 = crossRulesIssues[i].question + crossRulesIssues[i].otherValues[j].title;

        let id2 = crossRulesIssues[i].otherValues[j].title + crossRulesIssues[i].question;

        if (handledIssues.includes(id1) || handledIssues.includes(id2)) {
          continue;
        } else {
          handledIssues.push(id1);
          handledIssues.push(id2);
          crossRulesIssuesCount++;
        }
      }
    }

    return crossRulesIssuesCount
  }
}

