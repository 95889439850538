import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  private displaySubject = new BehaviorSubject<boolean>(false);
  display$ = this.displaySubject.asObservable();

  constructor() { }
  
  openSidebar() {
    this.displaySubject.next(true);
  }


  closeSidebar() {
    this.displaySubject.next(false);
  }
}
