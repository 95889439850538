import { Component, OnInit } from '@angular/core';
import { AuthService } from '../security/auth.service';
import { CognitoUserService } from '../services/cognito-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator, PasswordStrengthValidator } from '../helper/confirmed.validator';
import {
  RESET_PASSWORD_FIELDS_MISSING,
  RESET_PASSWORDS_NOT_MATCHING,
  PASSWORD_STRENGTH_VALIDATION_MESSAGE,
  PASSWORDS_MUST_MATCH,
  VERIFICATION_PATH, LOGIN_PATH, REGISTRATION_ACTIVATED_STATUS,
} from '../common/Constants';
import { MessageHandlerService } from '../services/message-handler.service';
import { LOGO_TEXT } from '../common/Constants';
import {UserService} from "../services/user.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-complete-new-password',
  templateUrl: './complete-new-password.component.html',
  styleUrls: ['./complete-new-password.component.scss'],
})
export class CompleteNewPasswordComponent implements OnInit {
  completeNewPasswordForm: FormGroup; //= new FormGroup({});
  logoText = LOGO_TEXT;
  PASSWORD_STRENGTH_VALIDATION_MESSAGE = PASSWORD_STRENGTH_VALIDATION_MESSAGE;
  PASSWORDS_MUST_MATCH = PASSWORDS_MUST_MATCH;
  subscription: Subscription;
  constructor(
    private router: Router,
    private authService: AuthService,
    private cognitoUserService: CognitoUserService,
    private messageHandlerService: MessageHandlerService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.completeNewPasswordForm = this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      newPassword: [null, Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      confirmNewPassword: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.autoPopulateEmailAndPassword();
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  autoPopulateEmailAndPassword() {
    if(!this.cognitoUserService.cognitoUser) {
      this.router.navigate([LOGIN_PATH]);
    }

    this.route.queryParams.subscribe((params) => {
      const password = params['password'];
      if (password) {
        this.completeNewPasswordForm.patchValue({
          oldPassword: password,
        });
      }
    });
  }

  completeNewPassword() {
    if (
      !this.completeNewPasswordForm.get('oldPassword').value ||
      !this.completeNewPasswordForm.get('confirmNewPassword').value ||
      !this.completeNewPasswordForm.get('newPassword').value
    ) {
      this.messageHandlerService.generateToastMessage('warn', '', RESET_PASSWORD_FIELDS_MISSING);
      this.completeNewPasswordForm.get('oldPassword').markAsTouched();
      this.completeNewPasswordForm.get('newPassword').markAsTouched();
      this.completeNewPasswordForm.get('confirmNewPassword').markAsTouched();

      return;
    }

    if (
      !this.completeNewPasswordForm.valid ||
      this.completeNewPasswordForm.get('confirmNewPassword').value !== this.completeNewPasswordForm.get('newPassword').value
    ) {
      this.messageHandlerService.generateToastMessage('error', '', RESET_PASSWORDS_NOT_MATCHING);
      this.completeNewPasswordForm.get('oldPassword').markAsTouched();
      this.completeNewPasswordForm.get('newPassword').markAsTouched();
      this.completeNewPasswordForm.get('confirmNewPassword').markAsTouched();

      return;
    }

    this.authService
      .updatePassword(
        this.completeNewPasswordForm.value.newPassword,
        this.completeNewPasswordForm.value.oldPassword,
        this.cognitoUserService.cognitoUser,
      )
      .then((authenticatedUser) => {
          this.subscription =  this.userService.updateUserRegistrationStatus({
            aws_cognito_id: this.cognitoUserService.cognitoUser.username,
            registrationStatus: REGISTRATION_ACTIVATED_STATUS
          }).subscribe();
        if (authenticatedUser.challengeName === 'SMS_MFA') {
          this.cognitoUserService.cognitoUser = authenticatedUser;
          this.router.navigate([VERIFICATION_PATH]);
        } else {
          let accessToken = authenticatedUser.signInUserSession.accessToken.jwtToken;
          this.authService.currentUserSubject.next(authenticatedUser);
          this.authService.setToken(accessToken);
          this.router.navigate(['/']);
          this.completeNewPasswordForm.reset();
        }
      })
      .catch((err) => {
        this.messageHandlerService.generateToastMessage('error', '', 'Invalid old password. Please enter the correct old password.');
        throw err;
      });
  }
}
