import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGO_TEXT,LOGIN_PATH } from 'src/app/common/Constants';

@Component({
  selector: 'app-password-reset-done',
  templateUrl: './password-reset-done.component.html',
  styleUrls: ['./password-reset-done.component.scss']
})
export class PasswordResetDoneComponent implements OnInit {
  logoText = LOGO_TEXT;

  constructor(private router :Router) { }

  ngOnInit(): void {
  }

  navigateToLogin(){
    this.router.navigate([LOGIN_PATH])
  }
}
