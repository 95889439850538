import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() firstName: string;
  @Input() lastName: string;
  constructor() { }

  ngOnInit(): void {
  }

  get firstNameInitial(): string {
    return this.firstName ? this.firstName.charAt(0).toUpperCase() : '';
  }

  get lastNameInitial(): string {
    return this.lastName ? this.lastName.charAt(0).toUpperCase() : '';
  }

  get imageUrl(): string {
    // Replace this with the URL of the actual image or use a placeholder image
    return '../../assets/images/avatar.png';
  }

}
