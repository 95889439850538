import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { countries } from '../models/country-data';
import { PasswordStrengthValidator } from '../helper/confirmed.validator';
import { PreferencesService } from '../services/preferences.service';
import { ProgressBarService } from '../services/progress-bar.service';
import { UserService } from '../services/user.service';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { User } from '../models/user';
import { MessageHandlerService } from '../services/message-handler.service';
import { UserDataManager } from '../user/UserDataManager';


@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit, OnDestroy {
  subscribers:Subscription[] = [];
  display: boolean = false;
  updateUserForm :  FormGroup
  countries = countries;
  currentImage: any;
  selectedIndex: number;
  user :User;
  currentUser : User;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
  constructor( private formBuilder : FormBuilder , private userDataManager: UserDataManager,
    private  preferencesService : PreferencesService,private progressBarService: ProgressBarService,private userService : UserService ,private messageHandlerService : MessageHandlerService) { }

  ngOnInit(): void {
    this.getCurrentUser();
    this.updateUserFormControl()
    this.preferencesService.display$.subscribe(display => {
      this.display = display;
    });
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(subscriber => {
      subscriber.unsubscribe();
    });
  }

  async getCurrentUser() {
    this.subscribers.push(await this.userDataManager.user$.subscribe((user) => {
      if(user) {

        this.user = user;
        this.currentUser = user;
      }
    }));
  }

  onSlideBarClose() {
    this.preferencesService.closeSidebar();
  }

  updateUserFormControl() {
    this.updateUserForm = this.formBuilder.group({
      picture: [this.user.picture],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName],
      phone: this.formBuilder.group({
        number: [this.user?.phone?.number],
        countryCode: [this.user?.phone?.countryCode],
      }),
      fax: this.formBuilder.group({
        number: [this.user?.fax?.number],
        countryCode: [this.user?.fax?.countryCode]
      }),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), PasswordStrengthValidator])),
      confirmPassword: new FormControl('', Validators.required),
    });
  }

  async onSelect(event) {
    this.progressBarService.setProgressBarVisibility(true);
    const file = event.files[0];

    const formData = new FormData();
    formData.append('file', file);

    if (event.currentFiles && event.currentFiles.length > 0) {
      await this.userService.uploadFile(formData).subscribe(res => {
        if(res?.message?.Location) {
          this.currentImage = res;
          this.updateUserForm.patchValue({picture: res.message.Location})
          this.fileUpload.clear();
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    }
  }

  async deleteFile() {
    await this.userService.deleteFile({objectKey: this.updateUserForm.value.picture}).subscribe(res => {
      if(res) {
        this.currentImage = null;
        this.fileUpload.clear();
        this.updateUserForm.patchValue({picture: null});
      }
    });
  }

  updateUserPreferences(){
    if(this.updateUserForm.valid && this.updateUserForm.value.password === this.updateUserForm.value.confirmPassword){
      const updatedUser : User = {
        firstName: this.updateUserForm.value.firstName,
        lastName: this.updateUserForm.value.lastName,
        password: this.updateUserForm.value.password,
        aws_cognito_id: this.currentUser?.aws_cognito_id,
        phone: {
          number: this.updateUserForm.value.phone.number,
          countryCode: this.updateUserForm.value.phone.countryCode
        },
        fax: {
          number: this.updateUserForm.value.fax.number,
          countryCode: this.updateUserForm.value.fax.countryCode
        },
        picture: this.updateUserForm.value.picture
      }

      const temp = {...this.currentUser, ...updatedUser};
      this.userService.updateUser(temp).subscribe((response)=>{
       this.messageHandlerService.generateToastMessage('success', '', 'User Preferences updated successfully');
       this.onSlideBarClose();
      },
      (error) => {
        // Handle error
        this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', error);
      })
    }
  }

}
