import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  breadcrumbs$ = new BehaviorSubject<BreadcrumbItem[]>([]);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const breadcrumbs = this.buildBreadcrumbs(this.activatedRoute.root);
        this.breadcrumbs$.next(breadcrumbs);
      });
  }

  private buildBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
    const path = route.routeConfig?.path;

    if (path) {
      url += `/${path}`;
      //const breadcrumbLabel = route.routeConfig?.data?.breadcrumb || '';
      const breadcrumbLabel = path === 'ioh-users' ? 'io Team Management' :
        path === 'users' ? 'Agency User Management' :
          path === 'agencies' ? 'Agency Management' :
            path === 'visits' ? 'Visits Management' :
              path === 'support' ? 'Support Management':
                path === 'e-docs' ? 'eDocument Management':
                  path === 'preferences' ? 'Preferences Management' :
                    path === 'support-ticket' ? 'Support Ticket' :
                      path === 'episodes' ? 'Episodes Management' :
                        path === 'unmatched-visits' ? 'Unmatched Visits Management' :
                          path === 'p-visits' ? 'Patients Management' :
                            path === 'companies' ? 'Company Management' :
                              path === 'upload-file' ? 'Rule Engine Validation' :'';  
      const breadcrumb: BreadcrumbItem = { label: breadcrumbLabel, url: url };
      breadcrumbs.push(breadcrumb);
    }

    if (route.firstChild) {
      return this.buildBreadcrumbs(route.firstChild, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  getBreadcrumbs$() {
    return this.breadcrumbs$.asObservable();
  }
}

interface BreadcrumbItem {
  label: string;
  url: string;
}
