import {Component, Input, OnInit} from '@angular/core';
import {ProgressBarService} from "../../services/progress-bar.service";

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})

export class ProgressBarComponent implements OnInit {
  showLoader: boolean = true;

  constructor(private readonly progressBarService: ProgressBarService) { }

  ngOnInit(): void {
    this.progressBarService.getProgressBarVisibility$().subscribe(visibility => {
      this.showLoader = visibility;
    })
  }

}
