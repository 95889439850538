import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ServiceClientService} from "../service-client.service";
import {PatientSubmissions} from 'src/app/models/patientSubmissions';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private serviceClientService: ServiceClientService) { }

  // Get patient submission data
  getPatientSubmissions(request: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getPatientSubmissions, request);
  }

  getCaseManagerList(requestPayload): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getCaseManagerList, requestPayload);
  }

  // Get submission by patient
  getSubmissionByPatient(request: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getSubmissionByPatient, request);
  }

  getAllVisits(request: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getAllVisits, request);
  }

  // Get submission without patient
  getSubmissionWithOutPatients(request: any): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.getSubmissionWithOutPatients, request);
  }

  uploadFile(file): Observable<any> {
    return this.serviceClientService.postImage(environment.endPoints.uploadeSocFile, file);
  }

  updatePatientSubmission(data): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.updatePatientSubmissionEsoc, data);
  }
  updateStatus(request: PatientSubmissions): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.updateStatus, request);

  }

  deleteFile(file): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.deleteEsockFile, file);
  }

  deleteESocFile(file): Observable<any> {
    return this.serviceClientService.post(environment.endPoints.deleteESocFile, file);
  }

  getPatientsWithCaseManagerNames(): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.getPatientsWithCaseManagerName, {})
  }

  getPatientCreatedByNames(): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.getPatientCreatedByNames, {})
  }

  createPatient(request: any, agency: string): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.createPatient, request, 'agency', agency)
  }

  deletePatient(request: any): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.deletePatient, request)
  }

  getClinicianList(requestPayload): Observable<any>{
    return this.serviceClientService.post(environment.endPoints.getClinicianList, requestPayload)
  }

  reProcessSubmission(submissionId: string): Observable<any>{
    return this.serviceClientService.get(environment.endPoints.reProcessSubmission + `/${submissionId}`)
  }

  uploadFileReadCSVFile(file): Observable<any> {
    return this.serviceClientService.postImage(environment.endPoints.readCSVFile, file);
  }
}
