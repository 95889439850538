import { NgModule } from "@angular/core";
import { OverlayBannerComponent } from "./overlay-banner.component";
import { CommonModule } from "@angular/common";
import { ToastModule } from "primeng/toast"; 

@NgModule({
  imports: [CommonModule, ToastModule],
  declarations: [OverlayBannerComponent],
  exports: [OverlayBannerComponent],
})
export class OverlayBannerModule {}
