import {NgModule} from '@angular/core';
import {PatientComponent} from "src/app/patient/patient.component";
import {TableModule} from "primeng/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule, DatePipe, LowerCasePipe, NgIf, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {SidebarModule} from 'primeng/sidebar';
import {PaginatorModule} from "primeng/paginator";
import {SharedModule} from "../shared/shared.module";
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from "primeng/inputmask";
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from "primeng/tooltip";
import {DialogModule} from 'primeng/dialog';

import {EsocModule} from 'src/app/e-soc/esoc.modules';
import {GMapModule} from 'primeng/gmap';
import {VisitModule} from "../visit/visit/visit.module";
import {EpisodeModule} from "../episode/episode/episode.module";
import {UnmatchedVisitModule} from "../unmatched-visits/unmatched-visits/unmatchedVisit.module";
import {SupportModule} from "../support/support.module";
import {MatMenuModule} from "@angular/material/menu";
import {MultiSelectModule} from "primeng/multiselect";

@NgModule({
  declarations: [
    PatientComponent
  ],
    imports: [
        TableModule,
        FormsModule,
        MatIconModule,
        NgIf,
        NgTemplateOutlet,
        DropdownModule,
        CalendarModule,
        SidebarModule,
        PaginatorModule,
        SharedModule,
        FileUploadModule,
        TitleCasePipe,
        LowerCasePipe,
        ReactiveFormsModule,
        InputMaskModule,
        CheckboxModule,
        DatePipe,
        TooltipModule,
        CommonModule,
        EsocModule,
        GMapModule,
        DialogModule,
        VisitModule,
        EpisodeModule,
        UnmatchedVisitModule,
        SupportModule,
        MatMenuModule,
        MultiSelectModule,
    ],
  exports: [
    PatientComponent
  ]
})
export class PatientModule { }
