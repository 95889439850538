import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ESockSubmission} from "../../models/patient";
import {DOB_DATE_FORMAT, PHONE_NUMBER_LENGTH, PHONE_NUMBER_REGEX} from "../../common/Constants";
import {ProgressBarService} from "../../services/progress-bar.service";
import {PatientService} from "../../services/patient/patient.service";
import {MessageHandlerService} from "../../services/message-handler.service";
import {ConfirmationService} from "primeng/api";
import {DatePipe} from "@angular/common";
import {eSoc, eSockStatus} from "../../models/patientSubmissions";
import {countries} from 'src/app/models/country-data';

@Component({
  selector: 'app-others-upload',
  templateUrl: './othersUpload.component.html',
  styleUrls: ['./othersUpload.component.scss']
})
export class OthersUploadComponent implements OnInit {

  eSockSubmissionForm: ESockSubmission;
  showeSockDragAndDrop: boolean = true;
  eSockFileName: string;
  showUpdateAndDeleteEsock: boolean = false;
  fileLocation: string;
  isPreferredContactSelected: boolean = false;
  dobDateFormat = DOB_DATE_FORMAT;
  isNewFileUploaded: boolean = false;
  isFileUploadComplete: boolean = true;
  isExistingFileDeleted: boolean = false;
  @Input() uploadOthersDisplay: boolean = false;
  @Input() patientSubmission?: any;
  @Input() userId: string;
  @Output() othersPopUpClose = new EventEmitter<any>();
  @Output() eSockUploadListingRefresh = new EventEmitter<any>()
  isChecked: boolean = false;
  protected readonly countries = countries;

  constructor(
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
  ){
    this.eSockSubmissionForm = {
      eSockFile: '',
      email: {email: '' , preferred: true},
      phoneNumber: {countryCode: countries[0].dial_code, number: '', preferred: false},
      dob: '',
      isChecked: true,
    }
  }

  ngOnInit(): void {
    this.openeSOCUploadPopup();
    this.changeCheckboxValue();
  }

  openeSOCUploadPopup() {
    let contactInfo = this.patientSubmission?.contactInfo ? this.patientSubmission?.contactInfo : this.patientSubmission?.patient?.contactInfo;
    let eSoc = this.patientSubmission?.eSock ? this.patientSubmission?.eSock : this.patientSubmission?.submissionData?.eSock;
    let dob = this.patientSubmission?.dob ? this.patientSubmission?.dob : this.patientSubmission?.patient?.dob;

    if (eSoc?.file && eSoc?.file[0] !== undefined) {
      this.eSockFileName = eSoc?.file[0]?.fileName;
      this.showUpdateAndDeleteEsock = true;
      this.showeSockDragAndDrop = false;

      this.eSockSubmissionForm = {
        isChecked: true,
        eSockFile: eSoc?.file[0]?.fileName,
        email: {
          email: contactInfo?.email
          , preferred: contactInfo?.preferredOption === 'email' || contactInfo?.preferredOption === 'both'
        },
        phoneNumber: {
          countryCode: this.getCountryCode(), number: this.getPhoneNumber()
          , preferred: contactInfo?.preferredOption === 'phone' || contactInfo?.preferredOption === 'both'
        },
        dob
      }
    }
    else {
      this.eSockSubmissionForm = {
        eSockFile: '',
        email: {
          email: contactInfo?.email,
          preferred: contactInfo?.preferredOption === 'email' || contactInfo?.preferredOption === 'both'
        },
        phoneNumber: {
          countryCode: this.patientSubmission?.patient?.contactInfo || this.patientSubmission?.contactInfo ? this.getCountryCode() : countries[0].dial_code,
          number: this.patientSubmission?.patient?.contactInfo || this.patientSubmission?.contactInfo ? this.getPhoneNumber() : '',
          preferred: contactInfo?.preferredOption === 'phone' || contactInfo?.preferredOption === 'both'
        },
        dob,
        isChecked: true
      }
      this.eSockFileName = ''
      this.showUpdateAndDeleteEsock = false;
      this.showeSockDragAndDrop = true;
    }
    this.uploadOthersDisplay = true;
  }

  getCountryCode()
  {
    let phoneNumber = this.patientSubmission?.patient?.contactInfo ? this.patientSubmission?.patient?.contactInfo?.phone : this.patientSubmission?.contactInfo?.phone
    let differnce = phoneNumber?.length-PHONE_NUMBER_LENGTH;
    phoneNumber = phoneNumber?.slice(0, differnce);
    return phoneNumber;
  }

  getPhoneNumber()
  {
    let phoneNumber = this.patientSubmission?.patient?.contactInfo ? this.patientSubmission?.patient?.contactInfo?.phone : this.patientSubmission?.contactInfo?.phone
    let differnce = phoneNumber?.length-PHONE_NUMBER_LENGTH;
    phoneNumber = phoneNumber?.slice(differnce);
    return phoneNumber;
  }

  othersUploadPopupClose() {
    this.uploadOthersDisplay = false;
    this.othersPopUpClose.emit();
  }

  async handleFileUpload(event: any) {
    this.isFileUploadComplete = false;
    const file = event.target.files[0];
    this.eSockFileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    this.showeSockDragAndDrop = false;
    this.showUpdateAndDeleteEsock = true;
    this.progressBarService.setProgressBarVisibility(true);
    /*this.subscriptions.push(*/await this.patientService.uploadFile(formData).subscribe(res => {
      if (res?.message?.Location) {
        this.isFileUploadComplete = true;
        this.isNewFileUploaded = true;
        this.fileLocation = res?.message?.Location
      } else {
        this.messageHandlerService.generateToastMessage('error', '', 'Unable to upload file');
      }
      this.progressBarService.setProgressBarVisibility(false);
    })/*)*/;
  }

  // changeCheckboxValue(value: any)
  // {
  //   if(value == 'Email')
  //   {
  //     if(!this.eSockSubmissionForm.email.preferred && !this.eSockSubmissionForm.phoneNumber.preferred)
  //     {
  //       this.isPrefferedContactSelected = true;
  //       return;
  //     }
  //     else{
  //       this.isPrefferedContactSelected = false;
  //     }
  //     this.eSockSubmissionForm.email.preferred = true;
  //     this.eSockSubmissionForm.phoneNumber.preferred = false;
  //   }
  //   else if(value == 'Phone'){
  //     if(!this.eSockSubmissionForm.email.preferred && !this.eSockSubmissionForm.phoneNumber.preferred)
  //     {
  //       this.isPrefferedContactSelected = true;
  //       return;
  //     }
  //     else{
  //       this.isPrefferedContactSelected = false;
  //     }
  //     this.eSockSubmissionForm.email.preferred = false;
  //     this.eSockSubmissionForm.phoneNumber.preferred = true;
  //   }
  // }

  async deleteEsockFile() {
    await this.patientService.deleteFile({
      objectKey: this.isNewFileUploaded ? this.fileLocation : this.patientSubmission?.eSock?.file ? this.patientSubmission?.eSock?.file[0]?.fileLocation : this.patientSubmission?.submissionData?.eSock?.file[0]?.fileLocation
    }).subscribe(res => {
      if (res) {
        this.showeSockDragAndDrop = true;
        this.showUpdateAndDeleteEsock = false;
        if(!this.isNewFileUploaded){
          this.isExistingFileDeleted = true;
          this.addeSocToSubmission(false);
        }
        this.fileLocation = null;
        this.eSockSubmissionForm.eSockFile = '';
        this.messageHandlerService.generateToastMessage('success', '', 'File deleted successfully');

      }
    },(error) => {
      this.messageHandlerService.generateToastMessage('error', '', 'An error occurred while removing eSoc file.');
    });
  }

  changeCheckboxValue()
  {
    if(!this.eSockSubmissionForm.email.preferred && !this.eSockSubmissionForm.phoneNumber.preferred)
    {
      this.isPreferredContactSelected = true;
      return;
    }
    else{
      this.isPreferredContactSelected = false;
    }
  }

  async addeSocToSubmission(eSockPopupClose?: boolean) {

  }

  openeSockDragAndDropAgain()
  {
    this.eSockSubmissionForm.eSockFile = '';
    this.fileLocation = ''
    this.showUpdateAndDeleteEsock = false;
    this.showeSockDragAndDrop = true;
  }

  isPhoneNumberValid() {
    let phone = this.eSockSubmissionForm?.phoneNumber?.number
    if (phone?.includes('-')) {
      phone = phone?.split('-').join("")
    }
    return new RegExp(PHONE_NUMBER_REGEX).test(phone);
  }

  updateEsockFile() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to upload a new document, as it will override the previous one?',
      header: 'Update eSOC',
      icon: 'pi pi-upload',
      accept: () => {
        this.openeSockDragAndDropAgain();
      },
      reject: (type) => {
      }
    });
  }

  formatDate(){
    let dob = this.eSockSubmissionForm.dob ? new Date(this.eSockSubmissionForm.dob).toISOString().split('T')[0] : this.patientSubmission.dob ? this.patientSubmission.dob : this.patientSubmission?.patient?.dob
    return this.datePipe.transform(dob, 'MM/dd/yyyy');
  }
}
