import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import {RedirectIfLoginGuard} from "./security/redirect.if.login.guard";
import {EsocVerificationComponent} from "./esoc-verification/esoc-verification.component";
import {IohStatusComponent} from "./ioh-status/ioh-status.component";

const routes: Routes = [
  {
    path: 'esoc-verification',
    component: EsocVerificationComponent
  },
  {
    path: 'api-status',
    component: IohStatusComponent
  },
  {
    path: 'login', component: LoginComponent, canActivate: [RedirectIfLoginGuard]
  },
  {
    path: '',
    redirectTo : '/login',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
