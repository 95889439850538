import { AddressDto, agencyStatusValues, statusValues as agency_status_values, status as agency_status } from "./agency";

export interface Response<T>{
    success: boolean,
    content: {
        totalElements: number;
        totalCompaniesCount: number;
        data: T;
    },
    returnCode: number,
    errorMessage?: string
}

export interface PhoneDto {
    countryCode: string;
    number: string
}

export interface Company {
    id?: string;
    logo: string;
    name: string;
    status: string;
    phoneNumber: PhoneDto;
    fax: PhoneDto;
    email: string;
    address: AddressDto;
    createdAt: Date;
    createdBy: string;
}

export interface CompanyId {
    companyId?: string;
}

export const companyStatusValues = agencyStatusValues

export const statusValues = agency_status_values

export const status = agency_status
export const defaultSelectedCompanyRows = [
    'Company',
    'Email',
    'Phone',
    'Status'
  ]
  export const CompanyyListingRows = [
    {value: 'Company', label: 'Company'},
    {value: 'Email', label: 'Email'},
    {value: 'Phone', label: 'Phone'},
    {value: 'Status', label: 'Status'},
  ];