import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../security/auth.service';
import {UserService} from "../services/user.service";
import {LocalStorageService} from "../services/local-storage.service";
import {
  AGENCY_PATH, AllExceptPatientAllowedRoles, EDOCS, IOH_USER_PATH,
  OnlyAdminAllowedRoles, OnlyAdminAndCompanyAdminAllowedRoles, OnlyAllowedExceptClinicianRoles,
  OnlyCaseManagerClinicianAllowedRoles,
  OnlyClinicianAllowedRoles,
  OnlySuperAdminAllowedRoles, PATH_BEFORE_REFRESH,
  RoleID,
  SUBMISSION_PATH,
  USER_PROFILE, USERS_PATH, VISIT_PATH, FORCE_RESET_PASSWORD_PATH,
  CHANGE_USER_PASSWORD_DAYS,
  CHANGE_USER_PASSWORD_BANNER_SHOW_DAYS
} from "../common/Constants";
import {Subscription, take} from "rxjs";
import {SidenavService} from "../core/sidebar/sidenav.service";
import {SidenavItem} from "../core/sidebar/sidenav-item.interface";
import {UserDataManager} from "../user/UserDataManager";
import {Location} from '@angular/common';
import { AgencyService } from '../services/agency/agency.service';
import {User} from "../models/user";
import {MessageHandlerService} from "../services/message-handler.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  toggleSidebar = true;
  subscriptions: Subscription[] = [];
  userSubscription: Subscription;
  isEDocPurchased: boolean = false;
  currentRole: string;
  navigateTo : string = AGENCY_PATH;
  currentAgencyId: string;
  currentUser : User;
  messageCount = 0
  days = 0
  RESTRICTED_ROLES = [RoleID.OfficeWorker.toString(), RoleID.Clinician.toString()]
  constructor(private router : Router , private authService : AuthService, private userService: UserService,
              private userDataManager: UserDataManager,
              private localStorageService: LocalStorageService,
              private location: Location,
              private sideNavService: SidenavService,
              private agencyService: AgencyService,
              private messageHandlerService: MessageHandlerService) {

  }

  ngOnInit(): void {
    this.addEDocItemsToMenu();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async getUserProfile() {
    try {
       this.subscriptions.push(this.userDataManager.user$.subscribe(res => {
       if (res) {
        this.currentUser = res;

        const days = this.calculateDays(res.changePassword ?? res.createdAt)
        if (days >= CHANGE_USER_PASSWORD_BANNER_SHOW_DAYS && days <= CHANGE_USER_PASSWORD_DAYS  && !this.RESTRICTED_ROLES.includes(this.currentRole) && this.currentRole !== "" && this.messageCount === 0) {
          this.messageCount += 1
          this.days = 60 - days
        }
        if (days >= CHANGE_USER_PASSWORD_DAYS && !this.RESTRICTED_ROLES.includes(this.currentRole) && this.currentRole !== "") {
          this.router.navigate([FORCE_RESET_PASSWORD_PATH]);
          return
        }

        this.localStorageService.set(PATH_BEFORE_REFRESH, this.location.path());
        const path = this.localStorageService.get(PATH_BEFORE_REFRESH);
         if(this.currentRole === RoleID.AgencyAdmin && this.isEDocPurchased && (!path || this.router.url === '/')){
           this.router.navigate([AGENCY_PATH]);
         }else if(this.currentRole  && (this.currentRole === RoleID.AgencyAdmin)
           && !this.isEDocPurchased && this.router.url === EDOCS){
           this.router.navigate([AGENCY_PATH]);
        }else if((res?.is_super_admin || res?.is_IOH_admin || res?.is_company_admin || this.currentRole === RoleID.AgencyAdmin) && (!path || this.router.url === '/')){
           this.router.navigate([AGENCY_PATH]);
        }else if((res?.defaultRole === RoleID.Clinician ||  res?.defaultRole === RoleID.CaseManager || res?.defaultRole === RoleID.OfficeWorker)
          && !this.isEDocPurchased && this.router.url === EDOCS){
          this.router.navigate([SUBMISSION_PATH])
        } else if((this.currentRole === RoleID.Clinician || this.currentRole === RoleID.CaseManager ||
           this.currentRole === RoleID.OfficeWorker) && this.router.url == AGENCY_PATH) {
           this.router.navigate([VISIT_PATH]);
         } else if ((res?.defaultRole === RoleID.Clinician ||
            res?.defaultRole === RoleID.CaseManager || res?.defaultRole === RoleID.OfficeWorker)
          && !this.localStorageService.get('currentRole')) {
          this.router.navigate([SUBMISSION_PATH])
        }else if(res?.defaultRole === RoleID.AgencyAdmin || res?.defaultRole === RoleID.CaseManager || res?.defaultRole === RoleID.OfficeWorker){
          this.router.navigate([AGENCY_PATH]);
        }else if(res?.defaultRole === RoleID.Clinician && (path === IOH_USER_PATH || path === USERS_PATH)){
          this.router.navigate([SUBMISSION_PATH]);
        }
        // if ((res?.content?.data?.is_super_admin || res?.content?.data?.is_IOH_admin || res?.content?.data?.defaultRole === RoleID.AgencyAdmin) && (!path || this.router.url === '/')) {
        //   this.router.navigate([AGENCY_PATH])
        // } else if ((res?.content?.data?.defaultRole === RoleID.Clinician ||
        //     res?.content?.data?.defaultRole === RoleID.CaseManager || res?.content?.data?.defaultRole === RoleID.OfficeWorker)
        //   && !this.localStorageService.get('currentRole')) {
        //   this.router.navigate([SUBMISSION_PATH])
        // }
        if (path) {
          this.localStorageService.remove(PATH_BEFORE_REFRESH);
        }
        this.localStorageService.set(USER_PROFILE, res?.toString());
      }
    }));
    }catch (e) {
      console.log(e);
    }


  }

  calculateDays(date: Date) {
    const createdAt_or_passwordChange = new Date(date);
    const currentDate = new Date();

    //calculate time difference
    const time_difference = currentDate.getTime() - createdAt_or_passwordChange.getTime();

    //calculate days difference by dividing total milliseconds in a day
    const days_difference = time_difference / (1000 * 60 * 60 * 24);

    return Math.floor(days_difference)
  }

  getUserRole() {
    this.subscriptions.push(this.userService.getUserRole().subscribe(res => {
      if(res.success) {
        this.localStorageService.set('role', res?.content?.data?.role);
      }
    }));
  }

  getCurrentAgencyRole() {
    //this.subscriptions.push(this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
      // this.currentUser = currentRole
      //this.getAgencies(currentRole.agency)
      // if(currentRole.role !==RoleID.Admin){
      //   this.items$ = this.items$.filter(
      //     item =>(!this.isEDocPurchased && this.currentUser.role as RoleID === this.superAdmin) || ((item.name === 'Agencies' || item.name === 'eDoc') && this.isEDocPurchased) || (item.name !== 'Agencies' && item.name !== 'eDoc')
      //   )
      // }


   // }));
  }

  addFeatureItemsToMenu(menu: SidenavItem[],Navigation) {
    menu.push(Navigation);
  }

  addEDocItemsToMenu() {
    try {
     this.subscriptions.push(this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
       if(OnlyAllowedExceptClinicianRoles.includes(currentRole.role)) {
         let request = {
           agencyId: currentRole.agency
         };
           this.subscriptions.push(
           this.agencyService.checkIfeDocEnabled(request).subscribe(res => {
             if (res?.success && res.content?.data) {
               this.isEDocPurchased = res.content?.data?.portalFeatures?.eDoc
             }
             this.currentRole = currentRole.role;
             this.createMenu();
             this.getUserProfile();
           }));


       }else{
          this.currentRole = currentRole.role;
          this.createMenu();
          this.getUserProfile();
       }
    }));

     }catch (error) {
    }


  }

  checkPortalFeatureRequirements(item, userRole) : boolean {
    let isSuperAdmin : boolean = userRole === RoleID.Admin;
    //let isItemAgencyOrEdoc: boolean = (item.name === 'Agencies' || item.name === 'eDoc');
    return isSuperAdmin || this.isEDocPurchased;
  }

  createMenu() {
    let menu: SidenavItem[] = [];
    const episodesManagement: SidenavItem = {
      name: 'Episodes',
      position: 1,
      routeOrFunction: '/episodes',
      icon: 'episode-icon',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyClinicianAllowedRoles,
      permissionCheck: true
    }
    menu.push(episodesManagement);

    const visitsManagement: SidenavItem = {
      name: 'Visits',
      position: 2,
      routeOrFunction: '/visits',
      icon: 'error-icon',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyClinicianAllowedRoles,
      permissionCheck: true
    }
    menu.push(visitsManagement);

    const patientManagement: SidenavItem = {
      name: 'Patients',
      position: 3,
      routeOrFunction: '/p-visits',
      icon: 'Patient-Icon',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyClinicianAllowedRoles,
      permissionCheck: true
    }
    menu.push(patientManagement);

    const userManagement: SidenavItem = {
      name: 'io Team',
      position: 9,
      routeOrFunction: '/ioh-users',
      icon: 'io-users-icon-green',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlySuperAdminAllowedRoles,
      permissionCheck: true
    }
    menu.push(userManagement);

    const companyManagement: SidenavItem = {
      name: 'Companies',
      position: 6,
      routeOrFunction: '/companies',
      icon: 'company-icon',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlySuperAdminAllowedRoles,
      permissionCheck: true
    }
    menu.push(companyManagement);

    const nonIohUserManagement: SidenavItem = {
      name: 'Users',
      position: 4,
      routeOrFunction: '/users',
      icon: 'Agency-Management',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyAdminAllowedRoles,
      permissionCheck: true
    }
    menu.push(nonIohUserManagement);

    const agentManagement: SidenavItem = {
      name: 'Agencies',
      position: 5,
      routeOrFunction: '/agencies',
      icon: 'agency-icon',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyAdminAllowedRoles,
      permissionCheck: true
    }
    menu.push(agentManagement);

    const esocManagement: SidenavItem = {
      name: 'eDoc',
      position: 7,
      routeOrFunction: '/e-docs',
      icon: 'soc-icon',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyAllowedExceptClinicianRoles,
      permissionCheck: true
    }
    if(this.isEDocPurchased){
      this.addFeatureItemsToMenu(menu,esocManagement)
    }

    const supportManagement: SidenavItem = {
      name: 'Support',
      position: 8,
      routeOrFunction: '/support',
      icon: 'support-menu',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlySuperAdminAllowedRoles,
      permissionCheck: true
    }
    menu.push(supportManagement);

    const supportTicketManagement: SidenavItem = {
      name: 'Support',
      position: 10,
      routeOrFunction: '/support-ticket',
      icon: 'support-menu',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlyCaseManagerClinicianAllowedRoles,
      permissionCheck: true
    }
    menu.push(supportTicketManagement);

    const uploadFileManagement: SidenavItem = {
      name: 'Upload File',
      position: 10,
      routeOrFunction: '/upload-file',
      icon: 'upload-nav',
      customClass: 'gc-nav-icon',
      allowedRoleIds: OnlySuperAdminAllowedRoles,
      permissionCheck: true
    }

    if(this.currentUser?.is_super_admin || this.currentUser?.is_IOH_admin)
      menu.push(uploadFileManagement);


    this.subscriptions.push(this.userDataManager.user$.subscribe(user => {
      this.sideNavService.items =[];
      let allowedItems:SidenavItem[] = [];

      menu.forEach(item => {
        if(item.permissionCheck){
          if(item.allowedRoleIds.includes(user.currentUserRole)){
            allowedItems.push(item);
          }
        }else{
          allowedItems.push(item);
        }
      });

      allowedItems.forEach(item => {
        this.sideNavService.addItem(item);
      });

    }));

    // this.subscriptions.push(this.userDataManager.user$.subscribe(user => {
    //   this.subscriptions.push(this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
    //     try {
    //       let request = {
    //         search: "",
    //         status: '',
    //         agencyLevel: '',
    //         agencyID: currentRole.agency,
    //         pagination: null,
    //         companyId: user.is_company_admin && user.currentUserRole === RoleID.companyAdmin ? user.companyId : null

    //       };
    //       this.subscriptions.push(
    //         this.agencyService.getAgenciesWithSearch(request).subscribe({
    //         next:  res => {

    //         if (res?.success && res.content.data?.length) {
    //           this.isEDocPurchased = res.content.data[0].portalFeatures.eDoc;

    //           //-------------------------old-------------------------------

    //             this.sideNavService.items =[];
    //             let allowedItems:SidenavItem[] = [];

    //             menu.forEach(item => {
    //               if(item.permissionCheck){
    //                 if(item.allowedRoleIds.includes(user.currentUserRole) && this.checkPortalFeatureRequirements(item, user.currentUserRole)){
    //                   allowedItems.push(item);
    //                 }
    //               }else{
    //                 allowedItems.push(item);
    //               }
    //             });

    //             allowedItems.forEach(item => {
    //               this.sideNavService.addItem(item);
    //             });

    //           }
    //         },

    //         complete: ()=>{
    //           if(this.authService.authenticated) {
    //             this.getUserProfile();
    //           }
    //         }
    //     })
    //     )} catch (error) {
    //       console.log('Error caught while fetching agenices : ', error);
    //     }

    //   }));
    // }))


  }

}
