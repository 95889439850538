import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EpisodeComponent} from "../episode.component";
import {SharedModule} from "../../shared/shared.module";
import {DockModule} from "primeng/dock";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {TableModule} from "primeng/table";
import {PaginatorModule} from "primeng/paginator";
import {MatIconModule} from "@angular/material/icon";
import {SidebarModule} from 'primeng/sidebar';
import {GMapModule} from 'primeng/gmap';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from "primeng/calendar";
import {VisitModule} from "../../visit/visit/visit.module";
import {SupportModule} from "../../support/support.module";
import {MultiSelectModule} from "primeng/multiselect";


@NgModule({
  declarations: [
    EpisodeComponent
  ],
  exports: [
    EpisodeComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        DockModule,
        DropdownModule,
        FormsModule,
        TableModule,
        PaginatorModule,
        MatIconModule,
        SidebarModule,
        GMapModule,
        DialogModule,
        CalendarModule,
        VisitModule,
        SupportModule,
        MultiSelectModule
    ]
})
export class EpisodeModule { }
