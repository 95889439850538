import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'noOfDays',
    pure: true
})
export class noOfDaysPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            const intervals = {
                'day': 86400
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    return counter;
            }
        }
        return 0;
    }

}
