import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FORCE_RESET_PASSWORD_PATH } from '../../common/Constants'

@Component({
    selector: 'app-overlay-banner',
    templateUrl: './overlay-banner.component.html',
    styleUrls: ['./overlay-banner.component.scss'],
})

export class OverlayBannerComponent implements OnChanges {
    constructor(private messageService: MessageService, private router : Router) {}

    @Input() days: number

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.days.currentValue !== null || changes.days.currentValue !== undefined) && changes.days.currentValue > 0) {
            this.showBanner()
        }
    }

    showBanner() {
        this.messageService.add({ key: 'expire', sticky: true, severity: 'warn', summary: `Hey! Your password will expire in ${this.days} days` });
    }

    navigate() {
        this.router.navigate([FORCE_RESET_PASSWORD_PATH]);
    }
}
