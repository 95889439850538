import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {AllExceptPatientAllowedRoles, LOGIN_PATH, RoleID} from "../common/Constants";
import {UserDataManager} from "../user/UserDataManager";
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  public busy = false;

  constructor(protected auth: AuthService,
              protected userDataManager: UserDataManager,
              protected router: Router) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!this.auth.authenticated) return this.navigateTo(LOGIN_PATH);
    this.busy = true;
    return this.userDataManager.getUserFromApi().then((user: User) => {
      this.busy = false;
      if (user === null) return false;
      let verifyRole= [];
       verifyRole.push(user.currentUserRole)
      const hasAllowedRole = verifyRole.some(r => AllExceptPatientAllowedRoles.indexOf(r) >= 0);
      if(!hasAllowedRole) {
          this.auth.flush();
          this.auth.signOut();
          return false;
        }
        return true;
    }).catch((err) =>
    {
        this.auth.signOut();
        this.busy = false;
        console.log(err)
        return false;
    });



    // const currentUser = this.auth.currentUser;
    // if(currentUser){
    //     return true;
    // }
    //
    // this.router.navigate([LOGIN_PATH], {queryParams: {message: 'You are not authorized to access this page.' , redirect: false}});
    // return false;
  }

   protected navigateTo(path: string, message:string = '') {
    if (message)
    {
      this.router.navigate([path], {queryParams: {message: message , redirect: false}});
    }
    else
    {
      this.router.navigate([path]);
    }

    this.busy = false;
    return false;
  }


}
