import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferencesComponent } from './preferences.component';
import {TableModule} from "primeng/table";
import { SidebarModule } from 'primeng/sidebar';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';







@NgModule({
  declarations: [
    PreferencesComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    SidebarModule,
    ReactiveFormsModule,
    DropdownModule,
    FileUploadModule,
    InputMaskModule,
    InputTextModule,
    PasswordModule
    
  ],
 
  exports: [
    PreferencesComponent
  ]
})
export class PreferencesModule { }